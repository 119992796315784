import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IconCircleX } from "@tabler/icons-react";
import { getCDNUrl } from "../utils/cdn";
import bokaro from "../assets/clients/bokaro.jpg";
import hil from "../assets/clients/HIL.jpg";
import patilKalikal from "../assets/clients/patil-Kallakal.jpg";
import patil from "../assets/clients/patil.jpg";
import sail from "../assets/clients/sail.jpg";
import tatasteel from "../assets/clients/tataSteel.jpg";
import Kallakal from "./Projects/Kallakal";
import { useNavigate } from "react-router-dom";
import SailBokaro from "./Projects/SailBokaro";
import Bokaro from "./Projects/Bokaro";
import SailBilai from "./Projects/SailBilai";
import HIL from "./Projects/HIL";
import ComingSoon from "../components/ComingSoon";

// Replace Tabler icons with a different icon library or custom components
const IconArrowNarrowLeft = () => <span className="font-bold">←</span>;
const IconArrowNarrowRight = () => <span>→</span>;
const IconX = () => <span>×</span>;

// Implement a simple utility function for class names
const cn = (...classes) => classes.filter(Boolean).join(" ");

// Simple hook to replace useOutsideClick
const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
const CarouselContext = createContext({
  onCardClose: () => {},
  currentIndex: 0,
  isFullScreen: false,
  toggleFullScreen: () => {},
});

const carouselItems = [
  {
    src: getCDNUrl("/clients/patil-Kallakal.jpg"),
    title: "Foundary - Insert casting ",
    category: "Kallakal",
    content: <Kallakal />,
  },
  {
    src: getCDNUrl("/clients/tataSteel.jpg"),
    title: "Blooming Mill",
    category: "Jamshedpur",
    content: <Bokaro />,
  },
  {
    src: getCDNUrl("/clients/bokaro.jpg"),
    title: "Hot Strip Mill",
    category: "Bokaro",
    content: <SailBokaro />,
  },
  {
    src: getCDNUrl("/clients/sail.jpg"),
    title: "Sinter Plant",
    category: "Bhilai",
    content: <SailBilai />,
  },
  {
    src: getCDNUrl("/clients/HIL.jpg"),
    title: "PVC pipe manufacturing",
    category: "Thimmapur",
    content: <HIL />,
  },
  {
    src: getCDNUrl("/clients/patil.jpg"),
    title: "Foundary - Insert Casting",
    category: "Bokaro",
    content: <ComingSoon />,
  },
];

export const Carousel = ({ initialScroll = 0 }) => {
  const carouselRef = React.useRef(null);
  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = initialScroll;
      checkScrollability();
    }
  }, [initialScroll]);

  const checkScrollability = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const handleCardClose = (index) => {
    if (carouselRef.current) {
      const cardWidth = isMobile() ? 230 : 384;
      const gap = isMobile() ? 4 : 8;
      const scrollPosition = (cardWidth + gap) * (index + 1);
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
      setCurrentIndex(index);
    }
  };

  const isMobile = () => {
    return typeof window !== "undefined" && window.innerWidth < 768;
  };

  return (
    <CarouselContext.Provider
      value={{
        onCardClose: handleCardClose,
        currentIndex,
        isFullScreen,
        toggleFullScreen,
      }}
    >
      <div className="relative w-full">
        <div
          className="flex overflow-x-scroll overscroll-x-auto py-10 md:py-20 scroll-smooth [scrollbar-width:none]"
          ref={carouselRef}
          onScroll={checkScrollability}
        >
          <div className="flex flex-row justify-start gap-4 pl-4 max-w-7xl mx-auto">
            {carouselItems.map((item, index) => (
              <motion.div
                key={`card${index}`}
                className="last:pr-[5%] md:last:pr-[33%]"
              >
                <Card card={item} index={index} />
              </motion.div>
            ))}
          </div>
        </div>
        {!isFullScreen && (
          <div className="flex justify-end gap-2 mr-10">
            <button
              className="relative z-40 h-10 w-10 rounded-full bg-secondary flex text-white font-bold items-center justify-center disabled:opacity-50"
              onClick={scrollLeft}
              disabled={!canScrollLeft}
            >
              <IconArrowNarrowLeft />
            </button>
            <button
              className="relative z-40 h-10 w-10 rounded-full bg-secondary flex  text-white font-bold items-center justify-center disabled:opacity-50"
              onClick={scrollRight}
              disabled={!canScrollRight}
            >
              <IconArrowNarrowRight />
            </button>
          </div>
        )}
      </div>
    </CarouselContext.Provider>
  );
};

const Card = ({ card, index }) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const { onCardClose, currentIndex, isFullScreen, toggleFullScreen } =
    useContext(CarouselContext);

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        handleClose();
      }
    }
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [open]);

  useOutsideClick(containerRef, () => handleClose());

  const handleOpen = () => {
    // navigate("/comingsoon");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCardClose(index);
  };

  return (
    <>
      <AnimatePresence>
        {open && (
          <div className="fixed inset-0 h-screen z-50 overflow-auto">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-black/80 backdrop-blur-lg h-full w-full fixed inset-0"
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              ref={containerRef}
              className="max-w-5xl mx-auto bg-white h-fit z-[60] my-10 p-4 md:p-10 rounded-3xl font-sans relative"
            >
              <button
                className="sticky top-4 h-8 w-8 right-0 ml-auto bg-white rounded-full flex items-center justify-center"
                onClick={handleClose}
              >
                <IconCircleX />
              </button>
              <motion.p className="text-2xl md:text-5xl font-semibold text-primary mt-4 ">
                {card.title}
              </motion.p>
              <motion.p className="text-base font-medium text-primary mt-2">
                {card.category}
              </motion.p>
              <div className="py-10">{card.content}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <motion.button
        onClick={handleOpen}
        className="rounded-3xl bg-gray-100 overflow-hidden flex flex-col items-start justify-start relative z-10 h-80 w-56 md:h-[40rem] md:w-96"
      >
        <div className="absolute h-full top-0 inset-x-0 bg-gradient-to-b from-black/50 via-transparent to-transparent z-30 pointer-events-none" />
        <div className="relative z-40 p-8">
          <motion.p className="text-white text-xl md:text-3xl font-semibold max-w-xs text-left [text-wrap:balance] font-sans">
            {card.title}
          </motion.p>
          <motion.p className="text-white text-sm md:text-base font-medium font-sans text-left mt-2">
            {card.category}
          </motion.p>
        </div>
        <img
          src={card.src}
          alt={card.title}
          className="object-cover absolute z-10 inset-0 w-full h-full"
        />
      </motion.button>
    </>
  );
};
