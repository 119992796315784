import React from "react";
import { WordRotate } from "../components/ui/TextFlip";
import Getdemo from "../components/getdemo";
import ContactUs from "../components/ContactUs";
import SpiderHero from "../components/SpiderHero";
import Marquee from "../components/ui/Mareque";
import { cn } from "../lib/utils";
import OrbitingCirclesDemo from "../components/OrbitingSpiderAI";
import SpideraiFeatured from "../components/SpideraiFeatured";
import Breadcrumb from "../components/BreadCrumb";

const ReviewCard = ({ body }) => {
  return (
    <figure
      className={cn(
        "relative  cursor-pointer overflow-hidden rounded-xl border p-1 px-2",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]"
      )}
    >
      <blockquote className=" text-sm">{body}</blockquote>
    </figure>
  );
};

export default function Spider() {
  const iframeContainerStyle = {
    position: "relative",
    boxSizing: "content-box",
    maxHeight: "80vh",
    width: "100%",
    aspectRatio: "1.7712177121771218",
    padding: "40px 0 40px 0",
  };

  const iframeStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  const questions = [
    {
      id: 1,
      question: "How is my machine doing?",
    },
    {
      id: 2,
      question: "Why is machine stopped at 2 AM yesterday?",
    },
    {
      id: 3,
      question: "Did @MaintenanceEnginner changed the bearing yesterday?",
    },
    {
      id: 4,
      question: "List out the things i should know?",
    },
    {
      id: 5,
      question: "What's the current status of Pump P-45?",
    },
    {
      id: 6,
      question:
        "Show me the trend  of Motor RPM Energies for Rod Mill - 4 over the past 2 months.",
    },
    {
      id: 7,
      question:
        "Give me a summary of all maintenance activities performed in this week?",
    },
    {
      id: 8,
      question: "I'm doing captial repair, what to focus on?",
    },
    {
      id: 9,
      question:
        "Give me a summary of all anomalies detected in the past 24 hours?",
    },
  ];

  const firstRow = questions.slice(0, questions.length / 2);
  const secondRow = questions.slice(questions.length / 2);

  return (
    <>
      <SpiderHero />

      <Breadcrumb />
      <div className="w-full">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center min-h-[50vh] py-12 sm:py-16 lg:py-20">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center leading-tight text-primary">
              spiderAI™ enables users to communicate and reason with machines in
              simple language, to bring quantum improvements in{" "}
              <WordRotate
                className="text-secondary font-bold inline-block"
                words={[
                  "productivity",
                  "efficiency",
                  "decision-making accuracy",
                ]}
              />
            </h1>
          </div>
        </div>
      </div>

      <div className="bg-white pt-10 ">
        <div className="mx-[5%]">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl font-bold text-primary mb-2">
              Capabilities
            </h2>
            <div className="h-1 w-20 bg-secondary"></div>
          </div>
        </div>
        <SpideraiFeatured />
      </div>
      <div className="bg-white py-10 ">
        <div className="mx-[5%]">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl font-bold text-primary mb-2">
              spiderAI™ in action
            </h2>
            <div className="h-1 w-20 bg-secondary"></div>
          </div>
        </div>
      </div>

      <span className="mb-6 sm:mb-8">
        <div className="bg-white  overflow-hidden ">
          <div style={iframeContainerStyle}>
            <iframe
              src="https://app.supademo.com/embed/cm2vhnze71yzvesgvpudxeyei?embed_v=2"
              // "https://app.supademo.com/embed/cm2bjknvq1anfvm5s96bu8f1c?embed_v=2"

              loading="lazy"
              title="spiderAI™"
              allow="clipboard-write"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={iframeStyle}
            ></iframe>
          </div>
        </div>
      </span>

      <div className="py-10">
        <div className="w-full p-12 bg-primary ">
          <div className="flex flex-col md:flex-row items-start  mx-[5%] justify-between">
            <div className="flex-grow pr-6">
              <h2 className=" text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-white leading-relaxed mb-8 md:mb-0">
                "spiderAI™ Is designed as a co-intelligence platform that powers
                all your operational intelligence use-cases. Have a usecase in
                mind! Discuss with us"
              </h2>
            </div>
            <div className="w-full md:w-auto flex items-center justify-center">
              <ContactUs />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mx-auto py-10">
        <h2 className="text-4xl font-bold mb-4 text-center text-primary">
          Your are just a question away #AskSpiderAI
        </h2>

        <div className="relative flex h-[100px] w-full flex-col items-center justify-center overflow-hidden   bg-background ">
          <div className="p-1">
            <Marquee pauseOnHover className="[--duration:20s]">
              {firstRow.map((question) => (
                <ReviewCard key={question.id} body={question.question} />
              ))}
            </Marquee>
          </div>

          <div className="p-1">
            <Marquee reverse pauseOnHover className="[--duration:20s]">
              {secondRow.map((question) => (
                <ReviewCard key={question.id} body={question.question} />
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </>
  );
}
