import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";

import ApplicationGrid from "../ApplicationGrid";

import SailBilai from "../Projects/SailBilai";
import Kallakal from "../Projects/Kallakal";
import HIL from "../Projects/HIL";

import Bokaro from "../Projects/Bokaro"; //blooming mill
import tata from "../../assets/clients/tataSteel.jpg";

import hotstripmill from "../../assets/clients/bokaro.jpg";
import SailBokaro from "../Projects/SailBokaro";

import hil from "../../assets/clients/HIL.jpg";

import sail from "../../assets/clients/sail.jpg";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";

import vfd from "../../assets/applications/vfd.jpg";
import Hv from "../../assets/applications/Nodulizer_mixers.jpeg";
import rapid from "../../assets/applications/rapid_start_stops.jpg";
import hardto from "../../assets/useCase-hotSaw.jpg";
import atex from "../../assets/applications/ATEX_zone.jpg";
import dusty from "../../assets/applications/Dusty Environment.jpg";

// Placeholder component for content
const ContentPlaceholder = ({ title }) => (
  <div className="flex items-center justify-center h-full min-h-[300px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
    <div className="text-center">
      <h3 className="text-lg font-medium text-gray-600">{title}</h3>
      <p className="mt-2 text-sm text-gray-500">
        Operation details will appear here
      </p>
    </div>
  </div>
);

const OperationAccordion = () => {
  const cardData1 = [
    {
      title: "Sail Bhilai",
      location: "",
      imageSrc: sail,
      children: <SailBilai />,
    },
  ];

  const cardData2 = [
    {
      title: "Sail Bhilai",
      location: "",
      imageSrc: sail,
      children: <SailBilai />,
    },
    {
      title: "Kallakal",
      location: "",
      imageSrc: kallakal,
      children: <Kallakal />,
    },
  ];

  const cardData3 = [
    {
      title: "Blooming Mill",
      location: "",
      imageSrc: tata,
      children: <Bokaro />,
    },
  ];

  const cardData4 = [
    {
      title: "Blooming Mill",
      location: "",
      imageSrc: tata,
      children: <Bokaro />,
    },
    {
      title: "Hot Strip Mill",
      location: "",
      imageSrc: hotstripmill,
      children: <SailBokaro />,
    },
  ];

  // Set initial state to the first section key
  const [expandedSection, setExpandedSection] = useState("vfd");

  const sections = {
    vfd: {
      title: "VFD Driven",
      description:
        "As supply frequency changes with speed, without tracking supply frequency we lose the crucial context needed for accurate fault detection. Our high-fidelity data acquisition captures every nuance of supply frequency, enabling precise order tracking of mechanical components even through speed variations. This capability is essential because mechanical fault frequencies scale with speed, and without accurate supply frequency tracking, fault signatures cannot be tracked.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={vfd}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Hv: {
      title: "High Voltage (HV)",
      description:
        "Through current transformers, we capture high-fidelity data without compromising safety or requiring machine modifications. For any plant running critical HV machines, iHz™ is an essential tool in their reliability arsenal.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={Hv}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    LowSpeed: {
      title: "Low-Speed Operation",
      description:
        "Traditional vibration analysis struggles - weaker signals at slower speeds often mean missed early warnings. What makes ESA particularly powerful here is the physics working in our favor. These low-speed components typically have high moment of inertia, meaning any developing fault requires significant changes in motor current to overcome these inertial forces. iHz™'s sensitivity to these current variations gives us a remarkable advantage - we can detect subtle changes long before they become critical issues. ",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage="https://minto-webapp-v3.s3.us-east-2.amazonaws.com/Low_speed.mp4"
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Rapidstart: {
      title: "Rapid Start/Stop",
      description:
        "Where machines undergo frequent starts and stops, creating high transient loads and stress points. Take the EOT cranes at steel plants where every lift cycle demands rapid movement patterns, or hot saw systems making precision cuts through red-hot steel. Traditional monitoring systems struggle with these transient conditions, often missing critical fault signatures hidden in the start-stop patterns. iHz™ excels here because we capture every start-stop cycle in high fidelity, detecting patterns like motor stalling, overloading, or developing mechanical issues.",
      content: (
        <ApplicationGrid
          cards={cardData4}
          title="Featured Projects"
          heroImage={rapid}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Difficult: {
      title: "Hard-to-Access Equipment",
      description:
        "Some critical machines operate in areas where physical access is severely limited - surrounded by high-temperature zones, or positioned in tight spaces. Traditional monitoring methods requiring direct sensor placement have suspected to high maintanance and overall high CapEx. Our non-intrusive approach becomes invaluable here - by monitoring through motor current, we gain deep insights into machine health without needing physical access to the machine itself. ",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={hardto}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Atex: {
      title: "ATEX Zones",
      description:
        "Where safety is paramount and conventional sensors pose inherent risks. In these hazardous environments, every monitoring component must meet stringent safety requirements. Our non-intrusive approach becomes particularly valuable - by capturing high-fidelity data from motors located in safe zones, we gain deep insights into equipment health without introducing additional ignition risks in the hazardous area",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={atex}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    DustyEnvironments: {
      title: "Dusty Environments",
      description:
        "In environments where airborne particles make conventional monitoring unreliable, maintaining equipment health becomes particularly challenging. Sensors fail, connections degrade, and maintenance itself becomes hazardous. Our monitoring system thrives in these conditions - there's no need for sensitive sensors in the dust zone, no cables to maintain, and no compromise in data quality even when monitoring equipment handling abrasive materials like iron ore and minerals.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={dusty}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz™ can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-lg border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg p-4 transition-all duration-300 ease-in-out">
              {expandedSection ? (
                sections[expandedSection]?.content
              ) : (
                <ContentPlaceholder title="Select an operation type to view details" />
              )}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-secondary"
                        : "text-primary hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationAccordion;
