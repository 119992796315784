import React, { useState, useEffect } from "react";
import {
  ChevronRight,
  RotateCcw,
  Zap,
  Settings,
  Activity,
  Factory,
  BookOpen,
  ArrowRight,
  X,
} from "lucide-react";
import application01 from "../assets/usecases/application_1.jpg";
import application02 from "../assets/usecases/applications_2.jpg";
import application03 from "../assets/usecases/applications_3.jpg";
import application04 from "../assets/usecases/applications_4.jpg";
import usecase from "../assets/usecases/usecase.jpg";
import industries from "../assets/usecases/industries.jpg";
import { getCDNUrl } from "../utils/cdn";
import { useNavigate } from "react-router-dom";

// Application Data
const applicationData = {
  title: "Applications",
  description:
    "Every system is designed by balancing fault tolerance requirements, degrees of freedom (DoF), and functional requirements. We designed our iHz as a high-fidelity system to comprehend these system design variables, enabling efficient problem-solving.",
  categories: [
    {
      id: "machines",
      icon: <RotateCcw className="w-6 h-6" />,
      title: "By Machines/Equipments",
      shortDescription:
        "Advanced monitoring for AC-driven rotating machines, ensuring optimal performance and reliability.",
      longDescription: `Fundamentally, your operations are powered by rotating machines driven by AC. iHz is the most accurate & reliable industrial IoT system to apply for availability, reliability, and efficiency-related use cases.`,
      image: getCDNUrl("/usecases/application_1.jpg"),
      keyPoints: [
        "Real-time performance monitoring",
        "IoT and AI integration",
        "Comprehensive equipment diagnostics",
        "Advanced electrical signature analysis",
      ],
    },
    {
      id: "faults",
      icon: <Zap className="w-6 h-6" />,
      title: "By Fault Types",
      shortDescription:
        "Comprehensive diagnosis of electrical and mechanical faults at component and system levels.",
      longDescription: `iHz systems not only diagnose electrical abnormalities but also mechanical faults at the component level, providing real-time condition assessment at the sub-system level.`,
      image: getCDNUrl("/usecases/applications_2.jpg"),
      keyPoints: [
        "Mechanical fault detection",
        "Electrical abnormality diagnosis",
        "Component-level analysis",
        "Sub-system level monitoring",
      ],
    },
    {
      id: "drivetrain",
      icon: <Settings className="w-6 h-6" />,
      title: "By Drive Train Complexity",
      shortDescription:
        "Advanced understanding and monitoring of complex drive train mechanisms and interactions.",
      longDescription: `iHz diagnostic systems go beyond merely monitoring machines for specific faults. Our fundamental approach captures the complexity of the drive train: individual mechanics, their interactions, and impact on components.`,
      image: getCDNUrl("/usecases/applications_3.jpg"),
      keyPoints: [
        "Comprehensive drive train analysis",
        "Component interaction monitoring",
        "Preventive fault detection",
        "System-wide optimization",
      ],
    },
    {
      id: "operations",
      icon: <Activity className="w-6 h-6" />,
      title: "By Operational Complexity",
      shortDescription:
        "Context-aware monitoring for diverse operational environments and conditions.",
      longDescription: `The context of machine operation is defined by how it operates, why it operates, and what it operates with. iHz provides precise understanding to engineers to prevent faults for machines operating under various conditions.`,
      image: getCDNUrl("/usecases/applications_4.jpg"),
      keyPoints: [
        "VFD driven systems",
        "High voltage operations",
        "ATEX zones monitoring",
        "Environmental adaptability",
      ],
    },
    {
      id: "usecases",
      icon: <BookOpen className="w-6 h-6" />,
      title: "By Use-case Type",
      shortDescription:
        "Real-world implementations demonstrating measurable impact across industries.",
      longDescription: `Our clients have achieved remarkable success through carefully defined use cases and iHz implementation. From achieving zero unplanned downtime for three consecutive months to enabling digital transformation in steel manufacturing.`,
      image: getCDNUrl("/usecases/usecase.jpg"),
      keyPoints: [
        "Predictive maintenance",
        "Condition monitoring",
        "Reliability improvement",
        "Digital transformation",
      ],
    },
    {
      id: "industries",
      icon: <Factory className="w-6 h-6" />,
      title: "By Industries",
      shortDescription:
        "Specialized solutions adapted for diverse industrial requirements.",
      longDescription: `Every industry defines unique operational complexities that shape machine-related challenges. We optimize iHz systems to accurately diagnose industry-specific phenomena, minimizing false positives and improving certainty in fault detection.`,
      image: getCDNUrl("/usecases/industries.jpg"),
      keyPoints: [
        "Heavy Industries/Metals",
        "Process Manufacturing",
        "Energy Sector",
        "Utilities",
      ],
    },
  ],
};

// Card Component
const ApplicationCard = ({ category, onSelect }) => (
  <div className="group cursor-pointer" onClick={() => onSelect(category)}>
    <div className="relative overflow-hidden shadow-lg transition-shadow duration-300 hover:shadow-xl">
      {/* Image */}
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={category.image}
          alt={category.title}
          className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
        />
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

      {/* Content */}
      <div className="absolute bottom-0 left-0 right-0 p-6  bg-gradient-to-t from-primary/90 via-primary/60 to-primary/30">
        <div className="flex items-center gap-3 mb-3">
          <div
            className="p-2 rounded-full bg-white/10 backdrop-blur-sm"
            style={{ color: "#ff5757" }}
          >
            {category.icon}
          </div>
          <h3 className="text-xl font-semibold text-white">{category.title}</h3>
        </div>
        <p className="text-white/80 mb-4 line-clamp-2">
          {category.shortDescription}
        </p>
        <div className="flex items-center gap-2 text-white group-hover:text-[#ff5757] transition-colors">
          Find out more <ChevronRight className="w-5 h-5" />
        </div>
      </div>
    </div>
  </div>
);

// Modal Component
const DetailView = ({ category, onClose, contactClick }) => (
  <div
    className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
    onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}
  >
    <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100 transition-colors z-10"
        aria-label="Close details"
      >
        <X className="w-6 h-6 text-primary" />
      </button>

      <div className="p-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 rounded-full bg-[#fff5f5] text-secondary">
            {category.icon}
          </div>
          <h2 className="text-3xl font-bold text-secondary">
            {category.title}
          </h2>
        </div>

        <p className="text-primary text-lg mb-8">{category.longDescription}</p>

        <div className="grid md:grid-cols-2 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-semibold mb-4 text-primary">
              Key Capabilities
            </h3>
            <ul className="space-y-3">
              {category.keyPoints.map((point, index) => (
                <li key={index} className="flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-secondary" />
                  <span className="text-primary">{point}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-secondary-background p-6 ">
            <h3 className="text-xl font-semibold mb-4 text-primary">
              Ready to Learn More?
            </h3>
            <p className="text-primary mb-6">
              Discover how <b>{category.title.toLowerCase()}</b> solutions can
              transform your operations.
            </p>
            <button
              className="w-full flex items-center justify-center gap-2 px-6 py-3 text-white transition-opacity hover:opacity-90 bg-secondary"
              onClick={contactClick}
            >
              Schedule a Consultation
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Main Component
export default function ApplicationsSection() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/applications");
  };

  const contactClick = () => {
    navigate("/contactus");
  };

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") setSelectedCategory(null);
    };

    if (selectedCategory) {
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [selectedCategory]);

  // Handle scroll lock
  useEffect(() => {
    if (selectedCategory) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [selectedCategory]);

  return (
    <section className="py-10">
      <div className="max-w-7xl mx-auto px-4">
        {/* Section Header */}

        {/* Cards Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {applicationData.categories.map((category) => (
            <ApplicationCard
              key={category.id}
              category={category}
              onSelect={setSelectedCategory}
            />
          ))}
        </div>

        {/* Modal */}
        {selectedCategory && (
          <DetailView
            category={selectedCategory}
            onClose={() => setSelectedCategory(null)}
            contactClick={contactClick}
          />
        )}

        {/* Bottom CTA */}
        <div className="mt-16 text-center">
          <button
            onClick={handleClick}
            className="inline-flex items-center px-6 py-3 border font-bold border-transparent text-base text-white bg-secondary hover:bg-secondary-dark transition duration-150 ease-in-out"
          >
            Explore All Applications
            <ChevronRight className="text-white font-bold ml-2" size={24} />
          </button>
        </div>
      </div>
    </section>
  );
}
