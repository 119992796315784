import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import ConversationalAILogo from "../components/CustomLogo/SpiderAI/ConversatoinalAI";
import HybridUILogo from "../components/CustomLogo/SpiderAI/HybridUi";
import IntegrationsMultipleDatasourcesLogo from "../components/CustomLogo/SpiderAI/IntegratoinDataSources";
import OrchestrationEngineLogo from "../components/CustomLogo/SpiderAI/OrchestrationEngine";
import SpiderAISearch from "./SpiderAISearch";
import { HelpfullNess } from "./HelpfullNess";
import integrations from "../assets/integrations.png";
import DecisionSupportLogo from "./CustomLogo/HomePage/DecisionSupport";
import CommunicationCollaborationLogo from "./CustomLogo/HomePage/Communitcation&Collaboration";

const FeaturedSolution = ({ imageLeft = false, data, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.4,
  });

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      setHasAnimated(true);
    } else if (!inView && hasAnimated) {
      controls.start("hidden");
    }
  }, [controls, inView, hasAnimated]);

  const contentVariants = {
    hidden: { opacity: 0, x: imageLeft ? 50 : -50 },
    visible: { opacity: 1, x: 0 },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: imageLeft ? -50 : 50 },
    visible: { opacity: 1, x: 0 },
  };

  const renderImageOrComponent = () => {
    if (React.isValidElement(data.visual)) {
      return <div className="w-full overflow-hidden">{data.visual}</div>;
    } else if (typeof data.visual === "string") {
      if (data.visual.endsWith(".gif")) {
        return (
          <div className="w-full overflow-hidden rounded-lg">
            <img
              src={data.visual}
              alt={data.imageAlt || "Featured solution visual"}
              className="w-full h-auto max-h-[70vh] object-contain"
            />
          </div>
        );
      }
      return (
        <div className="w-full overflow-hidden">
          <img
            src={data.visual}
            alt={data.imageAlt || "Featured solution visual"}
            className="w-full h-auto max-h-[70vh] object-contain"
          />
        </div>
      );
    }
    return null;
  };

  const backgroundColor = index % 2 === 0 ? "bg-white" : "bg-[#e8f1f8]";

  // Device frame styling for video and GIF content
  const DeviceFrame = ({ children }) => (
    <div className="w-full overflow-hidden px-4">{children}</div>
  );

  return (
    <div
      ref={ref}
      className={`${backgroundColor} min-h-[60vh] w-full overflow-hidden`}
    >
      <div className=" mx-[5%] px-4 flex flex-col lg:flex-row items-center justify-between py-8 max-w-full">
        <motion.div
          className={`w-full lg:w-1/2 ${imageLeft ? "lg:order-2" : ""
            } lg:px-8 mb-8 lg:mb-0`}
          initial="hidden"
          animate={controls}
          variants={contentVariants}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div className="flex items-center mb-4">
            <data.LogoComponent />
          </div>
          <h3 className="text-3xl font-bold text-primary">
            {data.title}
            <div className="w-16 h-1 bg-secondary mb-6"></div>
          </h3>
          <p className="text-primary text-xl font-medium mb-6">
            {data.description}
          </p>
        </motion.div>
        <motion.div
          className={`w-full lg:w-1/2 ${imageLeft ? "lg:order-1" : ""
            } flex items-center justify-center overflow-hidden`}
          initial="hidden"
          animate={controls}
          variants={imageVariants}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          {data.visual &&
            typeof data.visual === "object" &&
            data.visual.type &&
            (data.visual.type.name === "video" ||
              (data.visual.props &&
                data.visual.props.className &&
                data.visual.props.className.includes("rounded-lg"))) ? (
            <DeviceFrame>{renderImageOrComponent()}</DeviceFrame>
          ) : (
            renderImageOrComponent()
          )}
        </motion.div>
      </div>
    </div>
  );
};

const SpideraiFeatured = () => {
  const solutions = [
    {
      imageLeft: false,
      data: {
        title: "Conversational AI",
        subtitle: "",
        description:
          "Ask spiderAI™ anything about your operations, from machine health to production costs. It understands context, remembers past queries, and delivers clear, actionable insights in simple language. No technical expertise required.",
        visual: <SpiderAISearch />,
        imageAlt: "Fuel assembly",
        LogoComponent: ConversationalAILogo,
      },
    },
    {
      imageLeft: false,
      data: {
        title: "Hybrid UI",
        subtitle: "",
        description:
          "Ask a question, get a visual answer. spiderAI™ responds with the most appropriate format - be it text, graphs, maps, or formulas. This approach makes complex data easy to understand, enhancing decision-making across your team.",
        visual:
          "https://minto-spiderai-website.s3.us-east-2.amazonaws.com/UI.gif",
        imageAlt: "Advanced monitoring dashboard",
        LogoComponent: HybridUILogo,
      },
    },
    {
      imageLeft: false,
      data: {
        title: "Helpfulness-Focused Orchestration Engine",
        subtitle: "",
        description:
          "At its core, spiderAI™ is designed to be helpful. It understands your intent, anticipates needs, and coordinates complex workflows to deliver the most relevant information. It learns and adapts to provide increasingly valuable support.",
        visual: <HelpfullNess />,
        LogoComponent: OrchestrationEngineLogo,
      },
    },
    {
      imageLeft: false,
      data: {
        title: "Seamless Communication and Collaboration",
        subtitle: "",
        description:
          "Connect your entire industrial team on one platform. Share real-time machine data, maintenance schedules, and performance reports to improve efficiency and productivity.",
        visual: (
          <div
            className="p-4 rounded-lg"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="relative mx-auto bg-gray-800 border-gray-800 dark:border-gray-800 rounded-lg border-[5px] max-w-4xl">
              <div className="w-[24px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -bottom-[7px] left-[54px] rounded-s-lg"></div>
              <div className="w-[35px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -bottom-[7px] left-[94px] rounded-s-lg"></div>
              <div className="w-[35px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -bottom-[7px] left-[139px] rounded-s-lg"></div>
              <div className="w-[48px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -top-[7px] left-[36px] rounded-e-lg"></div>
              <div className="rounded-lg overflow-hidden flex justify-center items-center bg-white dark:bg-gray-800">
                <div className="flex bg-white justify-center p-4 overflow-hidden rounded-b-sm">
                  <img
                    src="https://minto-spiderai-website.s3.us-east-2.amazonaws.com/collab.gif"
                    alt="Communication and Collaboration Platform"
                    className="w-full h-auto object-contain max-h-[60vh]"
                  />
                </div>
              </div>
            </div>
          </div>
        ),
        imageAlt: "Fuel assembly",
        LogoComponent: CommunicationCollaborationLogo,
      },
    },

    {
      imageLeft: false,
      data: {
        title: "AI-Powered Decision Support",
        subtitle: "",
        description:
          "AI that processes your industrial data to provide actionable insights. Helps identify optimization opportunities, predict potential issues, and recommend solutions to improve operational efficiency.",
        visual: (
          <div
            className="p-4 rounded-lg"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="relative mx-auto bg-gray-800 border-gray-800 dark:border-gray-800 rounded-lg border-[5px] max-w-4xl">
              <div className="w-[24px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -bottom-[7px] left-[54px] rounded-s-lg"></div>
              <div className="w-[35px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -bottom-[7px] left-[94px] rounded-s-lg"></div>
              <div className="w-[35px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -bottom-[7px] left-[139px] rounded-s-lg"></div>
              <div className="w-[48px] h-[2px] bg-gray-800 dark:bg-gray-800 absolute -top-[7px] left-[36px] rounded-e-lg"></div>
              <div className="rounded-lg overflow-hidden flex justify-center items-center bg-white dark:bg-gray-800">
                <div className="flex bg-white justify-center p-4 overflow-hidden rounded-b-sm">
                  <video
                    src="https://minto-spiderai-website.s3.us-east-2.amazonaws.com/Ai_powered_decision_support.mp4"
                    alt="AI-Powered Decision Support System"
                    className="w-full h-auto object-contain max-h-[60vh]"
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
              </div>
            </div>
          </div>
        ),
        imageAlt: "Fuel assembly",
        LogoComponent: DecisionSupportLogo,
      },
    },

    // {
    //   imageLeft: false,
    //   data: {
    //     title: "Integrations with Multiple Data Sources",
    //     subtitle: "",
    //     description:
    //       "spiderAI™ connects all your data sources into one unified system. From maintenance logs to real-time sensor data, from employee notes to equipment manuals - it brings together information that was previously siloed, revealing new insights.",
    //     visual: integrations,
    //     imageAlt: "Fuel assembly",
    //     LogoComponent: IntegrationsMultipleDatasourcesLogo,
    //   },
    // },
  ];

  return (
    <div>
      {solutions.map((solution, index) => (
        <FeaturedSolution
          key={index}
          imageLeft={solution.imageLeft}
          data={solution.data}
          index={index}
        />
      ))}
    </div>
  );
};

export default SpideraiFeatured;
