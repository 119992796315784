import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import MachineCoverage from "./MachineCoverage";
import HighAccuracyMetrics from "./HighAccuracyMetrics";
import HighFidility from "./HighFidility";
import BentoGraphs from "./BentoGraphs";
import SignalCapture from "./SignalCapture";
import KpiDashboard from "./KpiDashboard";
import high from "../assets/high-accuracy-metrics.jpg";

const Robust = () => {
  return (
    <div className="w-full">
      <img
        src={high}
        alt="deployment"
        className="w-full object-contain rounded-lg"
      />
    </div>
  );
};

const Tech = () => {
  const [expandedSection, setExpandedSection] = useState("highAccuracy");

  const sections = {
    highAccuracy: {
      title: "High-Precision Metrics & Analytics",
      description:
        "Industry-leading measurement accuracy with < 0.1% error rates. Advanced calibration and noise reduction algorithms ensure reliable data for critical decision-making.",
      content: <KpiDashboard />,
    },
    comprehensiveCoverage: {
      title: "Comprehensive Machine Coverage",
      description:
        "Monitor your entire fleet of industrial equipment from a single unified platform. Advanced sensor networks provide 100% coverage across motors, drives, pumps, and auxiliary systems with automated health tracking.",
      content: <MachineCoverage />,
    },

    highFidelity: {
      title: "High-Fidelity Data Capture",
      description:
        "Capture intricate machine behavior and operational patterns across your entire plant. High-frequency sampling reveals hidden connections, anomalies, and optimization opportunities in your industrial network.",
      content: <HighFidility />,
    },
    marineGrade: {
      title: "Component Level Health",
      description:
        "Electric signatures reveal your machine's complete story. Track every aspect of your rotating equipment - power input, motor health, transmission system, and load behavior - through one powerful analysis technique.",
      content: <SignalCapture />,
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(key);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FFF5F2] to-white">
      <div className="max-w-7xl mx-auto p-4 lg:p-8">
        <div className="text-center mb-6 lg:mb-12">
          <div className="text-primary font-semibold mb-4">TECHNOLOGY</div>
          <h1 className="text-3xl lg:text-4xl font-bold text-secondary/100 max-w-4xl mx-auto">
            From High-Fidelity Electrical Signals to Plant-Level Coverage
          </h1>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-4 lg:p-8">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
            <div className="lg:w-1/3 flex flex-col">
              {Object.entries(sections).map(([key, section]) => (
                <div
                  key={key}
                  className="border-b border-gray-100 last:border-b-0"
                >
                  <div className="py-3 lg:py-4">
                    <button
                      className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                        expandedSection === key
                          ? "text-secondary"
                          : "text-primary hover:text-primary"
                      }`}
                      onClick={() => handleSectionClick(key)}
                    >
                      <span className="font-semibold text-sm lg:text-base">
                        {section.title}
                      </span>
                      <span className="transform transition-transform duration-200 flex-shrink-0 ml-2">
                        {expandedSection === key ? (
                          <Minus className="w-4 h-4 lg:w-5 lg:h-5" />
                        ) : (
                          <Plus className="w-4 h-4 lg:w-5 lg:h-5" />
                        )}
                      </span>
                    </button>
                    <div
                      className={`transition-all duration-300 ease-in-out ${
                        expandedSection === key
                          ? "max-h-40 opacity-100 mt-2"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      {section.description && (
                        <div className="text-xs lg:text-sm text-primary">
                          {section.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="lg:w-2/3">
              <div className="rounded-xl w-full">
                <div className="w-full">
                  {sections[expandedSection].content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
