import React from "react";
import HomeHeroSection from "../components/HomeHeroSection";
import ProductCards from "../components/ProductCards";
import ContactUs from "../components/ContactUs";
import LogoScroll from "../components/CompaniesCarousle";
import WhatWeAreSection from "../components/WhatAreWeSection";
import { Carousel } from "../components/Stories";
import Testimonials from "../components/Testimonials";
import { Cog, Activity, Brain, Scale, Wrench, Zap } from "lucide-react";

import ApplicationsSection from "../components/applicationsSection";

import UseCases from "../components/ui/Usecases";
import { useMediaQuery } from "react-responsive";
import Counter from "../components/ui/Counter";
import { Formatter } from "../components/ui/Counter";

import SEO from "../components/SEO";
import { seoConfig } from "../config/seo";

import { getCDNUrl } from "../utils/cdn";

const StatItem = ({ value, description }) => (
  <div className="flex flex-col items-center text-center p-4">
    <h2 className="text-6xl font-bold text-white mb-2">{value}</h2>
    <p className="text-md font-medium text-white">{description}</p>
  </div>
);

function FeatureTag({ icon: Icon, text, color }) {
  return (
    <div className="flex items-center gap-2">
      <div className="text-secondary flex-shrink-0">
        <Icon className="w-5 h-5" />
      </div>
      <span className="text-primary text-sm font-medium">{text}</span>
    </div>
  );
}

export default function HomePage() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const boxes = [
    {
      title: "Understanding Hot Saws Beyond Traditional Monitoring",
      image: getCDNUrl("/useCase-hotSaw.jpg"),
      alt: "Worker analyzing machine data",
      url: "/usecase-health-and-load-profile",
    },
    {
      title: "The Vibro Motor Challenge - Monitor Where Vibration Sensors Fail",
      image: getCDNUrl("/CaseStudy-2.jpg"),
      alt: "Machine health monitoring system",
      url: "/comingsoon",
    },
  ];
  return (
    <>
      {/* <SEO
        title="Home"
        description="Your Machines's Full Potential Unleashed : One technology system,
              does it all !"
        url="https://minto.ai/"
      /> */}

      <SEO
        {...seoConfig.home}
        image="https://s3.us-east-2.amazonaws.com/minto.ai-resources/website/spiderAI_favicon.png"
        url="https://minto.ai/"
      />

      {/* Hero section start */}
      <HomeHeroSection />
      {/* hero section end */}

      {/* Partner Logo start */}
      <LogoScroll />

      <div className="max-w-7xl mx-auto px-4">
        {/* Main Headline */}
        <div className="text-center">
          <div className="text-primary p-8  max-w-6xl mx-auto">
            <div className="text-2xl sm:text-3xl lg:text-2xl  text-primary leading-tight">
              <span className="font-semibold text-secondary">iHz™</span>,{" "}
              <b>Industrial IoT</b> system that is not only <b>reliable</b> &{" "}
              <b>accurate</b> but also robust and truly <b>AI-driven</b> to
              monitor Electrical Rotating Equipments, Machines and
              Infrastructure.
            </div>
          </div>
        </div>
      </div>

      <section className="container mx-auto px-4 py-12 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-6 gap-8 items-start">
            {/* Video Section */}
            <div className="lg:col-span-5 lg:order-2 order-1 rounded-xl shadow-lg overflow-hidden">
              <video
                src={`${getCDNUrl("/hot_saw_performance.mp4")}`}
                className="w-full h-full object-contain rounded-xl"
                autoPlay
                controls
                loop
                muted
                playsInline
                webkit-playsinline="true"
              />
            </div>

            {/* Feature Tags */}
            <div className="lg:col-span-1 lg:order-1 order-2 w-full">
              <div className="grid sm:grid-cols-2 lg:grid-cols-1 gap-6 sm:gap-4 pt-8">
                {/* Each feature in its own cell */}
                <div className="flex items-center">
                  <FeatureTag
                    icon={Cog}
                    text="Total equipment Intelligence"
                    color="#3b82f6"
                  />
                </div>
                <div className="flex items-center">
                  <FeatureTag
                    icon={Activity}
                    text="Electrical Signatures Driven"
                    color="#3b82f6"
                  />
                </div>
                <div className="flex items-center">
                  <FeatureTag
                    icon={Wrench}
                    text="Installation inside Electrical panel"
                    color="#3b82f6"
                  />
                </div>
                <div className="flex items-center">
                  <FeatureTag
                    icon={Brain}
                    text="AI Driven workflows"
                    color="#3b82f6"
                  />
                </div>
                <div className="flex items-center">
                  <FeatureTag
                    icon={Zap}
                    text="High-Fidelity Data"
                    color="#3b82f6"
                  />
                </div>
                <div className="flex items-center">
                  <FeatureTag
                    icon={Scale}
                    text="Scalable for diverse drive trains"
                    color="#3b82f6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Partner Logo end */}

      {/* featuresSection start */}
      {/* 
      <FeaturesSection /> */}

      {/* featuresSection end */}

      {/* technology section start */}
      <WhatWeAreSection />
      {/* technology section end */}

      {/* video section start */}

      <div className="bg-white">
        <div className="mx-[5%] pt-10">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center">
              <h2 className="text-3xl font-bold text-primary mb-2">
                Know about spiderAI™ and iHz™ - systems !
              </h2>
              <div className="h-1 w-60 bg-secondary"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto py-10">
        <div className="relative w-full  rounded-lg overflow-hidden">
          <video
            src={`${getCDNUrl("/Minto.mp4")}`}
            className="w-full h-full "
            autoPlay
            controls
            loop
            muted
            playsInline
            webkit-playsinline="true"
          />
        </div>
      </div>

      {/* video section end */}

      {/* Product Cards start */}
      <ProductCards />

      {/* Product cards end */}

      {/* Application start */}
      <div className="bg-secondary-background pb-5">
        <div className="mx-[5%] pt-12 ">
          <h2 className="text-3xl flex justify-start font-bold text-primary mb-2">
            Applications
          </h2>
          <div className="h-1 bg-secondary w-12 mb-6"></div>
          <p className="text-primary justify-start max-w-3xl text-lg">
            Every system is designed by balancing fault tolerance requirements,
            degrees of freedom (DoF), and functional requirements. We designed
            our iHz™ as a high-fidelity system to comprehend these system design
            variables, enabling efficient problem-solving.
          </p>
        </div>
        <ApplicationsSection />
      </div>

      {/* Application end */}

      {/* Projects start */}

      <div className="bg-white pb-5">
        <div className="mx-[5%] pt-12 ">
          <h2 className="text-3xl flex justify-start font-bold text-primary mb-2">
            Projects & more
          </h2>
          <div className="h-1 bg-secondary w-12"></div>
        </div>
        <Carousel />
      </div>

      {/* Projects end */}

      {/* Testimonials start */}

      <div className="bg-secondary-background">
        <div className="mx-[5%] pt-10">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center">
              <h2 className="text-3xl font-bold text-primary mb-2">
                Testimonials
              </h2>
              <div className="h-1 w-20 bg-secondary"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" rounded-md flex flex-col antialiased bg-primary-background items-center justify-center relative overflow-hidden">
        <Testimonials />
      </div>

      {/* Testimonials end */}

      {/* KPI start */}

      <div className="bg-primary p-10">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="flex flex-col items-center text-center p-4">
            <h2 className="text-6xl font-bold text-white mb-2">
              {" "}
              <Counter
                targetValue={2.2}
                format={Formatter.decimal}
                className="text-6xl"
                decimals={1}
              />
              M+
            </h2>
            <p className="text-md font-medium text-white">
              Machine hours monitored
            </p>
          </div>
          <div className="flex flex-col items-center text-center p-4">
            <h2 className="text-6xl font-bold text-white mb-2">
              {" "}
              <div className="flex justify-center">
                <Counter targetValue={15} className="text-6xl" />
              </div>
            </h2>
            <p className="text-md font-medium text-white">Plants monitored</p>
          </div>
          <div className="flex flex-col items-center text-center p-4">
            <h2 className="text-6xl font-bold text-white mb-2">
              <div className="flex justify-center">
                <Counter targetValue={50} className="text-6xl" />+
              </div>
            </h2>
            <p className="text-md font-medium text-white">
              Diverse machine types
            </p>
          </div>
          <div className="flex flex-col items-center text-center p-4">
            <h2 className="text-6xl font-bold text-white mb-2">
              {" "}
              <div className="flex">
                <Counter
                  format={Formatter.decimal}
                  targetValue={98.6}
                  className="text-6xl"
                  decimals={1}
                />{" "}
                <div className="text-6xl">%</div>
              </div>
            </h2>
            <p className="text-md font-medium text-white">iHz™ Uptime</p>
          </div>
        </div>
      </div>
      {/* KPI end */}

      {/* CTA start */}

      {/* CTA end */}

      {/* Case studies start */}
      <div className="bg-primary-background pb-5">
        <div className="mx-[5%] pt-12 ">
          <h2 className="text-3xl flex justify-start font-bold text-primary mb-2">
            Case Studies
          </h2>
          <div className="h-1 bg-secondary w-12"></div>
        </div>
      </div>
      <div className="bg-primary-background pb-10">
        <div className="mx-[5%]">
          <UseCases boxes={boxes} />
        </div>
      </div>
      <div className="w-full p-12 bg-secondary-background ">
        <div className="flex flex-col md:flex-row items-start  mx-[5%] justify-between">
          <div className="flex-grow pr-6">
            <h2 className=" text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-primary leading-relaxed mb-8 md:mb-0">
              "The world runs on electricity, including your equipment. So why
              not choose iHz™ total equipment intelligence system"
            </h2>
          </div>
          <div className="w-full md:w-auto flex items-center justify-center">
            <ContactUs />
          </div>
        </div>
      </div>

      {/* Case studies end */}
    </>
  );
}
