// src/config/seo.js
import favicon from "../assets/favicon.ico";

// Default SEO Configuration
export const defaultSEO = {
  title: "minto.ai",
  description:
    "Your Machines's Full Potential Unleashed : One technology system, does it all !",
  image:
    "https://s3.us-east-2.amazonaws.com/minto.ai-resources/website/spiderAI_favicon.png",
  url: "https://minto.ai",
  type: "website",
  keywords:
    "AI, Industrial IoT, Predictive Maintenance, Industry 4.0, Manufacturing AI, Technology System",
  author: "minto.ai",
};

// Main Pages SEO Configuration
export const seoConfig = {
  home: {
    title: "minto.ai - Technology System",
    description:
      "Your Machines's Full Potential Unleashed : One technology system, does it all!",
    type: "website",
    keywords:
      "industrial AI, IoT platform, predictive maintenance, smart manufacturing, Industry 4.0, Technology Systems",
  },
  about: {
    title: "About Us | minto.ai",
    description:
      "We build accurate and reliable technology systems that keep the world running on time, efficiently!",
    type: "website",
    keywords:
      "about minto.ai, AI company, industrial technology, innovation, tech startup",
  },
  spiderAi: {
    title: "spiderAI™ - Operational Intelligence Platform | minto.ai",
    description:
      "An assistive copilot to revolutionise how we interact with electrical machines, equipment and infrastructure.",
    type: "product",
    keywords:
      "spiderAI, IoT platform, predictive maintenance, industrial monitoring, asset health",
  },
  ihz: {
    title: "iHz™ - Techonology System For Industrial Equipments | minto.ai",
    description: "Diagnostic System For Industrial Equipments.",
    type: "product",
    keywords:
      "iHz, health monitoring, predictive maintenance, asset management, industrial health",
  },
  blog: {
    title: "Blog | minto.ai Insights",
    description:
      "Stay updated with the latest insights, news, and developments in industrial AI, IoT technology, and digital transformation.",
    type: "website",
    keywords:
      "AI blog, industrial insights, technology news, IoT updates, digital transformation",
  },
  technologies: {
    title: "Technologies | minto.ai",
    description:
      "Explore our cutting-edge technologies in AI, IoT, and machine learning that power next-generation industrial solutions.",
    type: "website",
    keywords:
      "AI technology, IoT solutions, machine learning, industrial tech, digital solutions",
  },
  contactUs: {
    title: "Contact Us | minto.ai",
    description: "Building Tomorrow's Technology Systems Today.",
    type: "website",
    keywords:
      "contact minto.ai, industrial solutions, business inquiry, support, partnership",
  },
  getAccess: {
    title: "Get Access | minto.ai Solutions",
    description:
      "Start your journey with minto.ai. Get access to our advanced industrial AI and IoT solutions today.",
    type: "website",
    keywords:
      "access minto.ai, trial, demo, industrial solutions, platform access",
  },
  careers: {
    title: "Careers | Join minto.ai",
    description:
      "Join us in revolutionizing the industrial world through AI. We're always looking for talented individuals to help shape the future of machine intelligence",
    type: "website",
    keywords:
      "minto.ai careers, jobs, AI jobs, tech careers, industrial technology",
  },
  useCases: {
    title: "See iHz™ systems in action | minto.ai Solutions",
    description:
      "Learn how our customers used iHz to monitor machines and equipment that are not only complex in many dimensions but also difficult to monitor with existing technologies.",
    type: "website",
    keywords:
      "use cases, success stories, industrial applications, AI implementation, case studies",
  },
  applications: {
    title:
      "Applications, Where High Fidelity (Hi-Fi) data meets Intelligence | minto.ai",
    description:
      "Our iHz™ technology system, accurate and reliable bridges the value delivery gap between your equipment monitoring and their use cases.",
    type: "website",
    keywords:
      "AI applications, industrial uses, solution implementation, practical AI, industry solutions",
  },
  comingSoon: {
    title: "Coming Soon | minto.ai",
    description:
      "Stay tuned for exciting new developments and features from minto.ai's industrial technology solutions.",
    type: "website",
    keywords:
      "coming soon, new features, upcoming releases, industrial AI, future technology",
  },
};

// Blog Posts SEO Configuration
export const blogPostsConfig = {
  "embracing-evolution-with-spider-ai": {
    title: "Embracing Evolution with Spider AI | minto.ai Blog",
    description:
      "Explore how Spider AI is transforming industrial maintenance and operations through advanced AI and IoT integration.",
    type: "article",
    keywords:
      "Spider AI, industrial evolution, AI transformation, maintenance optimization",
  },
  "features-of-ai-driven-iot-product": {
    title: "Features of AI-Driven IoT Products | minto.ai Blog",
    description:
      "Deep dive into the key features that make AI-driven IoT products revolutionary in industrial applications.",
    type: "article",
    keywords: "IoT features, AI capabilities, product features, industrial IoT",
  },
  introducing: {
    title: "Introducing minto.ai | Latest Innovation in Industrial AI",
    description:
      "Introducing minto.ai's revolutionary approach to industrial automation and predictive maintenance.",
    type: "article",
    keywords:
      "minto.ai introduction, industrial AI, innovation, new technology",
  },
  "minto-ai-recognized-as-top-2-innovative-startups": {
    title: "minto.ai Recognized as Top 2 Innovative Startups | News",
    description:
      "minto.ai achieves recognition as one of the top innovative startups in industrial AI and IoT solutions.",
    type: "article",
    keywords: "startup recognition, innovation award, AI startup, achievement",
  },
  "profound-impact-of-minto-ai": {
    title: "The Profound Impact of minto.ai in Industry | Success Story",
    description:
      "Discover the transformative impact of minto.ai's solutions on industrial operations and maintenance.",
    type: "article",
    keywords:
      "AI impact, industrial transformation, success story, digital impact",
  },
  "unveiling-inefficiencies-in-traditional-maintenance-methods": {
    title:
      "Unveiling Inefficiencies in Traditional Maintenance | minto.ai Blog",
    description:
      "Learn how traditional maintenance methods fall short and how AI-driven solutions provide superior alternatives.",
    type: "article",
    keywords:
      "maintenance inefficiencies, traditional methods, AI solutions, improvement",
  },
  "bokaro-steel-plant-partners-with-minto-ai": {
    title: "Bokaro Steel Plant Partners with minto.ai | Case Study",
    description:
      "How Bokaro Steel Plant revolutionized their operations through partnership with minto.ai's industrial solutions.",
    type: "article",
    keywords:
      "Bokaro Steel Plant, partnership, industrial implementation, success story",
  },
};

// Use Case Pages SEO Configuration
export const useCaseConfig = {
  useCase1: {
    title: "Industrial Use Case | minto.ai Solutions",
    description:
      "Explore how minto.ai's solutions are implemented in real industrial scenarios for maximum operational efficiency.",
    type: "article",
    keywords: "use case, implementation, industrial application, success story",
  },
  useCaseHealthLoadProfile: {
    title: "Health and Load Profile Analysis | minto.ai Use Case",
    description:
      "Learn how minto.ai's health and load profile analysis helps optimize industrial operations and maintenance.",
    type: "article",
    keywords:
      "health profile, load analysis, industrial monitoring, performance optimization",
  },
};

// Export all configurations
export default {
  defaultSEO,
  seoConfig,
  blogPostsConfig,
  useCaseConfig,
};
