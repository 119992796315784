import React, { useEffect, useRef, useState } from "react";
import Graph from "graphology";
import Sigma from "sigma";
import { Search, ZoomIn, ZoomOut, Maximize2, Home, Info } from "lucide-react";
import EdgeCurveProgram from "@sigma/edge-curve";
import ApplicationGrid from "./ApplicationGrid";
import GraphVisualization from "./GraphVisualization";
import hot from "../assets/useCase-hotSaw.jpg";
import process from "../assets/applications/process_manufacturing.jpg";
import Energy from "../assets/usecases/industries.jpg";
import utilities from "../assets/applications/utilities.jpg";
import transport from "../assets/applications/mobility.jpg";
import heavy from "../assets/applications/heavy_manufacturing.jpg";
import mechanical from "../assets/applications/mechanical_failures.jpg";
import electrical from "../assets/applications/electrical_fault.jpg";
import sub from "../assets/applications/sub-system-level-fault.jpg";
import compo from "../assets/applications/component_level_fault_diagnosis.jpg";
import forceAtlas2 from "graphology-layout-forceatlas2";
import hotstripmill from "../assets/clients/bokaro.jpg";
import SailBokaro from "../components/Projects/SailBokaro";
// import EdgeArrowProgram from "sigma/renderers/edge/programs/edge.arrow";

import { EdgeArrowProgram } from "sigma/rendering";
import Users from "./ApplicationParents/Users";

import vfd from "../assets/applications/vfd.jpg";
import Hv from "../assets/applications/Nodulizer_mixers.jpeg";
import rapid from "../assets/applications/rapid_start_stops.jpg";
import hardto from "../assets/useCase-hotSaw.jpg";
import atex from "../assets/applications/ATEX_zone.jpg";
import dusty from "../assets/applications/Dusty Environment.jpg";

import pumps from "../assets/applications/pump.jpg";
import rod from "../assets/applications/rod_mill.jpg";
import compressor from "../assets/applications/compressor.jpg";
import belts from "../assets/applications/conveyor_belts.jpg";
import fans from "../assets/applications/exhaust_fans.jpg";
import mixers from "../assets/applications/Nodulizer_mixers.jpeg";
import inductionMotors from "../assets/applications/induction_motor.jpg";

import sail from "../assets/clients/sail.jpg";
import kallakal from "../assets/foundery_kalakal_website_display.jpg";
import hil from "../assets/clients/HIL.jpg";
import tata from "../assets/clients/tataSteel.jpg";
import bokaro from "../assets/clients/bokaro.jpg";

import eccentric from "../assets/applications/eccentric_mass_unbalance.webp";
import resiprocating from "../assets/applications/compressor.jpg";
import fluid from "../assets/applications/fluid_coupling.jpeg";
import cardan from "../assets/applications/cardan_shaft.jpg";
import vbelt from "../assets/applications/v-Belt.jpg";
import gearbox from "../assets/applications/gearbox_2.jpg";

import SailBilai from "../components/Projects/SailBilai";
import Kallakal from "../components/Projects/Kallakal";
import Bokaro from "../components/Projects/Bokaro";
import HIL from "../components/Projects/HIL";

import MachinesEquipment from "./ApplicationParents/MachineEquipments";
import FaultTypes from "./ApplicationParents/FaultTypes";
import DriveTrainAccordion from "./ApplicationAccordons/DriveTrainAccordon";
import OperationAccordion from "./ApplicationAccordons/OperationAccordon";
import Industries from "./ApplicationParents/Industries";
import OperationComplexity from "./ApplicationParents/OperationComplexity";
import DriveTrainComplexity from "./ApplicationParents/DriveTrainComplexity";
import UseCaseType from "./ApplicationParents/UseCaseType";

const ComponentMap = {
  pumps: () => (
    <ApplicationGrid
      mainTitle="Pumps"
      mainText="Pumps are among the most widely used rotating equipment, driving critical processes across industries. iHz™'s sensitivity allows us to detect developing mechanical faults like cavitation before they become critical, Blockages map real-time performance against pump characteristics curves, and optimize operational efficiency."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={pumps}
    />
  ),
  "rod-mills": () => (
    <ApplicationGrid
      mainTitle="Rod Mills"
      mainText="These massive machines, characterized by high moment of inertia and low operating speeds, are critical workhorses in material processing. Their size and operational characteristics make them particularly suited for ESA monitoring - any developing fault must overcome significant inertial forces, creating clear signatures in motor current long before damage occurs. In these machines where replacement costs are huge and downtimes impact entire production lines, early detection becomes crucial."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
      title="Featured Projects"
      heroImage={rod}
    />
  ),

  compressors: () => (
    <ApplicationGrid
      mainTitle="Compressors"
      mainText="Compressed air is often called the fourth utility in industry, making compressors critical for pneumatic systems and plant operations. Their reciprocating nature creates unique load patterns and stress points that need careful monitoring. iHz™'s sensitivity to these distinct signatures."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={compressor}
    />
  ),

  "conveyor-belts": () => (
    <ApplicationGrid
      mainTitle="Conveyor Belts"
      mainText="These material handling systems are the lifelines of industrial operations, moving tons of material 24x7. From long belt runs to inclined systems, they face varying loads and multiple stress points across their drive trains - motor, fluid couplings, gearboxes, and pulleys. Any failure can halt entire production lines, making early detection crucial."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={belts}
    />
  ),

  "exhaust-fans": () => (
    <ApplicationGrid
      mainTitle="Industrial Fans"
      mainText="Operating continuously in high-temperature zones and aggressive environments, these machines are critical for heat management and air quality. When fans fail, entire production lines can face emergency shutdowns. iHz™ helps detect developing issues while monitoring through varying speeds and loads."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={fans}
    />
  ),

  mixers: () => (
    <ApplicationGrid
      mainTitle="Mixers"
      mainText="These machines perform critical mixing operations where blade speed, temperature, and mixing efficiency directly impact product quality. Whether it's high-speed hot mixers pushing 130°C or slow-speed agitators blending heavy materials, the motors endure varying loads and thermal stresses. iHz™ excels at detecting subtle changes in shaft alignment, and mixing efficiency patterns before they affect batch quality or lead to catastrophic failures."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
        {
          title: "HIL",
          location: "",
          imageSrc: hil,
          children: <HIL />,
        },
      ]}
      title="Featured Projects"
      heroImage={mixers}
    />
  ),

  "induction-motors": () => (
    <ApplicationGrid
      mainTitle="Induction Motors"
      mainText="These versatile machines power industry across diverse applications and challenging conditions - from dusty environments to explosive atmospheres, high temperatures to hard-to-reach locations. Despite their reliability, motors face both electrical stresses (winding degradation, rotor issues, voltage unbalance) and mechanical challenges (bearing failures, shaft misalignment). ESA becomes powerful here - by analyzing current signatures, we detect developing issues long before they become critical, whether it's early-stage rotor bar cracks or bearing wear. It's about understanding the motor's language through its electrical patterns"
      cards={[
        {
          title: "Hot Strip Mill",
          location: "",
          imageSrc: hotstripmill,
          children: <SailBokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage={inductionMotors}
    />
  ),

  "mechanical-faults": () => (
    <ApplicationGrid
      mainTitle="Mechanical Faults"
      mainText="Every rotating machine, despite its purpose, faces fundamental mechanical challenges. Through ESA, we've mastered the detection of classic mechanical issues: misalignment of shafts that creates destructive forces, looseness in mountings, unbalance that stresses bearings, and bearing deterioration that can lead to catastrophic failures. What makes our approach unique is detecting these mechanical faults through the motor's electrical signature."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={mechanical}
    />
  ),

  "electrical-faults": () => (
    <ApplicationGrid
      mainTitle="Electrical Faults"
      mainText="The motor is the heart of any rotating system, and its electrical health directly impacts performance and reliability. We detect both developing and acute electrical issues - from rotor bar problems and winding degradation to Current unbalance effects and VFD Related issues."
      cards={[
        {
          title: "Bokaro",
          location: "",
          imageSrc: bokaro,
          children: <Bokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage={electrical}
    />
  ),

  "subsystem-level": () => (
    <ApplicationGrid
      mainTitle="Subsystem Level-Fault diagnosis"
      mainText="When a machine fails, looking at individual components often doesn't tell the complete story. Real insights come from understanding how different parts of the system interact - how power flows from motor through transmission elements to the final load, how misalignment in one component creates stress in another, or how thermal conditions affect the entire drive train. Our diagnostics look at these interactions, identifying not just what failed, but why it failed and how it impacts the complete system."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage={sub}
    />
  ),

  "component-level": () => (
    <ApplicationGrid
      mainTitle="Component Level-Fault diagnosis"
      mainText="Moving beyond just identifying that something is wrong, component-level diagnosis pinpoints exactly which element is failing and how. Whether it's shaft damage, specific bearing race deterioration, key way wear, or blade looseness, precise identification allows maintenance teams to prepare the exact spares and plan repairs efficiently. This level of detail eliminates guesswork, reduces maintenance time, and ensures the right fix the first time."
      cards={[
        {
          title: "Sail Bokaro",
          location: "",
          imageSrc: bokaro,
          children: <SailBokaro />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={compo}
    />
  ),
  "eccentric-mass": () => (
    <ApplicationGrid
      mainTitle="Eccentric Mass Unbalance"
      mainText="Deliberately designed to vibrate, making traditional vibration monitoring not just difficult but almost meaningless. What makes them particularly critical is their rapid path to failure - once wear begins, deterioration can accelerate within days. Their typically hard-to-reach locations and self-vibrating nature eliminate most conventional monitoring options. ESA becomes invaluable here, capturing developing faults through motor current patterns"
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={eccentric}
    />
  ),

  "reciprocating-systems": () => (
    <ApplicationGrid
      mainTitle="Reciprocating Systems"
      mainText="From compressors to piston pumps, these machines present unique monitoring challenges due to their inherent load reversals and cyclic stresses. Unlike steady-state loads, reciprocating motion creates complex force patterns that stress components differently in each cycle. What makes ESA particularly powerful here is its ability to capture these cyclic patterns and detect subtle changes that indicate developing issues - whether it's valve problems, piston wear, or bearing deterioration. The motor's current signature essentially maps out the complete mechanical cycle, revealing issues traditional monitoring might miss."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={resiprocating}
    />
  ),

  "fluid-coupling": () => (
    <ApplicationGrid
      mainTitle="Fluid Coupling"
      mainText="Unlike direct couplings, fluid couplings use hydraulic fluid to transfer power, acting as both a torque limiter and shock absorber. This dynamic behavior creates unique load patterns - from slip during startup to variations under load changes. These changes leaves fingerprints in Current Spectrums."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
      title="Featured Projects"
      heroImage={fluid}
    />
  ),

  "cardan-shafts": () => (
    <ApplicationGrid
      mainTitle="Cardan Shafts"
      mainText="These power transmission elements, add unique complexity to machine monitoring. Their universal joints create distinct torque variations in each revolution, and when these shafts operate under high temperatures and demanding conditions, the monitoring challenge intensifies. ESA's sensitivity to these torque patterns becomes crucial - we can detect developing issues in the joints."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={cardan}
    />
  ),

  "v-belt": () => (
    <ApplicationGrid
      mainTitle="V-belt"
      mainText="One of the most common yet complex power transmission elements. While they provide flexibility in design and shock absorption, their dynamic behavior adds layers to fault detection - from subtle slipping to misalignment and tension issues. Each of these can mimic other fault patterns, making diagnosis tricky. The real challenge is in differentiating between normal belt wear, tension chan"
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={vbelt}
    />
  ),

  gearbox: () => (
    <ApplicationGrid
      mainTitle="Gearbox"
      mainText="gearboxes transfer torque across multiple stages - handling speed reduction, direction changes, and varying load requirements for machines. As they transmit these forces, they experience complex torsional changes throughout their gear stages. ESA's sensitivity to these torque variations makes it remarkably effective - when gear teeth start wearing, backlash develops, or bearings begin to fail, these changes manifest as subtle variations in motor current. This gives us deep insights into gearbox health, catching developing issues before they cascade into system-wide failures."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={gearbox}
    />
  ),

  vfd: () => (
    <ApplicationGrid
      mainTitle="VFD Driven"
      mainText="As supply frequency changes with speed, without tracking supply frequency we lose the crucial context needed for accurate fault detection. Our high-fidelity data acquisition captures every nuance of supply frequency, enabling precise order tracking of mechanical components even through speed variations. This capability is essential because mechanical fault frequencies scale with speed, and without accurate supply frequency tracking, fault signatures cannot be tracked."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
      title="Featured Projects"
      heroImage={vfd}
    />
  ),

  hv: () => (
    <ApplicationGrid
      mainTitle="High Voltage (HV)"
      mainText="Through current transformers, we capture high-fidelity data without compromising safety or requiring machine modifications. For any plant running critical HV machines, iHz is an essential tool in their reliability arsenal."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
      title="Featured Projects"
      heroImage={Hv}
    />
  ),

  lowSpeed: () => (
    <ApplicationGrid
      mainTitle="Low-Speed Operation"
      mainText="Traditional vibration analysis struggles - weaker signals at slower speeds often mean missed early warnings. What makes ESA particularly powerful here is the physics working in our favor. These low-speed components typically have high moment of inertia, meaning any developing fault requires significant changes in motor current to overcome these inertial forces. iHz™'s sensitivity to these current variations gives us a remarkable advantage - we can detect subtle changes long before they become critical issues."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage="https://minto-webapp-v3.s3.us-east-2.amazonaws.com/Low_speed.mp4"
    />
  ),

  rapidStart: () => (
    <ApplicationGrid
      mainTitle="Rapid Start/Stop"
      mainText="Where machines undergo frequent starts and stops, creating high transient loads and stress points. Take the EOT cranes at steel plants where every lift cycle demands rapid movement patterns, or hot saw systems making precision cuts through red-hot steel. Traditional monitoring systems struggle with these transient conditions, often missing critical fault signatures hidden in the start-stop patterns. iHz™ excels here because we capture every start-stop cycle in high fidelity, detecting patterns like motor stalling, overloading, or developing mechanical issues."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Hot Strip Mill",
          location: "",
          imageSrc: hotstripmill,
          children: <SailBokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage={rapid}
    />
  ),

  difficultAccess: () => (
    <ApplicationGrid
      mainTitle="Hard-to-Access Equipment"
      mainText="Some critical machines operate in areas where physical access is severely limited - surrounded by high-temperature zones, or positioned in tight spaces. Traditional monitoring methods requiring direct sensor placement have suspected to high maintanance and overall high CapEx. Our non-intrusive approach becomes invaluable here - by monitoring through motor current, we gain deep insights into machine health without needing physical access to the machine itself."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage={hardto}
    />
  ),

  atex: () => (
    <ApplicationGrid
      mainTitle="ATEX Zones"
      mainText="Where safety is paramount and conventional sensors pose inherent risks. In these hazardous environments, every monitoring component must meet stringent safety requirements. Our non-intrusive approach becomes particularly valuable - by capturing high-fidelity data from motors located in safe zones, we gain deep insights into equipment health without introducing additional ignition risks in the hazardous area"
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage={atex}
    />
  ),

  dustyEnvironments: () => (
    <ApplicationGrid
      mainTitle="Dusty Environments"
      mainText="In environments where airborne particles make conventional monitoring unreliable, maintaining equipment health becomes particularly challenging. Sensors fail, connections degrade, and maintenance itself becomes hazardous. Our monitoring system thrives in these conditions - there's no need for sensitive sensors in the dust zone, no cables to maintain, and no compromise in data quality even when monitoring equipment handling abrasive materials like iron ore and minerals."
      cards={[
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
      title="Featured Projects"
      heroImage={dusty}
    />
  ),

  Users: () => <Users />,
  predictiveMaintenance: () => (
    <ApplicationGrid
      mainTitle="Predictive Maintenance"
      mainText="The journey from reactive to predictive maintenance is often seen as a technology upgrade, but the real transformation lies in redefining how we understand machine health. Rather than waiting for alarms or scheduled checks, we enable maintenance teams to track developing issues days or even months in advance. This early insight isn't just about preventing failures - it's about optimizing maintenance planning, reducing spare parts inventory, and eliminating emergency repairs."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage=""
    />
  ),

  conditionMonitoring: () => (
    <ApplicationGrid
      mainTitle="Condition Monitoring"
      mainText="Moving beyond just tracking machine parameters, true condition monitoring means understanding the health of your equipment in real-time. Through high-fidelity ESA, we provide deep insights into how machines are actually performing under their operating conditions."
      cards={[
        {
          title: "HIL",
          location: "",
          imageSrc: hil,
          children: <HIL />,
        },
      ]}
      title="Featured Projects"
      heroImage=""
    />
  ),

  reliability: () => (
    <ApplicationGrid
      mainTitle="Reliability Engineering"
      mainText="Traditional reliability often relies on statistical probabilities and historical data. We transform this approach by providing actual, real-time health data of critical machines and their subsystems. This enables reliability teams to move from probable time-to-failure estimates to data-driven decisions. Understanding exactly how machines are performing, how they're degrading, and what's causing issues helps build more robust maintenance strategies. Over time, this deep insight helps identify systemic issues, optimize maintenance intervals, and improve overall equipment reliability."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage=""
    />
  ),
  performaceMonitoring: () => {
    <ApplicationGrid
      mainTitle="Performance Monitoring"
      mainText="Traditional performance monitoring often focuses solely on output metrics and OEE calculations, missing the deeper story of machine productivity. We transform this approach by connecting machine health directly to performance metrics, providing a comprehensive view of how equipment condition impacts productivity. By analyzing ESA patterns alongside production data, we can identify subtle inefficiencies that traditional monitoring misses - whether it's gradual speed degradation, inconsistent loading, or process variations that affect output quality. This integrated approach helps production teams optimize machine settings, identify bottlenecks, and make data-driven decisions about when to run equipment harder or pull back for maintenance."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
      ]}
    />;
  },
  rootCauseAnalysis: () => (
    <ApplicationGrid
      mainTitle="Root Cause Analysis / FMEA"
      mainText="When machines fail, understanding the true cause is crucial to prevent recurrence. Traditional analysis often stops at the component level - a bearing failed, a shaft broke. Our high-fidelity data and system-level understanding help uncover the deeper story - why did the bearing fail, what conditions led to the shaft break? By capturing machine behavior through ESA patterns over time, we can trace issues back to their origins, whether it's operational stress, misalignment, or process conditions. This comprehensive understanding makes solutions more effective and lasting."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
      ]}
      title="Featured Projects"
      heroImage=""
    />
  ),

  digitalTransformation: () => (
    <ApplicationGrid
      mainTitle="Digital Transformation"
      mainText="While many see digital transformation as just adding sensors and collecting data, true transformation means converting that data into actionable insights. Our approach focuses on delivering real value - whether it's preventing unplanned downtime, extending equipment life, or optimizing maintenance resources. By combining high-fidelity ESA with intelligent analytics, we help plants move from reactive firefighting to proactive, data-driven decision making. This isn't about digitization for its own sake; it's about using technology to solve real industrial challenges."
      cards={[
        {
          title: "HIL",
          location: "",
          imageSrc: hil,
          children: <HIL />,
        },
      ]}
      title="Featured Projects"
      heroImage=""
    />
  ),

  heavy: () => (
    <ApplicationGrid
      mainTitle="Manufacturing & Heavy Industries"
      mainText="These industries require heavy-duty machines, whether it's crushers, grinders, mixers, or conveyor belts which undergo intense lateral and torsional stress while operating in harsh environmental conditions. Given this environment and operational complexity, iHz proves to be an effective and scalable tool, reaching every corner of your industry without worrying about machine location or accessibility."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
      title="Featured Projects"
      heroImage={heavy}
    />
  ),

  processManufacturing: () => (
    <ApplicationGrid
      mainTitle="Process Manufacturing"
      mainText="These industries are highly sensitive to process parameters, where equipment health directly impacts product quality. From PVC mixing to chemical processing, precise control and consistency are crucial. iHz not only monitors equipment health but also provides valuable operational insights into process efficiency and batch quality, making it a win-win solution for both maintenance and production teams."
      cards={[
        {
          title: "HIL",
          location: "",
          imageSrc: hil,
          children: <HIL />,
        },
      ]}
      title="Featured Projects"
      heroImage={process}
    />
  ),

  energyGeneration: () => (
    <ApplicationGrid
      mainTitle="Energy Generation"
      mainText="Power generation depends heavily on equipment reliability where unplanned outages can be extremely costly. Beyond preventing failures, iHz provides crucial energy-related insights that help optimize operations. In an industry where efficiency improvements directly impact thin margins, having both reliability and energy performance monitoring makes every watt count."
      cards={[]}
      title=""
      heroImage={Energy}
    />
  ),

  utilities: () => (
    <ApplicationGrid
      mainTitle="Utilities & Infrastructure"
      mainText="From commercial buildings to treatment plants, critical equipment like pumps, elevators, escalators, compressors, and exhaust fans form the backbone of modern infrastructure. The stakes are particularly high in wastewater treatment, where pump failures don't just cause operational disruptions - they can lead to downstream pollution and heavy penalties. Real-time monitoring becomes essential when you're processing waste matter on demand with no room for unplanned downtime."
      cards={[
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
      ]}
      title="Featured Projects"
      heroImage={utilities}
    />
  ),

  hotSaw: () => (
    <>
      {/* <GraphVisualization /> */}
      <ApplicationGrid
        mainTitle="Hot Saw"
        mainText="Hot saws in steel mills do one critical job - cutting hot blooms into precise lengths. But running these machines well is challenging. They cut steel at over 1000°C, handle massive load spikes up to 300% during each 2-8 second cut, and operate on a mobile platform in harsh conditions. When something goes wrong, it affects everything from production flow to product quality.

Making the right decisions about this machine isn't just about knowing if it's running or broken. It's about understanding how well it's cutting different bloom types, how reliable the equipment is, whether maintenance is needed, and if it's delivering the quality cuts that customers need. This is why traditional monitoring that just looks at one or two things isn't enough. We need to look at the whole picture - from cut quality to equipment health, from performance to costs - to run these machines effectively in today's demanding steel operations."
        cards={[
          {
            title: "Blooming Mill",
            location: "",
            imageSrc: tata,
            children: <Bokaro />,
          },
        ]}
        title="Featured Projects"
        heroImage={hot}
      />
    </>
  ),

  maintenance: () => (
    <ApplicationGrid
      mainTitle="Maintenance"
      mainText="iHz™ transforms maintenance from reactive to time-wasting routines into focused, condition-based interventions. Teams receive specific alerts about component-level issues with clear action recommendations, eliminating guesswork. By detecting faults up to 6 months before failure, maintenance can be scheduled during planned downtimes. This predictive capability allows teams to reduce safety stock, minimize routine inspections, and deploy resources only where needed."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Kallakal",
          location: "",
          imageSrc: kallakal,
          children: <Kallakal />,
        },
        {
          title: "Sail Bokaro",
          location: "",
          imageSrc: bokaro,
          children: <SailBokaro />,
        },
      ]}
    />
  ),
  operation: () => (
    <ApplicationGrid
      mainTitle="Operation"
      mainText="iHz™ equips operations teams with machine diagnostics to execute production targets effectively. By detecting issues weeks to months in advance, they can confidently run equipment without fear of sudden failures. During shifts, real-time insights about equipment condition help them optimize load distribution and prevent overloading. This predictability helps maintain production schedules even for equipment in harsh, inaccessible, or hazardous environments."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Sail Bhilai",
          location: "",
          imageSrc: sail,
          children: <SailBilai />,
        },
      ]}
    />
  ),
  Reliability: () => (
    <ApplicationGrid
      mainTitle="Reliability"
      mainText="iHz™ provides comprehensive diagnostic data that reveals patterns in equipment behavior where previous systems failed. Teams can analyze fault progression across similar machines, including those in underground, underwater, or harsh environments where traditional sensors struggle. The system's ability to detect both electrical and mechanical faults through electrical signature analysis gives reliability teams unprecedented insight into asset health, helping optimize maintenance intervals and guide equipment investments."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "Sail Bokaro",
          location: "",
          imageSrc: bokaro,
          children: <SailBokaro />,
        },
      ]}
    />
  ),
  production: () => (
    <ApplicationGrid
      mainTitle="Production"
      mainText="iHz™'s diagnostics enable production teams to set achievable targets by providing clear equipment health visibility. Weeks of advance warning about developing issues lets them coordinate maintenance windows without disrupting production plans. The system's ability to monitor even hard-to-reach equipment ensures production schedules account for the real condition of all critical assets, maximizing uptime while avoiding unplanned disruptions."
      cards={[
        {
          title: "Blooming Mill",
          location: "",
          imageSrc: tata,
          children: <Bokaro />,
        },
        {
          title: "HIL",
          location: "",
          imageSrc: hil,
          children: <HIL />,
        },
      ]}
    />
  ),

  transportationMobility: () => (
    <ApplicationGrid
      mainTitle="Transportation & Mobility"
      mainText="The world should run on time. Whether it's railway systems, metro stations, or airport infrastructure, every minute of downtime impacts thousands. These facilities depend on an intricate network of equipment - from escalators and elevators moving people to Conveyor systems ensuring Luggage Delivery. iHz ensures these critical systems keep running reliably, helping maintain the precise schedules that modern mobility demands."
      cards={[]}
      title=""
      heroImage={transport}
    />
  ),
  "Machines & Equipments": () => <MachinesEquipment />,
  "Fault Types": () => <FaultTypes />,
  "Drive Train Complexity": () => <DriveTrainComplexity />,
  "Operational Complexity": () => <OperationComplexity />,
  "Usecase Type": () => <UseCaseType />,

  "Industry Type": () => <Industries />,
};

const data = {
  "Machines & Equipments": [
    { id: "pumps", label: "Pumps" },
    { id: "rod-mills", label: "Rod Mills" },
    { id: "compressors", label: "Compressors" },
    { id: "conveyor-belts", label: "Conveyor Belts" },
    { id: "exhaust-fans", label: "Exhaust Fans" },
    { id: "mixers", label: "Mixers" },
    { id: "induction-motors", label: "Induction Motors" },
    { id: "hotSaw", label: "How Saw" },
  ],
  "Fault Types": [
    { id: "mechanical-faults", label: "Mechanical Faults" },
    { id: "electrical-faults", label: "Electrical Faults" },
    { id: "subsystem-level", label: "Subsystem Level Fault Diagnosis" },
    { id: "component-level", label: "Component Level Fault Diagnosis" },
  ],
  "Drive Train Complexity": [
    { id: "eccentric-mass", label: "Eccentric Mass Unbalance" },
    { id: "reciprocating-systems", label: "Reciprocating Systems" },
    { id: "fluid-coupling", label: "Fluid Coupling" },
    { id: "cardan-shafts", label: "Cardan Shafts" },
    { id: "v-belt", label: "V-belt" },
    { id: "gearbox", label: "Gearbox" },
  ],
  "Operational Complexity": [
    { id: "vfd", label: "VFD Driven" },
    { id: "hv", label: "High Voltage" },
    { id: "lowSpeed", label: "Low-speed Operation" },
    { id: "rapidStart", label: "Rapid Start/stop" },
    { id: "difficultAccess", label: "Hard to Access Equipment" },
    { id: "atex", label: "Atex Zones" },
    { id: "dustyEnvironments", label: "Dusty Environments" },
  ],
  "Usecase Type": [
    { id: "predictiveMaintenance", label: "Predictive Maintenance" },
    { id: "conditionMonitoring", label: "Condition Monitoring" },
    { id: "reliability", label: "Reliability Engineering" },
    { id: "rootCauseAnalysis", label: "Root Cause Analysis /FMEA" },
    { id: "digitalTransformation", label: "Digital Transformation" },
    { id: "performaceMonitoring", label: "Performance Monitoring" },
  ],
  "Industry Type": [
    { id: "heavy", label: "Heavy Industries & Manufacturing" },
    { id: "utilities", label: "Utilities & infrastructure" },
    { id: "processManufacturing", label: "Process Manufacturing" },
    { id: "energyGeneration", label: "Energy Generation" },
    { id: "transportationMobility", label: "Transportation & Mobility" },
  ],

  Users: [
    { id: "maintenance", label: "Maintenance" },
    { id: "operation", label: "Operation" },
    { id: "Reliability", label: "Reliability" },
    { id: "production", label: "Production" },
  ],
};

const categoryColorSchemes = {
  "Machines & Equipments": {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Lighter shade of dark blue
  },
  "Fault Types": {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Lighter shade of orange
  },
  "Drive Train Complexity": {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Muted lighter shade of dark blue
  },
  "Operational Complexity": {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Softer lighter shade of orange
  },
  "Usecase Type": {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Medium shade of blue
  },
  "Industry Type": {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Muted lighter shade of blue
  },
  Users: {
    main: "#112A54", // Muted deep blue
    nodes: "#5275B5", // Soft pastel shade of orange
  },
};

const highlightColors = {
  center: "#FF5757",
  main: "#FF7171", // For category nodes
  leaf: "#FF8A8A", // For leaf/child nodes
};

const getNodeColor = (node, attrs, categoryColorSchemes) => {
  if (attrs.category === "center") {
    return "#184384"; // Darker blue for center
  }
  if (attrs.category === "category") {
    return categoryColorSchemes[attrs.label]?.main || "#455a64";
  }
  return categoryColorSchemes[attrs.parentCategory]?.nodes || "#78909c";
};

const connections = [
  {
    source: "Machines & Equipments",
    targets: [
      {
        id: "pumps",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "component-level",
          "fluid-coupling",
          "lowSpeed",
          "hv",
          "predictiveMaintenance",
          "conditionMonitoring",
          "heavy",
          "utilities",
        ],
      },
      {
        id: "rod-mills",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "gearbox",
          "cardan-shafts",
          "lowSpeed",
          "dustyEnvironments",
          "predictiveMaintenance",
          "reliability",
          "heavy",
        ],
      },
      {
        id: "compressors",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "reciprocating-systems",
          "predictiveMaintenance",
          "rootCauseAnalysis",
          "utilities",
          "processManufacturing",
        ],
      },
      {
        id: "conveyor-belts",
        connections: [
          "mechanical-faults",
          "gearbox",
          "fluid-coupling",
          "v-belt",
          "dustyEnvironments",
          "rapidStart",
          "predictiveMaintenance",
          "conditionMonitoring",
          "heavy",
          "electrical-faults",
        ],
      },
      {
        id: "exhaust-fans",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "difficultAccess",
          "dustyEnvironments",
          "rapidStart",
          "predictiveMaintenance",
          "utilities",
          "processManufacturing",
          "heavy",
          "conditionMonitoring",
          "vfd",
        ],
      },
      {
        id: "mixers",
        connections: [
          "mechanical-faults",
          "electrical-faults",

          "gearbox",
          "fluid-coupling",
          "v-belt",
          "vfd",
          "atex",
          "predictiveMaintenance",
          "conditionMonitoring",
          "rootCauseAnalysis",
          "processManufacturing",
          "performaceMonitoring",
        ],
      },
      {
        id: "hotSaw",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "v-belt",
          "difficultAccess",
          "conditionMonitoring",
          "rootCauseAnalysis",
          "heavy",
          "performaceMonitoring",
          "rapidStart",
        ],
      },

      {
        id: "induction-motors",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "vfd",
          "hv",
          "lowSpeed",
          "difficultAccess",
          "predictiveMaintenance",
          "conditionMonitoring",
          "digitalTransformation",
          "heavy",
          "utilities",
          "processManufacturing",
          "energyGeneration",
          "transportationMobility",
        ],
      },
    ],
  },
  {
    source: "Fault Types",
    targets: [
      {
        id: "mechanical-faults",
        connections: [
          "pumps",
          "rod-mills",
          "compressors",
          "conveyor-belts",
          "exhaust-fans",
          "mixers",
          "induction-motors",
          "predictiveMaintenance",
          "conditionMonitoring",
        ],
      },
      {
        id: "electrical-faults",
        connections: [
          "pumps",
          "rod-mills",
          "compressors",
          "exhaust-fans",
          "mixers",
          "induction-motors",
          "predictiveMaintenance",
          "rootCauseAnalysis",
          "reliability",
        ],
      },
      {
        id: "subsystem-level",
        connections: [
          "pumps",
          "rod-mills",
          "conveyor-belts",
          "mixers",
          "rootCauseAnalysis",
          "reliability",
        ],
      },
      {
        id: "component-level",
        connections: [
          "pumps",
          "compressors",
          "mixers",
          "induction-motors",
          "rootCauseAnalysis",
          "reliability",
        ],
      },
    ],
  },
  {
    source: "Drive Train Complexity",
    targets: [
      {
        id: "eccentric-mass",
        connections: [],
      },
      {
        id: "reciprocating-systems",
        connections: ["compressors", "pumps"],
      },
      {
        id: "fluid-coupling",
        connections: ["pumps", "conveyor-belts"],
      },
      {
        id: "cardan-shafts",
        connections: ["rod-mills"],
      },
      {
        id: "v-belt",
        connections: [
          "conveyor-belts",
          "mixers",
          "subsystem-level",
          "component-level",
        ],
      },
      {
        id: "gearbox",
        connections: [
          "conveyor-belts",
          "rod-mills",
          "subsystem-level",
          "component-level",
        ],
      },
    ],
  },
  {
    source: "Operational Complexity",
    targets: [
      {
        id: "vfd",
        connections: ["induction-motors", "mixers", "compressors"],
      },
      {
        id: "hv",
        connections: [
          "pumps",
          "induction-motors",
          "conveyor-belts",
          "rod-mills",
        ],
      },
      {
        id: "lowSpeed",
        connections: ["rod-mills"],
      },
      {
        id: "rapidStart",
        connections: ["conveyor-belts", "exhaust-fans"],
      },
      {
        id: "difficultAccess",
        connections: ["exhaust-fans", "induction-motors"],
      },
      {
        id: "atex",
        connections: ["mixers", "pumps"],
      },
      {
        id: "dustyEnvironments",
        connections: ["conveyor-belts", "rod-mills", "exhaust-fans"],
      },
    ],
  },
  {
    source: "Usecase Type",
    targets: [
      {
        id: "predictiveMaintenance",
        connections: [
          "pumps",
          "rod-mills",
          "compressors",
          "conveyor-belts",
          "exhaust-fans",
          "mixers",
          "induction-motors",
          "mechanical-faults",
          "electrical-faults",
          "heavy",
          "utilities",
          "processManufacturing",
          "hotSaw",
        ],
      },
      {
        id: "conditionMonitoring",
        connections: [
          "pumps",
          "conveyor-belts",
          "mixers",
          "induction-motors",
          "mechanical-faults",
          "heavy",
        ],
      },
      {
        id: "reliability",
        connections: [
          "rod-mills",
          "component-level",
          "electrical-faults",
          "mechanical-faults",
          "heavy",
          "mixers",
          "conveyor-belts",
        ],
      },
      {
        id: "rootCauseAnalysis",
        connections: [
          "component-level",
          "subsystem-level",
          "electrical-faults",
          "mechanical-faults",
          "mixers",

          "heavy",
          "processManufacturing",
        ],
      },
      {
        id: "digitalTransformation",
        connections: [
          "induction-motors",
          "predictiveMaintenance",
          "heavy",
          "utilities",
          "processManufacturing",
        ],
      },
    ],
  },
  {
    source: "Industry Type",
    targets: [
      {
        id: "heavy",
        connections: [
          "pumps",
          "conveyor-belts",
          "rod-mills",
          "mixers",
          "induction-motors",

          "predictiveMaintenance",
          "conditionMonitoring",
          "digitalTransformation",
          "rootCauseAnalysis",
          "reliability",
        ],
      },
      {
        id: "utilities",
        connections: [
          "pumps",
          "compressors",
          "exhaust-fans",
          "induction-motors",
          "predictiveMaintenance",
          "digitalTransformation",
        ],
      },
      {
        id: "processManufacturing",
        connections: [
          "compressors",
          "mixers",
          "induction-motors",
          "rootCauseAnalysis",
          "predictiveMaintenance",
          "digitalTransformation",

          "conveyor-belts",
          "pumps",
        ],
      },
      {
        id: "energyGeneration",
        connections: ["induction-motors", "pumps", "predictiveMaintenance"],
      },
      {
        id: "transportationMobility",
        connections: ["induction-motors", "predictiveMaintenance"],
      },
    ],
  },
  {
    source: "Users",
    targets: [
      {
        id: "maintenance",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "subsystem-level",
          "component-level",
          "eccentric-mass",
          "reciprocating-systems",
          "fluid-coupling",
          "cardan-shafts",
          "v-belt",
          "gearbox",
          "predictiveMaintenance",
          "heavy",
        ],
      },
      {
        id: "operation",
        connections: [
          "vfd",
          "hv",
          "lowSpeed",
          "rapidStart",
          "difficultAccess",
          "atex",
          "dustyEnvironments",
          "conditionMonitoring",
          "energyGeneration",
          "transportationMobility",
        ],
      },
      {
        id: "Reliability",
        connections: [
          "mechanical-faults",
          "electrical-faults",
          "subsystem-level",
          "component-level",
          "utilities",
          "transportationMobility",
          "rootCauseAnalysis",
        ],
      },
      {
        id: "production",
        connections: [
          "digitalTransformation",
          "processManufacturing",
          "conditionMonitoring",
          "heavy",
        ],
      },
    ],
  },
];

const categoryColors = {
  "Machines & Equipments": "#4287f5",
  "Fault Types": "#f54242",
  "Drive Train Complexity": "#42f554",
  "Operational Complexity": "#f5a442",
  "Usecase Type": "#b042f5",
  "Industry Type": "#42f5f5",
  Users: "#D84315",
};

const Card = ({ className = "", children, ...props }) => {
  return (
    <div
      className={`rounded-lg border border-gray-200 bg-white text-gray-950 shadow-sm ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const CardContent = ({ className = "", children, ...props }) => {
  return (
    <div className={`p-6 pt-0 ${className}`} {...props}>
      {children}
    </div>
  );
};

// Input.jsx
const Input = React.forwardRef(
  ({ className = "", type = "text", ...props }, ref) => {
    return (
      <input
        type={type}
        className={`flex h-10 w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
        ref={ref}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

// Button.jsx
const Button = React.forwardRef(
  (
    {
      className = "",
      variant = "default",
      size = "default",
      children,
      ...props
    },
    ref
  ) => {
    const variants = {
      default: "bg-gray-900 text-gray-50 hover:bg-gray-900/90",
      destructive: "bg-red-500 text-gray-50 hover:bg-red-500/90",
      outline:
        "border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900",
      secondary: "bg-gray-100 text-gray-900 hover:bg-gray-100/80",
      ghost: "hover:bg-gray-100 hover:text-gray-900",
      link: "text-gray-900 underline-offset-4 hover:underline",
    };

    const sizes = {
      default: "h-10 px-4 py-2",
      sm: "h-9 rounded-md px-3",
      lg: "h-11 rounded-md px-8",
      icon: "h-10 w-10",
    };

    return (
      <button
        className={`inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 
          ${variants[variant]} 
          ${sizes[size]} 
          ${className}`}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

const NetworkGraph = () => {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const graphRef = useRef(null);
  const [showLabels, setShowLabels] = useState(true);
  const [nodeInfo, setNodeInfo] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [matchingNodes, setMatchingNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isHelpVisible, setIsHelpVisible] = useState(true);

  const createGraphConnections = (graph, connections) => {
    connections.forEach((category) => {
      category.targets.forEach((target) => {
        if (!graph.hasEdge(category.source, target.id)) {
          graph.addEdge(category.source, target.id, {
            size: 2.5, // Increased base edge size
            color: "#455a64", // Darker edge color
            type: "curve",
            curveRatio: 0.2,
          });
        }

        target.connections.forEach((conn) => {
          if (!graph.hasEdge(target.id, conn)) {
            graph.addEdge(target.id, conn, {
              size: 2, // Increased secondary edge size
              color: "#78909c", // Lighter but still visible
              type: "curve",
              curveRatio: 0.3,
            });
          }
        });
      });
    });
  };

  const getSigmaConfig = () => {
    // Base configuration that's common for all screen sizes
    const baseConfig = {
      minCameraRatio: 0.2,
      allowInvalidContainer: true,
      defaultEdgeType: "curvedArrow",
      edgeProgramClasses: {
        curve: EdgeCurveProgram,
        arrow: EdgeArrowProgram,
      },
      maxCameraRatio: 2,
      labelSize: 14,
      labelColor: { color: "#000000" },
      nodeColor: { by: "color", attribute: "color" },
      edgeColor: { color: "#90a4ae" },
      renderLabels: true,
      allowInvalidContainer: true,
      labelDensity: 0.07,
      labelGridCellSize: 60,
      labelRenderedSizeThreshold: 2,
      labelPlacement: "center",
      defaultEdgeType: "arrow",
      defaultEdgeArrowSize: 20,
      defaultEdgeArrowTargetSize: 20,
      defaultEdgeColor: "#90a4ae",
      labelFont: "Helvetica Neue, Arial, sans-serif",
      mouseEnabled: true,
      touchEnabled: false,
      mouseWheelEnabled: false,
      labelBackgroundColor: { color: "#ffffff" },
      labelBackgroundOpacity: 0.9,

      edgeReducer: (edge, data) => ({
        ...data,
        zIndex: data.highlighted ? 10 : -1,
        color: data.highlighted ? "#ff5757" : "#e8f1f8",
        size: data.highlighted ? 3 : 2,
        arrowSize: data.arrowSize, // Preserve the arrow size
      }),
      nodeReducer: (node, data) => ({
        ...data,
        zIndex: data.highlighted ? 20 : 1,
        color: data.highlighted ? data.color : `${data.color}cc`,
        size: data.highlighted ? data.size * 1.1 : data.size,
      }),
    };

    // Add screen-specific configurations
    if (window.innerWidth < 768) {
      // Mobile devices
      return {
        ...baseConfig,
        zoomingRatio: 1,
        labelSize: 12, // Smaller labels for mobile
        labelGridCellSize: 50, // Adjusted grid size for mobile
        touchEnabled: true, // Enable touch for mobile
      };
    } else if (window.innerWidth < 1024) {
      // Tablets
      return {
        ...baseConfig,

        touchEnabled: true,
      };
    } else {
      // Desktop
      return {
        ...baseConfig,
      };
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    graphRef.current = new Graph();
    const graph = graphRef.current;
    const categories = Object.keys(data);
    const angleStep = (2 * Math.PI) / categories.length;

    const radius = {
      center: 0,
      category: 180,
      items: 380,
    };

    const defaultSizes = {
      center: 25,
      category: 18,
      item: 10,
    };

    // Add central node
    graph.addNode("center", {
      x: 0,
      y: 0,
      size: defaultSizes.center,
      label: "iHz™ & spiderAI™",
      color: "#4e79a7",
      category: "center",
      fixed: false,
      labelSize: 18,
      labelColor: "#000000",
      labelWeight: "bold",
      labelBackground: "rgba(255,255,255,0.8)",
      labelBorderRadius: 4,
      labelPadding: 4,
    });

    // Add category nodes and their items with matching colors
    categories.forEach((category, i) => {
      const angle = i * angleStep;
      const x = Math.cos(angle) * radius.category;
      const y = Math.sin(angle) * radius.category;
      const colorScheme = categoryColorSchemes[category];

      graph.addNode(category, {
        x,
        y,
        size: defaultSizes.category,
        label: category,
        color: getNodeColor(
          { category: "category", label: category },
          { category: "category", label: category },
          categoryColorSchemes
        ),
        category: "category",
        fixed: false,
        labelSize: 14,
      });

      graph.addEdge("center", category, {
        size: 2,
        color: "#ccc",
        type: "curve",
        curveRatio: 0.1,
      });

      const items = data[category];
      const itemAngleStep = (angleStep * 0.8) / (items.length + 1);

      items.forEach((item, j) => {
        const itemAngle = angle - angleStep * 0.4 + (j + 1) * itemAngleStep;
        const itemX = Math.cos(itemAngle) * radius.items;
        const itemY = Math.sin(itemAngle) * radius.items;

        graph.addNode(item.id, {
          x: itemX,
          y: itemY,
          size: defaultSizes.item,
          label: item.label,
          color: getNodeColor(
            { category: "item", parentCategory: category },
            { category: "item", parentCategory: category },
            categoryColorSchemes
          ),
          category: "item",
          parentCategory: category,
          fixed: false,
          labelSize: 12,
        });

        graph.addEdge(category, item.id, {
          size: 1,
          color: "#ddd",
          type: "curve",
          curveRatio: 0.15,
        });
      });
    });

    createGraphConnections(graph, connections);

    // Apply the ForceAtlas2 layout to the graph
    forceAtlas2.assign(graph, {
      iterations: 10, // Adjust the number of iterations as needed
      settings: {
        barnesHutOptimize: true,
        barnesHutTheta: 0.5,
        adjustSizes: true,
        edgeWeightInfluence: 1,
        scalingRatio: 2,
        gravity: 1,
      },
    });

    rendererRef.current = new Sigma(
      graph,
      containerRef.current,
      getSigmaConfig()
    );

    const getPathToRoot = (nodeId) => {
      const path = [nodeId];
      let current = nodeId;

      while (current !== "center") {
        const neighbors = graph.neighbors(current);
        // Find the neighbor that's closer to the center
        const parent = neighbors.find((n) => {
          const attrs = graph.getNodeAttributes(n);
          return attrs.category === "category" || attrs.category === "center";
        });

        if (parent) {
          path.push(parent);
          current = parent;
        } else {
          break;
        }
      }

      return path;
    };

    const cleanupHotsawVisualization = (graph) => {
      // Remove all custom edges added for 'hotSaw'
      graph.forEachEdge((edgeId) => {
        if (edgeId.startsWith("hotsaw-custom-")) {
          graph.dropEdge(edgeId);
        }
      });

      // Reset all edges to their default state and unhide them
      graph.forEachEdge((edgeId) => {
        graph.setEdgeAttribute(edgeId, "color", "rgba(144, 164, 174, 0.8)");
        graph.setEdgeAttribute(edgeId, "size", 2);
        graph.setEdgeAttribute(edgeId, "zIndex", 0);
        graph.setEdgeAttribute(edgeId, "highlighted", false);
        graph.setEdgeAttribute(edgeId, "hidden", false); // Unhide edges
      });

      // Reset all nodes to their default state
      graph.forEachNode((nodeId) => {
        const attrs = graph.getNodeAttributes(nodeId);
        attrs.highlighted = false;
        attrs.size = defaultSizes[attrs.category] || defaultSizes.item;
        attrs.forceLabel = false;

        // Reset node colors
        if (attrs.category === "center") {
          attrs.color = "#4e79a7";
        } else if (attrs.category === "category") {
          attrs.color = categoryColorSchemes[attrs.label]?.main || "#455a64";
        } else {
          attrs.color =
            categoryColorSchemes[attrs.parentCategory]?.nodes || "#78909c";
        }
      });
    };

    // Click handler with updated highlighting and overlay
    // Inside useEffect, update the click handler:
    // Click handler with updated highlighting and overlay
    rendererRef.current.on("clickNode", ({ node }) => {
      // Clear previous selection first
      graph.forEachNode((n) => {
        const attrs = graph.getNodeAttributes(n);
        attrs.highlighted = false;
        attrs.size = defaultSizes[attrs.category] || defaultSizes.item;
        attrs.forceLabel = false;
        if (attrs.parentCategory === "Users") {
          attrs.color = categoryColorSchemes["Users"].nodes;
        } else {
          attrs.color = getNodeColor(n, attrs, categoryColorSchemes);
        }
      });

      graph.forEachEdge((e) => {
        graph.setEdgeAttribute(e, "color", "rgba(144, 164, 174, 0.8)");
        graph.setEdgeAttribute(e, "size", 2);
        graph.setEdgeAttribute(e, "zIndex", 0);
        graph.setEdgeAttribute(e, "highlighted", false);
      });

      // If clicking the same node, reset everything
      if (selectedNode === node) {
        cleanupHotsawVisualization(graph);
        setSelectedNode(null);
        setNodeInfo(null);
        setSelectedComponent(null);
        rendererRef.current.refresh();
        return;
      }

      cleanupHotsawVisualization(graph);

      if (node === "hotSaw") {
        // Reset all nodes and edges first
        graph.forEachNode((n) => {
          const attrs = graph.getNodeAttributes(n);
          attrs.highlighted = false;
          attrs.size = defaultSizes[attrs.category] || defaultSizes.item;
          attrs.forceLabel = false;
        });

        graph.forEachEdge((e) => {
          graph.setEdgeAttribute(e, "highlighted", false);
          graph.setEdgeAttribute(e, "color", "rgba(144, 164, 174, 0.8)");
          graph.setEdgeAttribute(e, "size", 2);
          graph.setEdgeAttribute(e, "zIndex", 0);
        });

        // Define the edges we want to highlight or create
        const customEdges = [
          ["Industry Type", "predictiveMaintenance"],
          ["predictiveMaintenance", "hotSaw"],
          ["hotSaw", "center"],
          ["hotSaw", "Drive Train Complexity"],
          ["Drive Train Complexity", "Operational Complexity"],
          ["Operational Complexity", "Fault Types"],
          ["Fault Types", "Users"],
          ["performaceMonitoring", "hotSaw"],
          ["heavy", "Industry Type"],
          ["center", "performaceMonitoring"],
        ];

        // Highlight or create edges
        customEdges.forEach(([source, target]) => {
          if (graph.hasNode(source) && graph.hasNode(target)) {
            let edge;

            // Check if edge exists in either direction
            try {
              edge = graph.edge(source, target);
            } catch {
              try {
                edge = graph.edge(target, source);
              } catch {
                edge = null;
              }
            }

            if (edge) {
              // If edge exists, highlight it
              graph.setEdgeAttribute(edge, "color", "#1e88e5");
              graph.setEdgeAttribute(edge, "size", 10);
              graph.setEdgeAttribute(edge, "zIndex", 2);
              graph.setEdgeAttribute(edge, "type", "arrow");
              graph.setEdgeAttribute(edge, "highlighted", true);
              graph.setEdgeAttribute(edge, "arrowSize", 20);
            } else {
              // If edge doesn't exist, create it
              const edgeId = `hotsaw-custom-${source}-${target}`;
              graph.addEdge(source, target, {
                id: edgeId,
                size: 6,
                color: "#1e88e5",
                type: "arrow",
                zIndex: 2,
                highlighted: true,
                arrowSize: 20,
                isHotSawEdge: true,
              });
            }
          }
        });

        // Set of nodes to highlight
        const nodesToHighlight = new Set([
          "Industry Type",
          "predictiveMaintenance",
          "performaceMonitoring",
          "hotSaw",
          "Drive Train Complexity",
          "Operational Complexity",
          "Fault Types",
          "Users",
          "center",
          "heavy",
        ]);
        nodesToHighlight.forEach((nodeId) => {
          if (graph.hasNode(nodeId)) {
            const attrs = graph.getNodeAttributes(nodeId);
            attrs.highlighted = true;
            // attrs.size =
            //   defaultSizes[attrs.category] * 1.25 || defaultSizes.item * 1.25;
            attrs.forceLabel = true;

            // Apply highlight colors based on node type
            if (attrs.category === "center") {
              attrs.color = highlightColors.center;
            } else if (attrs.category === "category") {
              attrs.color = highlightColors.main;
            } else {
              attrs.color = highlightColors.leaf;
            }
          }
        });

        setNodeInfo(graph.getNodeAttributes(node));
        setSelectedNode(node);
        if (ComponentMap[node]) {
          setSelectedComponent(React.createElement(ComponentMap[node]));
        }

        graph.forEachEdge((edge) => {
          graph.setEdgeAttribute(
            edge,
            "hidden",
            !graph.getEdgeAttribute(edge, "highlighted")
          );
        });

        rendererRef.current.refresh();
        return;
      } else {
        // For other nodes, remove any custom edges created for hotsaw
        graph.forEachEdge((edge) => {
          if (edge.startsWith("hotsaw-custom-")) {
            graph.dropEdge(edge);
          }
        });

        cleanupHotsawVisualization(graph);

        const nodeAttributes = graph.getNodeAttributes(node);
        setNodeInfo(nodeAttributes);
        setSelectedNode(node);

        // Handle component rendering
        if (ComponentMap[node]) {
          try {
            const Component = ComponentMap[node];
            setSelectedComponent(<Component />);
          } catch (error) {
            console.error("Error rendering component:", error);
            setSelectedComponent(null);
          }
        } else {
          setSelectedComponent(null);
        }

        // Handle node category-specific highlighting
        if (nodeAttributes.category === "category") {
          const children = graph.neighbors(node);
          children.forEach((child) => {
            const childAttrs = graph.getNodeAttributes(child);
            childAttrs.highlighted = true;
            childAttrs.forceLabel = true;
            childAttrs.color = highlightColors.leaf;

            const categoryToChildEdge =
              graph.edge(node, child) || graph.edge(child, node);
            if (categoryToChildEdge) {
              // Exclude 'hotSaw' custom edges
              if (
                !graph.getEdgeAttribute(categoryToChildEdge, "isHotSawEdge")
              ) {
                graph.setEdgeAttribute(categoryToChildEdge, "color", "#FF5757");
                graph.setEdgeAttribute(categoryToChildEdge, "size", 3);
                graph.setEdgeAttribute(categoryToChildEdge, "zIndex", 2);
                graph.setEdgeAttribute(
                  categoryToChildEdge,
                  "highlighted",
                  true
                );
              }
            }
          });
        } else {
          // Item node click handling
          const connectedNodes = graph
            .neighbors(node)
            .filter((n) => graph.getNodeAttributes(n).category === "item");

          let clickedNodePath = getPathToRoot(node);

          connectedNodes.forEach((connectedNode) => {
            const attrs = graph.getNodeAttributes(connectedNode);
            attrs.highlighted = true;
            // attrs.size =
            //   defaultSizes[attrs.category] * 1.25 || defaultSizes.item * 1.25;
            attrs.color =
              attrs.category === "category"
                ? highlightColors.main
                : highlightColors.leaf;
            attrs.forceLabel = true;

            // Highlight the edges connecting to this node
            const edge =
              graph.edge(node, connectedNode) ||
              graph.edge(connectedNode, node);
            if (edge) {
              graph.setEdgeAttribute(edge, "color", "#FF5757");
              graph.setEdgeAttribute(edge, "size", 3);
              graph.setEdgeAttribute(edge, "zIndex", 2);
              graph.setEdgeAttribute(edge, "highlighted", true);
            }
          });

          // Highlight clicked node's path and their edges
          clickedNodePath.forEach((pathNode) => {
            const attrs = graph.getNodeAttributes(pathNode);
            attrs.highlighted = true;
            // attrs.size =
            //   defaultSizes[attrs.category] * 1.25 || defaultSizes.item * 1.25;
            attrs.forceLabel = true;
            // Ensure center node gets highlighted color
            if (pathNode === "center") {
              attrs.color = highlightColors.center;
            } else {
              attrs.color =
                attrs.category === "category"
                  ? highlightColors.main
                  : highlightColors.leaf;
            }
          });

          // Handle edges along the path
          for (let i = 0; i < clickedNodePath.length - 1; i++) {
            const currentNode = clickedNodePath[i];
            const nextNode = clickedNodePath[i + 1];

            // Highlight the edge between current and next node in the path
            const pathEdge =
              graph.edge(currentNode, nextNode) ||
              graph.edge(nextNode, currentNode);
            if (pathEdge) {
              graph.setEdgeAttribute(pathEdge, "color", "#FF5757");
              graph.setEdgeAttribute(pathEdge, "size", 3);
              graph.setEdgeAttribute(pathEdge, "zIndex", 2);
              graph.setEdgeAttribute(pathEdge, "highlighted", true);
            }
          }
        }

        // Highlight clicked node with special color
        nodeAttributes.highlighted = true;
        nodeAttributes.size =
          defaultSizes[nodeAttributes.category] * 1.25 ||
          defaultSizes.item * 1.25;
        nodeAttributes.color =
          nodeAttributes.category === "center"
            ? highlightColors.center
            : nodeAttributes.category === "category"
            ? highlightColors.main
            : highlightColors.leaf;
        nodeAttributes.forceLabel = true;
      }

      // Hide non-highlighted edges
      graph.forEachEdge((edge) => {
        graph.setEdgeAttribute(
          edge,
          "hidden",
          !graph.getEdgeAttribute(edge, "highlighted")
        );
        if (edge.startsWith("hotsaw-custom-")) {
          graph.setEdgeAttribute(edge, "hidden", true);
        }
      });

      rendererRef.current.refresh();
    });

    // Stage click handler
    // Stage click handler
    rendererRef.current.on("clickStage", () => {
      setNodeInfo(null);
      setSelectedComponent(null);
      setSelectedNode(null);

      cleanupHotsawVisualization(graph);

      graph.forEachNode((n) => {
        const attrs = graph.getNodeAttributes(n);
        attrs.highlighted = false;
        attrs.size = defaultSizes[attrs.category] || defaultSizes.item;
        attrs.forceLabel = false;

        // Special handling for center node
        if (attrs.category === "center") {
          attrs.color = "#4e79a7"; // Original center node color
        }
        // Special handling for Users category and its children
        else if (attrs.label === "Users" || attrs.parentCategory === "Users") {
          attrs.color =
            attrs.category === "category"
              ? categoryColorSchemes["Users"].main
              : categoryColorSchemes["Users"].nodes;
        }
        // Regular category nodes
        else if (attrs.category === "category") {
          attrs.color = categoryColorSchemes[attrs.label]?.main || "#455a64";
        }
        // Regular child nodes
        else {
          attrs.color =
            categoryColorSchemes[attrs.parentCategory]?.nodes || "#78909c";
        }
      });

      graph.forEachEdge((e) => {
        graph.setEdgeAttribute(e, "color", "rgba(144, 164, 174, 0.8)");
        graph.setEdgeAttribute(e, "size", 2);
        graph.setEdgeAttribute(e, "zIndex", 0);
        graph.setEdgeAttribute(e, "highlighted", false);

        graph.setEdgeAttribute(e, "hidden", false);
      });

      rendererRef.current.refresh();
    });

    return () => {
      if (rendererRef.current) {
        rendererRef.current.kill();
      }
    };
  }, [showLabels]);

  // Rest of the component remains the same...
  const zoomIn = () => {
    const camera = rendererRef.current.getCamera();
    camera.animatedZoom({ duration: 600 });
  };

  const zoomOut = () => {
    const camera = rendererRef.current.getCamera();
    camera.animatedUnzoom({ duration: 600 });
  };

  const resetView = () => {
    const camera = rendererRef.current.getCamera();
    camera.animatedReset({ duration: 600 });
  };

  const fitView = () => {
    rendererRef.current
      .getCamera()
      .animate({ x: 0.5, y: 0.5, ratio: 1.2 }, { duration: 600 });
  };

  return (
    <div className="mx-4 lg:mx-8 pb-5">
      <div className="flex flex-col w-full h-screen md:h-[85vh] bg-white rounded-lg shadow-lg">
        <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
          <div className="relative w-full lg:w-2/3 h-[50vh] lg:h-full">
            {/* Search and Controls overlay */}
            <div className="absolute top-4 left-4 z-10 flex flex-col space-y-4">
              <div className="flex items-center space-x-2 bg-white/90 p-2 rounded-lg shadow-sm">
                <div className="flex space-x-1">
                  <Button variant="outline" size="icon" onClick={zoomIn}>
                    <ZoomIn className="h-4 w-4" />
                  </Button>
                  <Button variant="outline" size="icon" onClick={zoomOut}>
                    <ZoomOut className="h-4 w-4" />
                  </Button>
                  <Button variant="outline" size="icon" onClick={fitView}>
                    <Maximize2 className="h-4 w-4" />
                  </Button>
                  <Button variant="outline" size="icon" onClick={resetView}>
                    <Home className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              {searchTerm && matchingNodes.length > 0 && (
                <Card className="w-48">
                  <CardContent className="p-2">
                    {matchingNodes.map(({ node, label }) => (
                      <button
                        key={node}
                        className="w-full text-left px-2 py-1 hover:bg-gray-100 rounded"
                        onClick={() => {
                          setSearchTerm("");
                          if (graphRef.current && rendererRef.current) {
                            const nodeAttributes =
                              graphRef.current.getNodeAttributes(node);
                            rendererRef.current.getCamera().animate(
                              {
                                x: nodeAttributes.x,
                                y: nodeAttributes.y,
                                ratio: 0.5,
                              },
                              { duration: 600 }
                            );
                          }
                        }}
                      >
                        {label}
                      </button>
                    ))}
                  </CardContent>
                </Card>
              )}
            </div>

            <div
              ref={containerRef}
              className="absolute inset-0 rounded-lg shadow-inner m-4"
            />

            {isHelpVisible && (
              <div className="absolute top-4 right-4 max-w-xs bg-white p-4 rounded-lg shadow-lg border border-blue-100">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <Info className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium text-gray-900">
                      How to Navigate
                    </h3>
                  </div>
                  <button
                    onClick={() => setIsHelpVisible(false)}
                    className="text-primary hover:text-primary"
                  >
                    ✕
                  </button>
                </div>
                <ul className="text-sm text-primary space-y-2">
                  <li>• Click nodes to explore different categories</li>
                  <li>• Hover over nodes to see connections</li>
                  <li>• Use zoom buttons to zoom in/out</li>
                  <li>• Drag to pan around</li>
                </ul>
              </div>
            )}

            {nodeInfo && !selectedComponent && (
              <Card className="absolute bottom-8 left-8 max-w-sm">
                <CardContent className="p-4">
                  <h3 className="font-semibold text-gray-900 mb-1">
                    {nodeInfo.label}
                  </h3>
                  <p className="text-sm text-gray-600">
                    Type: {nodeInfo.category}
                  </p>
                </CardContent>
              </Card>
            )}
          </div>

          <div className="w-full lg:w-1/3 lg:h-full max-h-[50vh] lg:max-h-none bg-white border-l border-t border-gray-200 overflow-y-auto">
            {selectedComponent ? (
              <div className="p-6">{selectedComponent}</div>
            ) : (
              <div className="h-full flex items-center justify-center p-6">
                <div className="text-center">
                  <p className="text-gray-500 mb-2">
                    Select a node to view details
                  </p>
                  <p className="text-sm text-gray-400">
                    Click on any node in the network for more information
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkGraph;
