import React from "react";
import { ChevronRight, Zap, Target, Brain, Shield } from "lucide-react";

const HighlightedText = ({ children }) => (
  <span className="relative">
    {children}
    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-secondary"></span>
  </span>
);

const TruthOverInsight = ({ htmlPageUrl }) => {
  return (
    <div className="p-4 sm:p-6 md:p-10 max-w-7xl mx-auto">
      <div className="flex flex-col md:flex-row gap-4 sm:gap-6 md:gap-8">
        {/* Left Content */}
        <div className="flex-1 md:w-2/5 flex items-center">
          <div className="w-full pb-10">
            <div className="flex items-center mb-4">
              <Target className="w-5 h-5 sm:w-6 sm:h-6 text-secondary mr-2 sm:mr-3" />
              <h3 className="text-base sm:text-lg font-semibold text-primary">
                Truth Over Insight
              </h3>
            </div>
            <div className="flex gap-3 sm:gap-4">
              <div className="w-1 bg-secondary self-stretch"></div>
              <div className="space-y-4 sm:space-y-6">
                <p className="text-primary text-lg leading-relaxed">
                  You don't need insight—you need truth! Truth is what's
                  actually happening in the entire system. Only{" "}
                  <HighlightedText>high-fidelity</HighlightedText> technology
                  can provide this.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Right Content */}
        <div className="flex-1 md:w-3/5">
          <div className="relative aspect-video w-full min-h-[400px] overflow-auto md:overflow-hidden">
            <div className="absolute inset-0 overflow-auto md:overflow-hidden">
              <iframe
                src={htmlPageUrl}
                title="Embedded content"
                className="w-full h-full"
                style={{ minWidth: "100%", minHeight: "100%" }}
                frameBorder="0"
                loading="lazy"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RoboustReliability = ({ htmlPageUrl }) => {
  return (
    <div className="bg-secondary-background p-4 sm:p-6 md:p-10  max-w-7xl mx-auto">
      <div className="flex flex-col-reverse md:flex-row gap-4 sm:gap-6 md:gap-8">
        {/* Left Content */}
        <div className="flex-1 md:w-3/5">
          <div className="relative aspect-video w-full">
            <div className="absolute inset-0 overflow-auto md:min-h-[500px] md:overflow-hidden">
              <iframe
                src={htmlPageUrl}
                title="Embedded content"
                className="w-full h-full"
                style={{ minWidth: "100%", minHeight: "600px" }}
                frameBorder="0"
                loading="lazy"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        {/* Right Content */}
        <div className="flex-1 md:w-2/5 flex items-center pt-10">
          <div className="w-full">
            <div className="flex items-center mb-4">
              <Shield className="w-6 h-6 text-secondary mr-3" />
              <h3 className="text-lg font-semibold text-primary">
                Robust & Reliable Technology
              </h3>
            </div>
            <div className="flex gap-4">
              <div className="w-1 bg-secondary self-stretch"></div>
              <div className="space-y-6">
                <p className="text-primary text-lg leading-relaxed">
                  You want the truth consistently, without compromising safety
                  or increasing operational expenses. Only{" "}
                  <HighlightedText>robust and reliable</HighlightedText>{" "}
                  technology can deliver this.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccurateIntelligent = ({ approachImage }) => {
  return (
    <div className="p-10 max-w-7xl mx-auto">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Left Content */}
        <div className="flex-1 md:w-2/5 flex items-center">
          <div className="w-full">
            <div className="flex items-center mb-4">
              <Brain className="w-6 h-6 text-secondary mr-3" />
              <h3 className="text-lg font-semibold text-primary">
                Accurate & Intelligent
              </h3>
            </div>
            <div className="flex gap-4">
              <div className="w-1 bg-secondary self-stretch"></div>
              <div className="space-y-6">
                <p className="text-primary text-lg leading-relaxed">
                  With little to no training, any member of your team can
                  precisely understand the complex mechanisms of equipment and
                  machinery in real-time. Only{" "}
                  <HighlightedText>intelligent systems</HighlightedText> can
                  achieve this.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Right Content */}
        <div className="flex-1 md:w-3/5">
          <img
            src={approachImage}
            alt="Electrical Signature Analysis"
            className="w-full h-auto object-contain max-h-[60vh]"
          />
        </div>
      </div>
    </div>
  );
};

export { TruthOverInsight, RoboustReliability, AccurateIntelligent };
