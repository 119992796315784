import solution_development_1 from "../assets/timeline/solution_development/1.png";
import solution_development_4 from "../assets/timeline/solution_development/machine_selected_for_development.jpg";
import solution_development_5 from "../assets/timeline/solution_development/mcc.jpg";

import offical_launch_1 from "../assets/timeline/2018_2019_official_launch/mintoai_with_iHz.jpg";
import offical_launch_2 from "../assets/timeline/2018_2019_official_launch/mobileapp.jpg";

//first POC block
import first_poc_iHzs from "../assets/timeline/first_poc/time_line_first_poc_1.jpg";
import first_poc_2 from "../assets/timeline/first_poc/time_line_first_poc_2.jpg";
import first_poc_3 from "../assets/timeline/first_poc/time_line_first_poc_3.jpg";
import first_poc_4 from "../assets/timeline/first_poc/time_line_first_poc_4.png";

//first project block
import first_project_1 from "../assets/timeline/first_project/time_line_first_project_1.jpg";
import first_project_2 from "../assets/timeline/first_project/time_line_first_project_2.jpg";

import sail from "../assets/Sail-timeLine.jpg";
import solutioning from "../assets/solutioning.png";
// CK birla block

import ckbirla from "../assets/timeline/ckbirla/time_line_ckbirla_1.jpg";

//Official launch

import offical_1 from "../assets/timeline/Offical_launch/1.jpg";
import offical_2 from "../assets/timeline/Offical_launch/2.jpg";
import offical_3 from "../assets/timeline/Offical_launch/3.png";

//iHz v2.0
import iHz_v2_1 from "../assets/timeline/thermal_insulation/time_line_thermal_insulation_1.jpg";
import iHz_v2_2 from "../assets/timeline/thermal_insulation/time_line_thermal_insulation_2.jpg";
import iHz_v2_3 from "../assets/timeline/thermal_insulation/time_line_thermal_insulation_3.jpg";

//expansion
import expansion_1 from "../assets/timeline/82patil/time_line_82patil_1.jpg";
import expansion_2 from "../assets/timeline/82patil/time_line_82patil_2.png";
import expansion_3 from "../assets/timeline/82patil/time_line_82patil_3.jpg";

//HIL
import hil_1 from "../assets/timeline/hil/time_line_hil_1.jpg";
//SAIL-Bokaro
import SAIL_Bokaro_1 from "../assets/timeline/SAIL-Bokaro/1.jpg";
import SAIL_Bokaro_2 from "../assets/timeline/SAIL-Bokaro/2.jpg";

//TATASTEEL
import TATASTEEL_1 from "../assets/timeline/TATASTEEL/1.jpg";
import TATASTEEL_2 from "../assets/timeline/TATASTEEL/3.png";
import TATASTEEL_3 from "../assets/timeline/TATASTEEL/Testimonial.jpg";

//Durgapur
import durgapur from "../assets/timeline/DSP/time_line_DSP_1.jpg";

// SAIL-Bhilai-workorder
import sail_bhilai_workorder from "../assets/timeline/SAIL-Bhilai-workorder/1.jpg";

//spiderAI
import spiderAI_1 from "../assets/timeline/spiderAI_v1/sense__ai.png";
import spiderAI_2 from "../assets/timeline/spiderAI_v1/time_line_spiderai_1.jpg";
import spiderAI_3 from "../assets/timeline/spiderAI_v1/time_line_spiderai_spiderAI-iHz.webp";

//reorient
import reorient_1 from "../assets/timeline/reorient/time_line_Team-orientation-genAI.jpg";
import reorient_2 from "../assets/timeline/LLM-hype/time_line_LLM_hype_1.png";
import reorient_SAIL_Bhilai_1 from "../assets/timeline/SAIL-Bhilai-Project/time_line_sail_plant.jpeg";
import reorient_SAIL_Bhilai_2 from "../assets/timeline/SAIL-Bhilai-Project/time_line_sail_bhilai_2.jpg";
import reorient_SAIL_Bhilai_3 from "../assets/timeline/SAIL-Bhilai-Project/time_line_sail_3.jpg";

import website_dev_1 from "../assets/timeline/website/time_line_new_website.png";
import website_dev_2 from "../assets/timeline/website/time_line_website_3.jpg";

const timelineData = [
  {
    title: "November 2017: Genesis & Market Research",
    content:
      "Drawing from diverse experiences in manufacturing, data science, and engineering, we began exploring solutions for industrial challenges. We visited nearly 10 manufacturing plants across various companies to gain insights into their operations and challenges.",
    images: [
      {
        src: "https://minto-webapp-v3.s3.us-east-2.amazonaws.com/time_line/time_line_plant_visit_1.jpg",
        alt: "HMT Plant - Learning about Motor Control Cabinet panel",
      },
      {
        src: "https://minto-webapp-v3.s3.us-east-2.amazonaws.com/time_line/time_line_plant_visit_2.jpg",
        alt: "Hydraulic Pump, Railway Sleepers Plant - Patil Group",
      },
      {
        src: "https://minto-webapp-v3.s3.us-east-2.amazonaws.com/time_line/time_line_plant_visit_3.webp",
        alt: "Railways Sleepers Plant - Patil Group",
      },
      {
        src: "https://minto-webapp-v3.s3.us-east-2.amazonaws.com/time_line/time_line_plant_visit_4.jpg",
        alt: "Machine operating panel, HMT plant -Hyderabad",
      },
    ],
  },

  {
    title: "April 2018: Solution Development",
    content:
      "We identified Motor Current Signature Analysis (MCSA) as a groundbreaking approach to diagnose both electrical and mechanical faults in rotating machines. We developed an IoT device that can be installed inside the motor control cabinet—a central location where power is supplied to all machines in the plant.",
    images: [
      {
        src: solution_development_1,
        alt: "MCSA solution development",
      },

      {
        src: solution_development_4,
        alt: "Machine selected for development",
      },
      {
        src: solution_development_5,
        alt: "iHz in MCC panel",
      },
    ],
  },
  {
    title: "September 2018 - January 2019: Official Launch",
    content:
      "Registered minto softech private limited and launched our website: www.minto.ai. Developed the first version of our IoT device, iHz™.",
    images: [
      {
        src: offical_launch_1,
        alt: "minto.ai with iHz",
      },
      {
        src: offical_launch_2,
        alt: "Mobile App for monitoring the solution",
      },
    ],
    link: "http://www.minto.ai",
  },
  {
    title: "February - April 2019: First PoC and Success",
    content:
      "Won and successfully completed our first Proof of Concept with the Patil Group, implementing iHz™ for 5 machines.",
    images: [
      {
        src: first_poc_iHzs,
        alt: "iHz™ devices for first PoC",
      },
      {
        src: first_poc_2,
        alt: "iHz™ with package case",
      },
      {
        src: first_poc_3,
        alt: "machines in the mobile application",
      },
      {
        src: first_poc_4,
        alt: "machine in the mobile application",
      },
    ],
  },
  {
    title: "May - June 2019: First Major Project",
    content:
      "Secured a project to monitor 40 machines at Patil Group's Kallakal plant. Completed installation and launched real-time equipment health monitoring dashboard on AWS connected to iHz™.",
    images: [
      { src: first_project_1, alt: "iHz™ installation in Kallakal Plant" },
      { src: first_project_2, alt: "Team after completion of installation" },
    ],
  },
  {
    title: "November 2019: Recognition",
    content:
      "Recognized as one of the Top 2 Innovative startups in India by BIRLASOFT, CK Birla group.",
    images: [
      {
        src: ckbirla,
        alt: "Receiving award from CK Birla Group",
      },
    ],
    link: "https://www.birlasoft.com/company/news/2019/birlasoft-announces-the-winner-of-xccelerate4startups-contest-season-1",
  },
  {
    title: "July 2020: spidersense™ Launch",
    content:
      "Released spidersense™, our web-based real-time equipment health monitoring platform.",
    images: [
      { src: offical_1, alt: "spidersense launch in minto.ai website" },
      { src: offical_2, alt: "spidersense™ analyse page" },
      { src: offical_3, alt: "spidersense™ diagnose page" },
    ],
  },
  {
    title: "October 2020: iHz™ v2.0 Release",
    content:
      "Released an improved version of iHz™ with enhanced thermal insulation and new power module.",
    images: [
      { src: iHz_v2_1, alt: "iHz™ v2.0 case" },
      { src: iHz_v2_2, alt: "iHz™ v2.0 with power module in MCC panel" },
      { src: iHz_v2_3, alt: "iHz™ v2.0 spec" },
    ],
  },
  {
    title: "July 2021: Expansion",
    content:
      "Received purchase order for 82 machines in Patil Group's Bokaro Plant, marking significant growth.",
    images: [
      { src: expansion_1, alt: "82 Nos of iHz™ devices for Bokaro Plant" },
      { src: expansion_2, alt: "Bokaro Plant machine analyse page dashboard" },
      { src: expansion_3, alt: "Our team in Patil Bokaro" },
    ],
  },
  {
    title: "September 2021 - February 2023: Major Clients and Successes",
    content:
      "Won contracts with HIL-Plant Hyderabad and initiated PoC with SAIL Bokaro. Started installations at TATA STEEL Jamshedpur and SAIL Durgapur. Received testimonial from TATA STEEL for preventing a major breakdown.",
    images: [
      { src: hil_1, alt: "HIL- Timmapur" },
      { src: TATASTEEL_1, alt: "Installation at TATA Steel plant" },
      { src: TATASTEEL_2, alt: "Fault Identified in TATA Steel Plant" },
      { src: TATASTEEL_3, alt: "Testimonial from TATA Steel" },
      { src: SAIL_Bokaro_1, alt: "Installation at SAIL Bokaro Plant" },
      { src: SAIL_Bokaro_2, alt: "SAIL Bokaro Plant" },
      { src: durgapur, alt: "Installation at SAIL Durgapur Plant" },
    ],
  },
  {
    title: "June 2023: SAIL-Bhilai Contract",
    content:
      "Received work order from SAIL-Bhilai to monitor 50 machines for three years.",
    images: [
      { src: sail, alt: "Sail Bhilai plant" },
      { src: solutioning, alt: "Equipment at the Sail" },
    ],
  },
  {
    title: "September 2023 - Present: spiderAI™ Development",
    content:
      "Began integrating GenerativeAI technology, developing spiderAI™ as a next-generation industrial co-intelligence platform.",
    images: [
      { src: spiderAI_1, alt: "From spidersense™ to spiderAI™" },
      { src: spiderAI_2, alt: "spiderAI™ intial development" },
      { src: spiderAI_3, alt: "spiderAI™ intial chat window" },
      {
        src: reorient_2,
        alt: "Orienting ourselfs on LLMs and its applications",
      },
    ],
  },
  {
    title: "August - September 2024: minto.ai™ 2.0 Launch",
    content:
      "Kickstarted the SAIL-Bhilai project, marking the beginning of minto.ai™ 2.0 with the integration of spiderAI™ and iHz™.",
    images: [
      { src: website_dev_1, alt: "New website development" },
      { src: website_dev_2, alt: "New website development" },
      { src: reorient_SAIL_Bhilai_1, alt: "SAIL-Bhilai" },
      { src: reorient_SAIL_Bhilai_2, alt: "iHz in SAIL-Bhilai" },
      { src: reorient_SAIL_Bhilai_3, alt: "iHz in SAIL-Bhilai" },
    ],
  },
];

export default timelineData;
