import React from "react";
import { useState, useEffect } from "react";
import HeroImg from "../assets/HeroImgAboutus.jpg";
import CoffeeGrid from "../components/CoffeeGrid";
import TestimonialCarousel from "../components/TestimonialCarousle";
import team from "../assets/team/team.jpeg";
import FloatingHeroSection from "../components/FloatingHeroSection";
import ScrollVelocity from "../components/ui/ScrollVelocity";
import {
  Coffee,
  Book,
  Sprout,
  ArrowRight,
  User,
  Binoculars,
  Mail,
} from "lucide-react";
import koushik from "../assets/Individual/koushik.jpg";
import ravi from "../assets/Individual/RaviTeja.jpg";
import bhargav from "../assets/Individual/Bhargav.jpeg";
import adhitya from "../assets/Individual/adhitya.jpg";

import careers_internal_section from "../assets/careers_internal_section.jpg";

import ImpactPlayers from "../components/careers/impact-makers";

import davinci1 from "../assets/adore/davinci-ss.png";
import davinci2 from "../assets/adore/davinci-work.png";
import davinci3 from "../assets/adore/Davincie-1.webp";
import newton1 from "../assets/adore/newton.png";
import newton2 from "../assets/adore/Newton-1.webp";
import newton3 from "../assets/adore/Newton-2.webp";
import newton4 from "../assets/adore/Newton-3.webp";
import sherlock1 from "../assets/adore/SherLock-1.webp";
import sherlock2 from "../assets/adore/SherLock-2.webp";
import sherlock3 from "../assets/adore/SherLock-3.webp";
import ImpactStatement from "../components/ImapctStatement";
import MessageSection from "../components/MessageSection";
import Breadcrumb from "../components/BreadCrumb";

const ContactInfoItem = ({ title, content, Icon }) => {
  return (
    <div className="text-center sm:text-center">
      <div className="flex flex-col items-center sm:items-center mb-2">
        <div className="bg-gray-100 p-3 rounded-full">
          <Icon />
        </div>
        <h3 className="mt-4 text-lg font-semibold text-primary ">{title}</h3>
      </div>
      <p className="text-primary">{content}</p>
    </div>
  );
};

const images = [
  {
    title: "Editorially",
    thumbnail: newton1,
  },
  {
    title: "Editorially 2",
    thumbnail: sherlock2,
  },

  {
    title: "Moonbeam 2",
    thumbnail: newton3,
  },
  {
    title: "Moonbeam",
    thumbnail: davinci1,
  },
  {
    title: "Cursor",
    thumbnail: davinci2,
  },
  {
    title: "Rogue",
    thumbnail: davinci3,
  },
  {
    title: "Cursor 2",
    thumbnail: newton4,
  },
  {
    title: "Rogue 2",
    thumbnail: sherlock1,
  },

  {
    title: "Editrix AI 2",
    thumbnail: sherlock3,
  },
  {
    title: "Editrix AI",
    thumbnail: newton2,
  },
];
const velocity = [3, -3];

const slides = [
  {
    image: ravi,
    title: "An Unquenchable Curiosity",
    component: (
      <div className="space-y-4 text-sm text-primary">
        <p className="leading-relaxed">
          An unquenchable curiosity has been my lifelong companion. In
          childhood, my interests spanned sports—especially cricket—essay
          writing, extemporaneous speaking, reading science, doing mathematics,
          and engaging with sociology and history. These passions have evolved
          into travel, pursuing new projects, writing, coffee appreciation,
          books, design, applied history, risk theory, problem-solving,
          innovation, and entrepreneurship—always diverse and ever-evolving.
        </p>

        <p className="leading-relaxed">
          I recall an incident when, barely tall enough to reach the bicycle
          pedals, I ventured alone to nearby villages, prompting a frantic
          search by my parents. Today, that same adventurous spirit leads me to
          explore new horizons as a traveler, savor the complexities of coffee,
          delve into books of various genres to understand the world, and
          immerse myself in entrepreneurship and technology building. Science
          and technology amaze me—not only appearing magical but also serving as
          forces to create a better world when applied at scale. This relentless
          drive for exploration, curiosity, and discovery led me to create
          minto.ai and continues to inspire my vision for an exciting future.
          Like a compass seeking true north, my curiosity consistently points me
          toward the adventurous dimensions of life and all that I currently
          pursue.
        </p>
      </div>
    ), // Your custom component here
    name: "Raviteja",
    location: "New York",
  },
  {
    image: bhargav,
    title: "Pocket Rocket!",
    component: (
      <div className="space-y-4 text-sm text-primary">
        <p className="leading-relaxed">
          Cricket was my first love—and it is forever. The day I first rode an
          Austrian-made KTM Duke 390, I just sat there stunned. Never thought a
          machine could move like that - hitting 12,000 RPM and stopping dead in
          its tracks. As a kid, watching this piece of engineering marvel, I
          just fell in love. That began my fascination with anything involving a
          clutch, gears, and internal combustion engines.
        </p>

        <p className="leading-relaxed">
          At minto.ai, every day is a new discovery about machines - each
          unique, each serving differently, each telling its own story. Watching
          them work tirelessly in varied conditions has taught me profound
          respect for these silent warriors. Between cricket matches and machine
          learning experiments, I collect moments through my lens, care for my
          plants, and find myself shadow batting with whatever's at hand.
        </p>
        <p className="leading-relaxed">
          From those first humans who shaped metal and wheels to the engineers
          who fine-tuned ABS, I'm just another curious soul in that line. Like
          the machines we work with, AI and language models are already
          incredibly capable - they're just limited by how we communicate with
          them. Our prompts can't always capture what humans truly feel or mean.
          That's what drives me - exploring these fascinating engineering
          marvels and hoping to leave my mark by adding just one more day to
          their life. Everything after that is a bonus.
        </p>
        <p className="leading-relaxed">
          What a time to be alive - watching the physical and digital worlds of
          machines finally converge. Game UP!
        </p>
      </div>
    ), // Your custom component here
    name: "Bhargav",
    location: "New York",
  },
  {
    image: koushik,
    title: "Crafting Me....",
    component: (
      <div className="space-y-4 text-sm text-primary">
        <p className="leading-relaxed">
          Whenever I hold that metal camera, the feel of its weight in my hands,
          grounding me, trying to see the world through the lens, chasing
          adventure on mountain trails and capturing life's unscripted moments .
          I savor every moment of them. This is me, always excited about trying
          new things whether its technology or anything , I want to experiment,
          and discover unique experience in it.
        </p>

        <p className="leading-relaxed">
          This is the mind set, when i joined mino.ai, learnt the new experience
          that the magic truly happens when you dive deep. Understanding the
          things deeply, its just not about doing surface-level experiments ——
          it's about peeling back layers, much like how a photographer must
          understand light, composition, and timing to capture that perfect
          moment.
        </p>
        <p className="leading-relaxed">
          This is the beginning of my journey, There are so many trails yet to
          explore, so many moments to capture. I'm not just collecting
          experiences , I ’m crafting my story
        </p>
      </div>
    ), // Your custom component here
    name: "Koushik",
    location: "",
  },
  {
    image: adhitya,
    title: "あきらめないで",
    component: (
      <div className="space-y-4 text-sm text-primary">
        <p className="leading-relaxed">
          I weave between technology, art, and creativity—whether I'm
          experimenting with cutting-edge tech tools, perfecting presentation
          aesthetics, or channeling energy into intense gym sessions. This drive
          for excellence reminds me of Vegeta's wisdom: 'Power comes in response
          to a need, not a desire.' It's a philosophy I carry into every aspect
          of my work and personal growth.
        </p>
        <p className="leading-relaxed">
          My approach is methodical yet dynamic, seeking perfect balance between
          form and function. The gym isn't just about physical training; it's my
          dojo for building mental resilience. What sets me apart is blending
          diverse interests—from optimizing workflows to reimagining information
          presentation—transforming challenges into opportunities for
          innovation.
        </p>
        <p className="leading-relaxed">
          Every day presents new opportunities to push boundaries, whether in
          technology, fitness, or creative expression. My story is one of
          constant growth, where each skill mastered is just the beginning of
          another exciting chapter.
        </p>
      </div>
    ), // Your custom component here
    name: "Adhitya",
    location: "New York",
  },
];

export default function Careers() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className="relative h-[80vh] overflow-hidden flex flex-col justify-center items-center">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${HeroImg})`,
            transform: `translateY(${offset * 0.5}px)`,
          }}
        />
        <div className="absolute inset-0  bg-gradient-to-t from-primary via-primary/50 to-primary/10" />
        <div className="relative z-10  max-w-3xl lg:max-w-4xl text-center">
          <p className="text-3xl lg:text-4xl xl:text-5xl font-bold text-white mb-6 leading-relaxed">
            Join us in revolutionizing the industrial world through AI. We're
            always looking for talented individuals to help shape the future of
            machine intelligence
          </p>
        </div>
      </div>

      <Breadcrumb />

      <ImpactPlayers />
      <div className="bg-white">
        <div className="py-6">
          {/* Header Section */}
          <h1 className="text-3xl font-bold text-center text-secondary mb-8">
            Our Team's Perspective
          </h1>
        </div>

        <MessageSection />
      </div>

      <div className="p-10 max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <img
              src={careers_internal_section}
              alt="Our approach"
              className="w-full h-[500px] object-cover rounded-lg shadow-lg"
            />
          </div>
          <div className="flex-1 flex items-center">
            {" "}
            {/* Added flex and items-center */}
            <div className="w-full">
              <div className="flex gap-4">
                <div className="space-y-6">
                  <p className="text-primary text-xl leading-loose">
                    We are a tribe of artisans, designers, hackers and warriors
                    building intelligent systems to make the world run on time
                    efficiently.
                  </p>
                  <p className="text-primary text-xl leading-loose">
                    We build hardware that captures the truth efficiently, we
                    power this hardware with software & machine learning to
                    empower everyone understand how the world is running
                    mechanically, we build AI that helps everyone to ensure that
                    it runs on time efficiently.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  max-w-8xl mx-auto p-4 md:p-10 lg:px-20 py-16">
        <div className="w-full mx-auto px-6">
          <div className="space-y-4">
            {/* Header Section */}
            <h1 className="text-3xl font-bold text-center text-secondary mb-8">
              Our Team's Percpective
            </h1>

            <p className="text-lg text-primary text-center">
              We're more than just a team - we're a community of passionate
              individuals <br /> bringing their unique perspectives to work
              every day.
            </p>

            <div className="flex gap-3 pt-4 justify-center">
              <div className="inline-flex items-center text-center">
                <span className="text-secondary text-sm font-medium mr-1">
                  ⬢
                </span>
                <span className="text-sm text-primary font-medium">
                  STORIES
                </span>
              </div>
              <div className="inline-flex items-center">
                <span className="text-secondary text-sm font-medium mr-1">
                  ⬢
                </span>
                <span className="text-sm text-primary font-medium">
                  HOBBIES
                </span>
              </div>
              <div className="inline-flex items-center">
                <span className="text-secondary text-sm font-medium mr-1">
                  ⬢
                </span>
                <span className="text-sm text-primary font-medium">
                  INTERESTS
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TestimonialCarousel slides={slides} />

      <div className="w-full bg-gradient-to-b from-white to-blue-50">
        <div className="max-w-4xl mx-auto px-6 py-20">
          {/* Main Content Container */}
          <div className="space-y-12">
            {/* Coffee Chat Section */}
            <div className="text-center space-y-6">
              <div className="inline-flex items-center justify-center space-x-2 bg-secondary-background text-primary px-4 py-2 rounded-full mb-4">
                <Coffee className="w-5 h-5" />
                <span className="text-sm font-medium">Let's Connect</span>
              </div>

              <h2 className="text-2xl md:text-3xl font-semibold text-primary">
                Are you interested to explore the opportunities at{" "}
                <span className="text-secondary">minto.ai</span>?
              </h2>

              <p className="text-lg text-primary max-w-2xl mx-auto">
                Connect with us, let's discuss over a coffee.
                <span className="block mt-2 text-primary">
                  BTW, we know the best coffee shops in town 😍
                </span>
                <div className="mt-8">
                  <p className="text-lg font-semibold text-primary flex items-center justify-center gap-2">
                    <ArrowRight className="w-5 h-5" />
                    Connect with Ravi, Founder directly
                  </p>
                  <a
                    href="mailto:ravi@minto.ai"
                    className="flex items-center justify-center gap-2 mt-2 text-primary hover:underline"
                  >
                    <Mail className="w-5 h-5" />
                    ravi@minto.ai
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-4xl mx-auto px-6 py-10">
          {/* Main Content Container */}
          <div className="space-y-12">
            {/* Coffee Chat Section */}
            <div className="text-center space-y-6">
              <div className="inline-flex items-center justify-center space-x-2 bg-secondary-background text-primary px-4 py-2 rounded-full mb-4">
                <Binoculars className="w-5 h-5" />
                <span className="text-sm font-medium">
                  Discover Our Favorites
                </span>
              </div>

              <h2 className="text-2xl md:text-3xl font-semibold text-primary">
                Inspiring Spaces & Reads
              </h2>
            </div>
          </div>
        </div>

        <CoffeeGrid />

        {/* <div className="text-center">
          <Link
            to="/opportunities"
            className="group inline-flex items-center justify-center space-x-3 bg-blue-900 text-white px-8 py-4 rounded-lg text-lg font-medium transition-all duration-200 hover:bg-blue-800"
          >
            <span>Explore current opportunities</span>
            <ArrowRight className="w-5 h-5 transition-transform duration-200 group-hover:translate-x-1" />
          </Link>
        </div> */}
      </div>

      {/* <div className="bg-white py-28">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-16 md:gap-8">
            <ContactInfoItem
              title="Drop a mail:"
              content="Share your resume at spiderai@minto.ai"
              Icon={MailLogo}
            />

            <ContactInfoItem
              title="Address:"
              content={
                <>
                  Plot no. 114, at sy no 66/2 Street No. 03, floor no. 04 ,Rai
                  Durgam,
                  <br /> Prashanth Hills Gachi Bowli, Nav Khalsa,
                  Serilingampally, <br /> Hyderabad, Telangana 500008
                </>
              }
              Icon={Address}
            />

            <ContactInfoItem
              title="Call us:"
              content={
                <>
                  Call us to speak to a member of our team.
                  <br />
                  +91 9502268935
                </>
              }
              Icon={Callus}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
