import React from "react";
import {
  Factory,
  Wrench,
  AlertTriangle,
  Lightbulb,
  MessageSquare,
  ExternalLink,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SailBokaro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
  };

  const sections = [
    {
      title: "Plant Summary",
      icon: <Factory className="w-5 h-5 text-primary" />,
      content: `This is a fully automated Hot Strip Mill section in the SAIL Bokaro Steel Plant which is running with a 4.5 MnTPA capacity, transforms slabs into high-quality strips. The primary purpose of a hot strip mill is to reheat thick steel slabs into thin sheets with varying thickness. This process begins with reheating the surface through walking beam Furnaces, then steel slab enters into the process of roughing where there are six separate rolling mill stands, here the thickness is reduced and the steel tends to spread width wise. The finishing phase has six separate finishing mills that are automated. Finally, the steel bar is rolled again in the finishing mills in order to reduce the thickness as per the customer’s requirements. There are Critical EOT cranes, equipped with dual-motor driven hoists, which handles these heavy materials throughout this process. These cranes operate under demanding conditions - varying loads, frequent starts and stops, and constant movement - while maintaining precise control for material handling.`,
    },
    {
      title: "Requirement",
      icon: <Wrench className="w-5 h-5 text-primary" />,
      content: "POC for a Predictive maintenance solution.",
    },
    {
      title: "Execution Challenges",
      icon: <AlertTriangle className="w-5 h-5 text-primary" />,
      content: `EOT crane monitoring presents unique complexities in the steel mill environment. The dual-motor drive system, where two motors work in tandem through coupling and gearbox arrangements to drive a single drum, requires sophisticated analysis to detect imbalances or developing issues. Traditional monitoring methods struggle with the dynamic nature of crane operations - constant load variations, rapid start-stop cycles, and the moving platform make conventional sensor placement nearly impossible. The high-temperature, dusty environment of the hot strip mill further complicates monitoring efforts.`,
    },
    {
      title: "Solution",
      icon: <Lightbulb className="w-5 h-5 text-primary" />,
      content: `Within just three days of iHz installation, we successfully identified critical issues - detecting both motor winding problems and gearbox anomalies. The system's ability to monitor dual-drive systems proved particularly valuable, enabling early detection of intermediate gearbox issues and motor stalling conditions, problems that would have been nearly impossible to catch with conventional monitoring approaches.`,
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* Main Content */}
      <div>
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="mb-8 last:mb-0 hover:bg-gray-50 rounded-lg transition-colors duration-200 p-4"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-primary">
                {section.title}
              </h2>
            </div>
            <div className="pl-14">
              <p className="text-primary leading-relaxed">{section.content}</p>
            </div>
          </div>
        ))}
        {/* Testimonial Section */}
        <div className="mt-8 bg-blue-50 rounded-lg p-6">
          <div className="flex items-center space-x-3 mb-4">
            <div className="bg-blue-100 p-3 rounded-lg">
              <MessageSquare className="w-5 h-5 text-primary" />
            </div>
            <h2 className="text-xl font-semibold text-primary">
              Client Testimonial
            </h2>
          </div>
          <div className="pl-14">
            <div className="w-full max-w-2xl mx-auto p-4 sm:p-6 bg-blue-50">
              <div className="space-y-4 sm:space-y-6">
                <div className="relative">
                  {/* Quote mark - responsive size */}
                  <div className="absolute -left-2 sm:-left-6 top-0 text-4xl sm:text-6xl text-blue-500 font-serif select-none">
                    "
                  </div>

                  {/* Quote text - responsive font size and padding */}
                  <p className="text-lg sm:text-xl md:text-2xl text-primary leading-relaxed pl-4 sm:pl-4">
                    After installing Minto Ai's system, we quickly identified an
                    issue in the intermediate gearbox. Their system has been
                    helpful in diagnosing the stalling motor and the gearbox
                    shaft problem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Case Study Link */}
        <div className="mt-8 border-t pt-6">
          <a
            href="#"
            className="inline-flex items-center space-x-2 text-secondary transition-colors duration-200"
          >
            <ExternalLink className="w-4 h-4" />
            <Link to="/usecase-health-and-load-profile">
              <span>View Case Study Details</span>
            </Link>
          </a>
        </div>

        {/* Contact Section */}
        <div className="mt-8 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-primary mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-primary mb-6">
            Contact us to discuss your equipment monitoring needs
          </p>
          <button
            onClick={handleClick}
            className="inline-flex items-center space-x-2 bg-secondary text-white px-6 py-3 rounded-lg hover:bg-secondary transition-colors duration-200"
          >
            <Mail className="w-5 h-5" />
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SailBokaro;
