import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import deployment from "../../assets/deployment.jpg";

import ApplicationGrid from "../ApplicationGrid";

import SailBilai from "../Projects/SailBilai";
import Kallakal from "../Projects/Kallakal";
import HIL from "../Projects/HIL";

import hil from "../../assets/clients/HIL.jpg";

import sail from "../../assets/clients/sail.jpg";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";
import hotstripmill from "../../assets/clients/bokaro.jpg";
import SailBokaro from "../Projects/SailBokaro";

import pumps from "../../assets/applications/pump.jpg";
import rod from "../../assets/applications/rod_mill.jpg";
import compressor from "../../assets/applications/compressor.jpg";
import belts from "../../assets/applications/conveyor_belts.jpg";
import fans from "../../assets/applications/exhaust_fans.jpg";
import mixers from "../../assets/applications/Nodulizer_mixers.jpeg";
import inductionMotors from "../../assets/applications/induction_motor.jpg";



import { getCDNUrl } from "../../utils/cdn";





// Separate component for the image display
const Robust = () => {
  return (
    <img
      src={deployment}
      alt="deployment"
      className="w-full h-auto object-cover rounded-lg"
      loading="lazy" // Added for better performance
    />
  );
};

const cardData1 = [
  {
    title: "Sail Bhilai",
    location: "",
    imageSrc: sail,
    children: <SailBilai />,
  },
  {
    title: "Kallakal",
    location: "",
    imageSrc: kallakal,
    children: <Kallakal />,
  },
];

const cardData2 = [
  {
    title: "Sail Bhilai",
    location: "",
    imageSrc: sail,
    children: <SailBilai />,
  },
];

const cardData3 = [
  {
    title: "Kallakal",
    location: "",
    imageSrc: kallakal,
    children: <Kallakal />,
  },
];

const cardData4 = [
  {
    title: "Sail Bhilai",
    location: "",
    imageSrc: sail,
    children: <SailBilai />,
  },
  {
    title: "Kallakal",
    location: "",
    imageSrc: kallakal,
    children: <Kallakal />,
  },
  {
    title: "HIL",
    location: "",
    imageSrc: hil,
    children: <HIL />,
  },
];

const cardData5 = [
  {
    title: "Hot Strip Mill",
    location: "",
    imageSrc: hotstripmill,
    children: <SailBokaro />,
  },
];

const MachinesAccordon = () => {
  // Changed default state to "equipments" to match your sections
  const [expandedSection, setExpandedSection] = useState("Pumps");

  const sections = {
    Pumps: {
      title: "Pumps",
      description:
        "Pumps are among the most widely used rotating equipment, driving critical processes across industries. iHz™'s sensitivity allows us to detect developing mechanical faults like cavitation before they become critical, Blockages map real-time performance against pump characteristics curves, and optimize operational efficiency.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={pumps}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Rodmill: {
      title: "Rod Mills",
      description:
        "These massive machines, characterized by high moment of inertia and low operating speeds, are critical workhorses in material processing. Their size and operational characteristics make them particularly suited for ESA monitoring - any developing fault must overcome significant inertial forces, creating clear signatures in motor current long before damage occurs. In these machines where replacement costs are huge and downtimes impact entire production lines, early detection becomes crucial.",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage={rod}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Compressors: {
      title: "Compressors",
      description:
        "Compressed air is often called the fourth utility in industry, making compressors critical for pneumatic systems and plant operations. Their reciprocating nature creates unique load patterns and stress points that need careful monitoring. iHz™'s sensitivity to these distinct signatures.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={compressor}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Conveyor: {
      title: "Conveyor Belts",
      description:
        "These material handling systems are the lifelines of industrial operations, moving tons of material 24x7. From long belt runs to inclined systems, they face varying loads and multiple stress points across their drive trains - motor, fluid couplings, gearboxes, and pulleys. Any failure can halt entire production lines, making early detection crucial.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={belts}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Exhaust: {
      title: "Industrial Fans",
      description:
        "Operating continuously in high-temperature zones and aggressive environments, these machines are critical for heat management and air quality. When fans fail, entire production lines can face emergency shutdowns. iHz™ helps detect developing issues while monitoring through varying speeds and loads.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={fans}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Mixers: {
      title: "Mixers",
      description:
        "These machines perform critical mixing operations where blade speed, temperature, and mixing efficiency directly impact product quality. Whether it's high-speed hot mixers pushing 130°C or slow-speed agitators blending heavy materials, the motors endure varying loads and thermal stresses. iHz™ excels at detecting subtle changes in shaft alignment, and mixing efficiency patterns before they affect batch quality or lead to catastrophic failures.",
      content: (
        <ApplicationGrid
          cards={cardData4}
          title="Featured Projects"
          heroImage={mixers}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Induction: {
      title: "Induction Motors",
      description:
        "These versatile machines power industry across diverse applications and challenging conditions - from dusty environments to explosive atmospheres, high temperatures to hard-to-reach locations. Despite their reliability, motors face both electrical stresses (winding degradation, rotor issues, voltage unbalance) and mechanical challenges (bearing failures, shaft misalignment). ESA becomes powerful here - by analyzing current signatures, we detect developing issues long before they become critical, whether it's early-stage rotor bar cracks or bearing wear. It's about understanding the motor's language through its electrical patterns",

      content: (
        <ApplicationGrid
          cards={cardData5}
          title="Featured Projects"
          heroImage={inductionMotors}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz™ can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
    // Modified to allow toggling closed
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-sm border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg p-3 sm:p-4 transition-all duration-300 ease-in-out min-h-[250px] sm:min-h-[300px] lg:min-h-[400px]">
              {expandedSection && sections[expandedSection]?.content}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-secondary"
                        : "text-primary hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachinesAccordon;
