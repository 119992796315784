import React from "react";
import { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import prodictSpiderAI from "../assets/spiderai-hero.jpg";
import BoxReveal from "./ui/BoxReveal";

import Getdemo from "./getdemo";
import { ChevronRight } from "lucide-react";

import { getCDNUrl } from "../utils/cdn";

const ProductPage = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative h-[80vh] overflow-hidden flex flex-col justify-center mt-12">
      {/* Background Image with Parallax - Using the same positioning strategy */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${getCDNUrl("/spiderai-hero.jpg")})`,
          transform: `translateY(${offset * 0.5}px)`,
        }}
      />

      {/* Gradient Overlay - Matching the home page style */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/60 to-black/30" />

      {/* Hero Content */}
      <div className="relative z-10 container mx-auto px-4 lg:px-8 mt-8">
        <div className="max-w-2xl lg:max-w-3xl">
          {/* Technical Label */}
          <BoxReveal boxColor={"#ff5757"} duration={0.4}>
            <div className="flex items-center space-x-3 mb-6">
              <div className="h-1 w-12 bg-secondary" />
              <span className="text-secondary text-sm tracking-wider">
                OPERATIONAL INTELLIGENCE PLATFORM
              </span>
            </div>
          </BoxReveal>

          {/* Main Title */}
          <BoxReveal boxColor={"#ff5757"} duration={0.4}>
            <div className="flex items-baseline mb-6">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white">
                spiderAI
                <sup className="text-xl text-secondary">™</sup>
              </h1>
            </div>
          </BoxReveal>

          {/* Description Box */}
          <BoxReveal boxColor={"#ff5757"} duration={0.4}>
            <div className="bg-black/50 backdrop-blur-sm p-6 border-l-2 border-secondary max-w-2xl mb-8">
              <p className="text-white text-lg lg:text-xl">
                An assistive copilot to revolutionise how we interact with
                electrical machines, equipment and infrastructure
              </p>
            </div>
          </BoxReveal>

          {/* Get Demo Button */}
          <BoxReveal boxColor={"#ff5757"} duration={0.4}>
            <Getdemo
              className="bg-secondary hover:bg-secondary/90 text-white px-8 py-3
                        flex items-center space-x-2 transition-all duration-300
                        transform hover:translate-x-2"
            >
              <span className="font-semibold">Get Demo</span>
              <ChevronRight className="w-5 h-5" />
            </Getdemo>
          </BoxReveal>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
