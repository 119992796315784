import React from "react";
import bokarodep from "../../assets/clients/bokaro-deployment.jpg";
import { getCDNUrl } from "../../utils/cdn";

const InstallMccSensor = () => {
  const features = [
    "Monitor your machines wherever they are",
    "Quick and easy installation",
    "Controlled environment for reliable operation",
    "Minimal maintenance requirements",
  ];

  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="flex flex-col lg:flex-row bg-white rounded-xl overflow-hidden">
        {/* Left Content Section */}
        <div className="lg:w-1/2 p-8 lg:p-12 flex flex-col justify-center">
          <div className="space-y-6">
            {/* Subtitle */}

            {/* Main Title */}
            <h2 className="text-3xl lg:text-4xl font-bold text-primary">
              Safely install the condition monitoring sensor inside the motor
              control cabinet
            </h2>

            <ul className="space-y-4">
              {features.map((feature, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <span className="flex-shrink-0 w-5 h-5 bg-secondary rounded-full flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <span className="text-primary">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* Right Image Section */}
        <div className="lg:w-1/2">
          <div className="h-full relative flex items-center justify-center">
            <img
              src={getCDNUrl("/clients/bokaro-deployment.jpg")}
              alt="Electrical panel monitoring installation"
              className="w-full h-full object-fit rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// "https://minto-spiderai-website.s3.us-east-2.amazonaws.com/iHz_MCC.mp4"   -- how it works video
//  "https://minto-spiderai-website.s3.us-east-2.amazonaws.com/Installation+(online-video-cutter.com)+(1).mp4"   -- trimmed video
//  https://minto-webapp-v3.s3.us-east-2.amazonaws.com/video_iteration_1.mp4

export default InstallMccSensor;
