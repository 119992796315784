import React, { useState, useEffect } from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { Activity, Radio, Clock, Gauge, AlertCircle, Zap } from "lucide-react";

const PRIMARY_COLOR = "#184384";
const SECONDARY_COLOR = "#ff5757";

const Ticker = ({ value, duration, startValue = 0, onComplete }) => {
  const [currentValue, setCurrentValue] = useState(startValue);

  useEffect(() => {
    let start = startValue;
    const increment = (value - startValue) / (duration / 10);
    const interval = setInterval(() => {
      start += increment;
      if (
        (increment > 0 && start >= value) ||
        (increment < 0 && start <= value)
      ) {
        start = value;
        clearInterval(interval);
        if (onComplete) onComplete();
      }
      setCurrentValue(start.toFixed(1));
    }, 10);

    return () => clearInterval(interval);
  }, [value, duration, startValue, onComplete]);

  return <span>{currentValue}</span>;
};

const KpiDashboard = () => {
  const createInitialHistory = (value, count = 20) => {
    return Array(count)
      .fill(null)
      .map(() => ({ value }));
  };

  const generateValue = (current, step, min, max) => {
    const newValue = current + (Math.random() * step * 2 - step);
    return Math.max(Math.min(newValue, max), min);
  };

  const [tickingComplete, setTickingComplete] = useState(false);
  const [metrics, setMetrics] = useState({
    snr: { value: -90.1, history: createInitialHistory(-90.1) },
    availability: { value: 99.2, history: createInitialHistory(99.2) },
    rpm_accuracy: { value: 965, error: 2, history: createInitialHistory(965) },
    uptime: { value: 98.6, history: createInitialHistory(98.6) },
    rms_error: { value: 56, error: 1, history: createInitialHistory(56) },
    timestamp: new Date(),
  });

  useEffect(() => {
    if (!tickingComplete) return;

    const interval = setInterval(() => {
      setMetrics((prev) => {
        const newSnr = generateValue(prev.snr.value, 2, -92, -90);
        const newAvail = generateValue(prev.availability.value, 0.2, 99, 99.9);
        const newRms = generateValue(prev.rms_error.value, 1, 56, 60);
        const newUptime = generateValue(prev.uptime.value, 0.1, 98.6, 99.7);
        const newRpm = generateValue(prev.rpm_accuracy.value, 2, 965, 970);

        return {
          snr: {
            value: newSnr,
            history: [...prev.snr.history.slice(1), { value: newSnr }],
          },
          availability: {
            value: newAvail,
            history: [
              ...prev.availability.history.slice(1),
              { value: newAvail },
            ],
          },
          rpm_accuracy: {
            value: newRpm,
            error: 2, // Fixed at ±2 RPM
            history: [...prev.rpm_accuracy.history.slice(1), { value: newRpm }],
          },
          uptime: {
            value: newUptime,
            history: [...prev.uptime.history.slice(1), { value: newUptime }],
          },
          rms_error: {
            value: newRms,
            error: 1, // Fixed at ±1%
            history: [...prev.rms_error.history.slice(1), { value: newRms }],
          },
          timestamp: new Date(),
        };
      });
    }, 500);
    return () => clearInterval(interval);
  }, [tickingComplete]);

  const Sparkline = ({ data, color }) => (
    <ResponsiveContainer width="100%" height={32}>
      <LineChart data={data}>
        <Line
          type="monotone"
          dataKey="value"
          stroke={color || PRIMARY_COLOR}
          strokeWidth={1}
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );

  const GaugeWithReading = ({
    title,
    value,
    max,
    color,
    unit,
    icon: Icon,
    error,
    showReadings = true,
    showErrorAsRPM = false,
  }) => {
    const currentReading = value + (Math.random() * 2 - 1);

    return (
      <div className="bg-white rounded border border-gray-100 p-3 h-[14rem] lg:h-[16rem]">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-1.5">
            <Icon className="w-4 h-4" style={{ color: PRIMARY_COLOR }} />
            <h3
              className="text-sm font-normal"
              style={{ color: PRIMARY_COLOR }}
            >
              {title}
            </h3>
          </div>
        </div>
        <div className="relative h-24 lg:h-28 mb-2">
          <svg className="w-3/4 mx-auto h-full" viewBox="0 0 100 50">
            <path
              d="M 10 45 A 40 40 0 0 1 90 45"
              fill="none"
              stroke="#f3f4f6"
              strokeWidth="8"
              strokeLinecap="round"
            />
            <path
              d="M 10 45 A 40 40 0 0 1 90 45"
              fill="none"
              stroke={color}
              strokeWidth="8"
              strokeLinecap="round"
              strokeDasharray={`${(value / max) * 125.6} 125.6`}
            />
          </svg>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 text-center">
            <span
              className="text-base lg:text-lg font-medium"
              style={{ color }}
            >
              {tickingComplete ? (
                value.toFixed(1)
              ) : (
                <Ticker
                  value={value}
                  duration={4000}
                  startValue={0}
                  onComplete={() => setTickingComplete(true)}
                />
              )}
              {unit}
            </span>
          </div>
        </div>
        {showReadings && (
          <div className="bg-gray-50 rounded-lg p-2">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="flex items-center gap-1 mb-0.5">
                  <Clock className="w-3 h-3" style={{ color: PRIMARY_COLOR }} />
                  <span className="text-xs" style={{ color: PRIMARY_COLOR }}>
                    Current Reading
                  </span>
                </div>
                <span
                  className="text-sm lg:text-base font-medium"
                  style={{ color }}
                >
                  {currentReading.toFixed(1)}
                  {unit}
                </span>
              </div>
              <div>
                <div className="flex items-center gap-1 mb-0.5">
                  <AlertCircle
                    className="w-3 h-3"
                    style={{ color: PRIMARY_COLOR }}
                  />
                  <span className="text-xs" style={{ color: PRIMARY_COLOR }}>
                    Accuracy
                  </span>
                </div>
                {error && (
                  <span
                    className="text-sm lg:text-base font-medium"
                    style={{ color: PRIMARY_COLOR }}
                  >
                    ±
                    {showErrorAsRPM
                      ? `${error.toFixed(0)} RPM`
                      : `${error.toFixed(1)}%`}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 p-2 lg:p-4">
      <div className="max-w-5xl mx-auto space-y-4">
        <div className="flex items-center gap-1.5">
          <Activity className="w-4 h-4" style={{ color: PRIMARY_COLOR }} />
          <h1 className="text-sm font-normal" style={{ color: PRIMARY_COLOR }}>
            iHz™ Metrics
          </h1>
        </div>

        <div className="space-y-4">
          <div className="bg-white rounded border border-gray-100 p-3 h-[10rem] lg:h-[6rem]">
            <div className="flex items-center gap-1.5 mb-2">
              <Radio className="w-4 h-4" style={{ color: PRIMARY_COLOR }} />
              <h2
                className="text-sm font-normal"
                style={{ color: PRIMARY_COLOR }}
              >
                Signal-to-Noise Ratio
              </h2>
            </div>
            <div className="flex items-baseline gap-2 mb-3">
              <span
                className="text-2xl lg:text-3xl font-medium"
                style={{ color: PRIMARY_COLOR }}
              >
                {tickingComplete ? (
                  metrics.snr.value.toFixed(1)
                ) : (
                  <Ticker
                    value={metrics.snr.value}
                    duration={4000}
                    startValue={-70}
                    onComplete={() => setTickingComplete(true)}
                  />
                )}
              </span>
              <span className="text-base lg:text-lg text-gray-500">dB</span>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <GaugeWithReading
              title="Motor Current"
              value={metrics.rms_error.value}
              max={80}
              color={SECONDARY_COLOR}
              unit=""
              icon={AlertCircle}
              error={metrics.rms_error.error}
            />
            <GaugeWithReading
              title="Motor Speed"
              value={metrics.rpm_accuracy.value}
              max={1200}
              unit=" RPM"
              icon={Gauge}
              color={PRIMARY_COLOR}
              error={metrics.rpm_accuracy.error}
              showErrorAsRPM={true}
            />
            <GaugeWithReading
              title="System Availability"
              value={metrics.availability.value}
              max={100}
              color={PRIMARY_COLOR}
              unit="%"
              icon={Clock}
              showReadings={false}
            />
            <GaugeWithReading
              title="Device Uptime"
              value={metrics.uptime.value}
              max={100}
              color={PRIMARY_COLOR}
              unit="%"
              icon={Zap}
              showReadings={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiDashboard;
