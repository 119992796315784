import React from "react";
import {
  Factory,
  Wrench,
  AlertTriangle,
  Lightbulb,
  MessageSquare,
  ExternalLink,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SailBilai = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
  };

  const sections = [
    {
      title: "Plant Summary",
      icon: <Factory className="w-5 h-5 text-primary" />,
      content: `This is a Sinter Plant within SAIL's Bhilai Steel Plant - India's premier steel producer and largest supplier of rails. The sinter plant handles the crucial first stage of steel making, where raw ore from mines undergoes multiple transformations. Heavy-duty hammer crushers break down the ore, rod mills grind it further, and an intricate network of conveyor belts transport materials between processes. Powerful agitators mix these materials under extreme torsional forces before they enter hot ovens to form sinter - the feedstock for downstream operations. The environment is intensely dusty with high-voltage equipment operating in tough-to-access locations.`,
    },
    {
      title: "Requirement",
      icon: <Wrench className="w-5 h-5 text-primary" />,
      content: (
        <div>
          Implement an advanced pre-failure alert system with specific targets:
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li>Warning time &gt; 7 days before potential failures</li>
            <li>True Positive Rate &gt; 90%</li>
            <li>Precision &gt; 80%</li>
            <li>Diagnosis Accuracy&gt; 90%</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Execution Challenges",
      icon: <AlertTriangle className="w-5 h-5 text-primary" />,
      content: `The complexity spans both downstream and upstream challenges. At the field level, legacy equipment operates beyond design life in harsh, dusty environments, with complex drive trains making traditional monitoring nearly impossible. The diversity of machines - from HV hammer crushers with fluid couplings to LV nodulizer agitators with VFD drives - adds to the complexity. Upstream, organizational challenges include reliability issues with aging equipment, steep learning curves for field engineers, and the growing entropy of industrial systems making failure patterns increasingly non-linear.`,
    },
    {
      title: "Solution",
      icon: <Lightbulb className="w-5 h-5 text-primary" />,
      content: `The implementation combines iHz™ devices for high-fidelity data acquisition with spiderAI™'s conversational interface. Phase 1 has already demonstrated impressive results with over 98% uptime and throughput, maintaining signal integrity even in challenging conditions like the nodulizer agitators. The project is expanding across 50 critical motors, establishing a foundation for advanced diagnostics and natural language-based interaction with the system.`,
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* Main Content */}
      <div>
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="mb-8 last:mb-0 hover:bg-gray-50 rounded-lg transition-colors duration-200 p-4"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-primary">
                {section.title}
              </h2>
            </div>
            <div className="pl-14">
              {typeof section.content === "string" ? (
                <p className="text-primary leading-relaxed">
                  {section.content}
                </p>
              ) : (
                <div className="text-primary leading-relaxed">
                  {section.content}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SailBilai;
