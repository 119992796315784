import React, { useState } from "react";
import { Plus, Minus, Dice1, Divide } from "lucide-react";

import ApplicationGrid from "../ApplicationGrid";

import SailBilai from "../Projects/SailBilai";
import Kallakal from "../Projects/Kallakal";
import HIL from "../Projects/HIL";

import hil from "../../assets/clients/HIL.jpg";

import sail from "../../assets/clients/sail.jpg";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";

import eccentric from "../../assets/applications/eccentric_mass_unbalance.webp";
import resiprocating from "../../assets/applications/compressor.jpg";
import fluid from "../../assets/applications/fluid_coupling.jpeg";
import cardan from "../../assets/applications/cardan_shaft.jpg";
import vbelt from "../../assets/applications/v-Belt.jpg";
import gearbox from "../../assets/applications/gearbox_2.jpg";

// Placeholder component for content
const ContentPlaceholder = ({ title }) => (
  <div className="flex items-center justify-center h-full min-h-[300px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
    <div className="text-center">
      <h3 className="text-lg font-medium text-gray-600">{title}</h3>
      <p className="mt-2 text-sm text-gray-500">
        Content visualization will appear here
      </p>
    </div>
  </div>
);

const DriveTrainAccordion = () => {
  const cardData2 = [
    {
      title: "Sail Bhilai",
      location: "",
      imageSrc: sail,
      children: <SailBilai />,
    },
  ];

  const cardData3 = [
    {
      title: "Kallakal",
      location: "",
      imageSrc: kallakal,
      children: <Kallakal />,
    },
  ];

  // Set initial state to the first section key
  const [expandedSection, setExpandedSection] = useState("Eccentric");

  const sections = {
    Eccentric: {
      title: "Eccentric Mass Unbalance",
      description:
        "Deliberately designed to vibrate, making traditional vibration monitoring not just difficult but almost meaningless. What makes them particularly critical is their rapid path to failure - once wear begins, deterioration can accelerate within days. Their typically hard-to-reach locations and self-vibrating nature eliminate most conventional monitoring options. ESA becomes invaluable here, capturing developing faults through motor current patterns ",

      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={eccentric}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    ReciprocatingSystems: {
      title: "Reciprocating Systems",
      description:
        "From compressors to piston pumps, these machines present unique monitoring challenges due to their inherent load reversals and cyclic stresses. Unlike steady-state loads, reciprocating motion creates complex force patterns that stress components differently in each cycle. What makes ESA particularly powerful here is its ability to capture these cyclic patterns and detect subtle changes that indicate developing issues - whether it's valve problems, piston wear, or bearing deterioration. The motor's current signature essentially maps out the complete mechanical cycle, revealing issues traditional monitoring might miss.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={resiprocating}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    fluidCoupling: {
      title: "Fluid Coupling",
      description:
        "Unlike direct couplings, fluid couplings use hydraulic fluid to transfer power, acting as both a torque limiter and shock absorber. This dynamic behavior creates unique load patterns - from slip during startup to variations under load changes. These changes leaves fingerprints in Current Spectrums.",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage={fluid}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    CardanShafts: {
      title: "Cardan Shafts",
      description:
        "These power transmission elements, add unique complexity to machine monitoring. Their universal joints create distinct torque variations in each revolution, and when these shafts operate under high temperatures and demanding conditions, the monitoring challenge intensifies. ESA's sensitivity to these torque patterns becomes crucial - we can detect developing issues in the joints.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={cardan}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Vbelt: {
      title: "V-belt ",
      description:
        "One of the most common yet complex power transmission elements. While they provide flexibility in design and shock absorption, their dynamic behavior adds layers to fault detection - from subtle slipping to misalignment and tension issues. Each of these can mimic other fault patterns, making diagnosis tricky. The real challenge is in differentiating between normal belt wear, tension chan",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={vbelt}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Gearbox: {
      title: "Gearbox ",
      description:
        "gearboxes transfer torque across multiple stages - handling speed reduction, direction changes, and varying load requirements for machines. As they transmit these forces, they experience complex torsional changes throughout their gear stages. ESA's sensitivity to these torque variations makes it remarkably effective - when gear teeth start wearing, backlash develops, or bearings begin to fail, these changes manifest as subtle variations in motor current. This gives us deep insights into gearbox health, catching developing issues before they cascade into system-wide failures.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={gearbox}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz™ can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-lg border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg p-4 transition-all duration-300 ease-in-out">
              {expandedSection ? (
                sections[expandedSection]?.content
              ) : (
                <ContentPlaceholder title="Select a section to view details" />
              )}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-secondary"
                        : "text-primary hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveTrainAccordion;
