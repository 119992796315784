import React, { useState } from "react";
import { ChevronRight, X } from "lucide-react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="absolute inset-0 bg-black bg-opacity-30"
        onClick={onClose}
      ></div>
      <div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4 z-10">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        {children}
      </div>
    </div>
  );
};

const MultiStepContactForm = () => {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    interests: {
      iHz: false,
      spiderAI: false,
    },
    applications: [],
    otherInputs: {}, // Store other input values for each category
    assetCount: "",
    description: "",
    name: "",
    email: "",
    phoneCountry: "+91",
    phoneNumber: "",
  });

  const assetCountOptions = [
    { value: "1-49", label: "1 - 49" },
    { value: "50-99", label: "50 - 99" },
    { value: "100-499", label: "100 - 499" },
    { value: "500-4999", label: "500 - 4,999" },
    { value: "5000+", label: "5,000+" },
  ];

  const applications = {
    "Machines & Equipments": [
      { id: "pumps", label: "Pumps" },
      { id: "rod-mills", label: "Rod Mills" },
      { id: "compressors", label: "Compressors" },
      { id: "conveyor-belts", label: "Conveyor Belts" },
      { id: "exhaust-fans", label: "Exhaust Fans" },
      { id: "mixers", label: "Mixers" },
      { id: "induction-motors", label: "Induction Motors" },
      { id: "other-applications", label: "Other" },
    ],
    "Fault Types": [
      { id: "mechanical-faults", label: "Mechanical Faults" },
      { id: "electrical-faults", label: "Electrical Faults" },
      { id: "subsystem-level", label: "Subsystem Level Fault Diagnosis" },
      { id: "component-level", label: "Component Level Fault Diagnosis" },
      { id: "other-fault-types", label: "Other" },
    ],
    "Drive Train Complexity": [
      { id: "eccentric-mass", label: "Eccentric Mass Unbalance" },
      { id: "reciprocating-systems", label: "Reciprocating Systems" },
      { id: "fluid-coupling", label: "Fluid Coupling" },
      { id: "cardan-shafts", label: "Cardan Shafts" },
      { id: "v-belt", label: "V-belt" },
      { id: "gearbox", label: "Gearbox" },
      { id: "other-drive-train-complexity", label: "Other" },
    ],
    "Operational Complexity": [
      { id: "vfd-driven", label: "VFD Driven" },
      { id: "high-voltage", label: "High Voltage" },
      { id: "low-speed", label: "Low-speed Operation" },
      { id: "rapid-start-stop", label: "Rapid Start/stop" },
      { id: "hard-access", label: "Hard to Access Equipment" },
      { id: "atex-zones", label: "Atex Zones" },
      { id: "dusty-environments", label: "Dusty Environments" },
      { id: "other-operational-complexity", label: "Other" },
    ],
    "Usecase Type": [
      { id: "predictive-maintenance", label: "Predictive Maintenance" },
      { id: "condition-monitoring", label: "Condition Monitoring" },
      { id: "reliability-engineering", label: "Reliability Engineering" },
      { id: "root-cause-analysis", label: "Root Cause Analysis /FMEA" },
      { id: "digital-transformation", label: "Digital Transformation" },
      { id: "other-usecase-type", label: "Other" },
    ],
    "Industry Type": [
      { id: "heavy-industries", label: "Heavy Industries" },
      { id: "manufacturing", label: "Manufacturing" },
      { id: "utilities", label: "Utilities" },
      { id: "renewables", label: "Renewables" },
      { id: "transportation", label: "Transportation" },
      { id: "automotive", label: "Automotive" },
      { id: "other-industry-type", label: "Other" },
    ],
  };

  const handleNext = () => {
    const nextStep = step + 1;
    setStep(nextStep);
    setProgress((nextStep - 1) * (100 / 4));
  };

  const handleBack = () => {
    const prevStep = step - 1;
    setStep(prevStep);
    setProgress((prevStep - 1) * (100 / 4));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => {
      if (type === "checkbox") {
        if (name.startsWith("interests.")) {
          const interestName = name.split(".")[1];
          return {
            ...prev,
            interests: {
              ...prev.interests,
              [interestName]: checked,
            },
          };
        }
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleOtherInput = (categoryId, value) => {
    setFormData((prev) => ({
      ...prev,
      otherInputs: {
        ...prev.otherInputs,
        [categoryId]: value,
      },
    }));
  };

  const handleApplicationSelect = (applicationId) => {
    setFormData((prev) => {
      const newApplications = prev.applications.includes(applicationId)
        ? prev.applications.filter((id) => id !== applicationId)
        : [...prev.applications, applicationId];

      // If unchecking an "Other" option, remove its corresponding input
      if (
        !newApplications.includes(applicationId) &&
        applicationId.startsWith("other-")
      ) {
        const { [applicationId]: _, ...remainingOtherInputs } =
          prev.otherInputs;
        return {
          ...prev,
          applications: newApplications,
          otherInputs: remainingOtherInputs,
        };
      }

      return {
        ...prev,
        applications: newApplications,
      };
    });
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return formData.interests.iHz || formData.interests.spiderAI;
      case 2:
        // Check if all selected "Other" options have corresponding input values
        const hasAllOtherInputs = formData.applications
          .filter((id) => id.startsWith("other-"))
          .every((id) => formData.otherInputs[id]?.trim());
        return formData.applications.length > 0 && hasAllOtherInputs;
      case 3:
        return formData.assetCount !== "";
      case 4:
        return formData.name && formData.email && formData.phoneNumber;
      default:
        return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://ugkluryc86.execute-api.us-east-2.amazonaws.com/prod/v4/spiderAI/website",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API Response:", result);

      // Show success message
      setShowSuccess(true);

      // Reset form
      setFormData({
        interests: { iHz: false, spiderAI: false },
        applications: [],
        otherInputs: {},
        assetCount: "",
        description: "",
        name: "",
        email: "",
        phoneCountry: "+91",
        phoneNumber: "",
      });

      setStep(1);
      setProgress(0);
    } catch (error) {
      console.error("Error submitting form:", error);
      // You might want to add error handling here, such as:
      // setShowError(true);
      // setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-10 px-20">
      <Modal isOpen={showSuccess} onClose={() => setShowSuccess(false)}>
        <div className="text-center">
          <h3 className="text-2xl font-semibold text-primary mb-4">
            Thank you!
          </h3>
          <div className="bg-green-50 border border-green-200 rounded-lg p-4">
            <p className="text-green-800 font-medium">Success</p>
            <p className="text-green-700 mt-1">
              Thank you for your interest. We'll be in touch shortly.
            </p>
          </div>
        </div>
      </Modal>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-x-12">
        <div className="col-span-1 mb-8 lg:mb-0">
          <div className="sticky top-10">
            <h2 className="text-3xl text-primary">Interested!</h2>
            <h3 className="text-3xl text-primary">
              Book a Demo with us or know about our offerings!
            </h3>
          </div>
        </div>

        <div className="col-span-1 md:col-span-2">
          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="mb-8 hidden md:block">
              <div className="h-2 w-full bg-gray-200 rounded-full">
                <div
                  className="h-full bg-primary rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <div className="mt-2 text-sm text-primary font-medium">
                Step {step} of 4
              </div>
            </div>

            {step === 1 && (
              <div className="space-y-4">
                <p className="text-sm font-medium text-primary">
                  Interested in:
                </p>
                <div className="space-y-2">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      name="interests.iHz"
                      checked={formData.interests.iHz}
                      onChange={handleChange}
                      className="rounded border-gray-300 text-primary focus:ring-primary"
                    />
                    <span className="text-sm text-gray-700">
                      iHz™ - Diagnostic System
                    </span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      name="interests.spiderAI"
                      checked={formData.interests.spiderAI}
                      onChange={handleChange}
                      className="rounded border-gray-300 text-primary focus:ring-primary"
                    />
                    <span className="text-sm text-gray-700">
                      spiderAI™ - Operational Intelligence Platform
                    </span>
                  </label>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm text-primary mb-1">
                    Choose Your Applications
                  </label>
                  <div className="space-y-4">
                    {Object.entries(applications).map(([category, items]) => (
                      <div key={category} className="space-y-2">
                        <h4 className="text-sm font-medium text-gray-700">
                          {category}
                        </h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                          {items.map((item) => (
                            <div key={item.id}>
                              <label className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  checked={formData.applications.includes(
                                    item.id
                                  )}
                                  onChange={() =>
                                    handleApplicationSelect(item.id)
                                  }
                                  className="rounded border-gray-300 text-primary focus:ring-primary"
                                />
                                <span className="text-sm text-gray-700">
                                  {item.label}
                                </span>
                              </label>
                              {item.id.startsWith("other-") &&
                                formData.applications.includes(item.id) && (
                                  <input
                                    type="text"
                                    value={formData.otherInputs[item.id] || ""}
                                    onChange={(e) =>
                                      handleOtherInput(item.id, e.target.value)
                                    }
                                    placeholder="Please specify"
                                    className="mt-2 ml-6 w-full max-w-xs border-b border-gray-300 pb-2 focus:border-primary focus:outline-none bg-transparent text-sm"
                                  />
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm text-primary mb-1">
                    Number of assets
                  </label>
                  <div className="space-y-2">
                    {assetCountOptions.map((option) => (
                      <label
                        key={option.value}
                        className="flex items-center space-x-2 p-3 border rounded-lg hover:bg-gray-50 cursor-pointer"
                      >
                        <input
                          type="radio"
                          name="assetCount"
                          value={option.value}
                          checked={formData.assetCount === option.value}
                          onChange={handleChange}
                          className="text-primary focus:ring-primary"
                        />
                        <span className="text-sm text-gray-700">
                          {option.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm text-primary mb-1">
                    Help us to understand your problem better
                  </label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows={4}
                    className="w-full border-b border-gray-300 pb-2 focus:border-primary focus:outline-none bg-transparent resize-none"
                    placeholder="Give a detailed description of your use-case"
                  />
                </div>
              </div>
            )}

            {/* Step 4: Personal Information */}
            {step === 4 && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <label className="block text-sm text-primary mb-1">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full border-b border-gray-300 pb-2 focus:border-primary focus:outline-none bg-transparent"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-primary mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full border-b border-gray-300 pb-2 focus:border-primary focus:outline-none bg-transparent"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm text-primary mb-1">
                    Phone Number
                  </label>
                  <div className="flex gap-4">
                    <div className="w-1/3">
                      <select
                        name="phoneCountry"
                        value={formData.phoneCountry}
                        onChange={handleChange}
                        className="w-full border-b border-gray-300 pb-2 focus:border-primary focus:outline-none bg-transparent"
                      >
                        {[
                          { code: "+91", country: "India" },
                          { code: "+1", country: "USA/Canada" },
                          { code: "+44", country: "UK" },
                          { code: "+86", country: "China" },
                          { code: "+81", country: "Japan" },
                          { code: "+49", country: "Germany" },
                          { code: "+33", country: "France" },
                          { code: "+61", country: "Australia" },
                          { code: "+7", country: "Russia" },
                          { code: "+39", country: "Italy" },
                          { code: "+34", country: "Spain" },
                          { code: "+82", country: "South Korea" },
                          { code: "+55", country: "Brazil" },
                          { code: "+52", country: "Mexico" },
                          { code: "+65", country: "Singapore" },
                          { code: "+31", country: "Netherlands" },
                          { code: "+46", country: "Sweden" },
                          { code: "+41", country: "Switzerland" },
                          { code: "+32", country: "Belgium" },
                          { code: "+43", country: "Austria" },
                        ].map(({ code, country }) => (
                          <option key={code} value={code}>
                            {code} ({country})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-2/3">
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="w-full border-b border-gray-300 pb-2 focus:border-primary focus:outline-none bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between pt-8">
              {step > 1 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Back
                </button>
              )}

              {step < 4 ? (
                <button
                  type="button"
                  onClick={handleNext}
                  disabled={!isStepValid()}
                  className="flex items-center ml-auto gap-2.5 px-3 py-2 text-sm transition-colors duration-200 ease-in-out font-medium tracking-wide text-white uppercase bg-secondary disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="flex-grow font-bold">Next</span>
                  <ChevronRight className="flex-shrink-0" size={18} />
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={!isStepValid() || isSubmitting}
                  className="flex items-center ml-auto gap-2.5 px-3 py-2 text-sm transition-colors duration-200 ease-in-out font-medium tracking-wide text-white uppercase bg-secondary disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="flex-grow font-bold">
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </span>
                  <ChevronRight className="flex-shrink-0" size={18} />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MultiStepContactForm;
