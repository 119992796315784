import React from "react";
import {
  Factory,
  Wrench,
  AlertTriangle,
  Lightbulb,
  MessageSquare,
  ExternalLink,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";

const Bokaro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
  };

  const sections = [
    {
      title: "Plant Summary",
      icon: <Factory className="w-5 h-5 text-primary" />,
      content: `This is a blooming mill section within Tata Steel's massive 10 MnTPA Jamshedpur facility - one of India's largest integrated steel-making operations. The blooming mill is a critical link in the steel processing chain, transforming large steel ingots into manageable blooms for further processing. They are driven by Continuous Stands which drive the blooms over long runways, Rolling mills squeeze the blooms into desired shapes, Exhaust Fans for cooling the systems and Hot Saws for cutting the blooms to desired lengths and Hydraulic Pumps to power most of the pushes and pulls involved in this process.`,
    },
    {
      title: "Requirement",
      icon: <Wrench className="w-5 h-5 text-primary" />,
      content:
        "Initially approached as a predictive maintenance implementation, but deeper analysis revealed the critical need to understand and prevent recurring hot saw failures.",
    },
    {
      title: "Execution Challenges",
      icon: <AlertTriangle className="w-5 h-5 text-primary" />,
      content: `The blooming mill environment presents extreme challenges - intense heat from red-hot steel, heavy torsional loads during cutting, and constant shock loads that stress equipment beyond normal limits. The hot saw failures were particularly concerning, with unpredictable shaft breakages and inconsistent blade life severely impacting operations. Traditional monitoring approaches struggled with these conditions, while the complexity of failure patterns made conventional predictions unreliable. Network infrastructure limitations further complicated implementation, forcing a focused approach on the most critical equipment.`,
    },
    {
      title: "Solution",
      icon: <Lightbulb className="w-5 h-5 text-primary" />,
      content: `The project was to monitor hot saws, Exhaust Fans and hydraulic systems. Through focused monitoring from February to May, iHz successfully detected four critical hot saw faults, providing both advance warnings and detailed failure analysis. This deep insight into failure patterns revealed underlying structural issues, leading to a complete frame redesign and significantly improving equipment reliability.`,
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* Main Content */}
      <div>
        {sections.map((section, index) => (
          <div
            key={section.title}
            className="mb-8 last:mb-0 hover:bg-gray-50 rounded-lg transition-colors duration-200 p-4"
          >
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-50 p-3 rounded-lg">{section.icon}</div>
              <h2 className="text-xl font-semibold text-primary">
                {section.title}
              </h2>
            </div>
            <div className="pl-14">
              <p className="text-primary leading-relaxed">{section.content}</p>
            </div>
          </div>
        ))}

        {/* Testimonial Section */}
        <div className="mt-8 bg-blue-50 rounded-lg p-6">
          <div className="flex items-center space-x-3 mb-4">
            <div className="bg-blue-100 p-3 rounded-lg">
              <MessageSquare className="w-5 h-5 text-primary" />
            </div>
            <h2 className="text-xl font-semibold text-primary">
              Client Testimonial
            </h2>
          </div>
          <div className="pl-14">
            <div className="w-full max-w-2xl mx-auto p-4 sm:p-6 bg-blue-50">
              <div className="space-y-4 sm:space-y-6">
                <div className="relative">
                  {/* Quote mark - responsive size */}
                  <div className="absolute -left-2 sm:-left-6 top-0 text-4xl sm:text-6xl text-blue-500 font-serif select-none">
                    "
                  </div>

                  {/* Quote text - responsive font size and padding */}
                  <p className="text-lg sm:text-xl md:text-2xl text-primary leading-relaxed pl-4 sm:pl-4">
                    Kudos to the team at Minto Ai for identifying the problem
                    accurately. Their timely detection helped us replace the
                    mandrel assembly, preventing further damage caused by the
                    smaller pulley key and keyway getting crushed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Case Study Link */}
        <div className="mt-8 border-t pt-6">
          <a
            href="#"
            className="inline-flex items-center space-x-2 text-secondary transition-colors duration-200"
          >
            <ExternalLink className="w-4 h-4" />
            <Link to="/usecase-health-and-load-profile">
              <span>View Hot Saw Case Study Details</span>
            </Link>
          </a>
        </div>

        {/* Contact Section */}
        <div className="mt-8 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-primary mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-primary mb-6">
            Contact us to discuss your equipment monitoring needs
          </p>
          <button
            onClick={handleClick}
            className="inline-flex items-center space-x-2 bg-secondary text-white px-6 py-3 rounded-lg hover:bg-secondary transition-colors duration-200"
          >
            <Mail className="w-5 h-5" />
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Bokaro;
