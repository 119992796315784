import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";

import ApplicationGrid from "../ApplicationGrid";

import SailBilai from "../Projects/SailBilai";
import Kallakal from "../Projects/Kallakal";
import HIL from "../Projects/HIL";

import hil from "../../assets/clients/HIL.jpg";

import sail from "../../assets/clients/sail.jpg";
import kallakal from "../../assets/foundery_kalakal_website_display.jpg";

import Bokaro from "../Projects/Bokaro"; //blooming mill
import tata from "../../assets/clients/tataSteel.jpg";

import bokaro from "../../assets/clients/bokaro.jpg";

import heavy from "../../assets/applications/heavy_manufacturing.jpg";
import SailBokaro from "../Projects/SailBokaro";
import process from "../../assets/applications/process_manufacturing.jpg";
import Energy from "../../assets/usecases/industries.jpg";
import utilities from "../../assets/applications/utilities.jpg";
import transport from "../../assets/applications/mobility.jpg";

// Placeholder component for content
const ContentPlaceholder = ({ title, description }) => (
  <div className="flex items-center justify-center h-full min-h-[300px] bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
    <div className="text-center p-6 max-w-lg">
      <h3 className="text-lg font-medium text-gray-600">{title}</h3>
      <p className="mt-2 text-sm text-gray-500">
        {description || "Industry-specific solutions and applications"}
      </p>
    </div>
  </div>
);

const IndustriesAccordion = () => {
  const cardData1 = [
    {
      title: "HIL",
      location: "",
      imageSrc: hil,
      children: <HIL />,
    },
  ];

  const cardData2 = [
    {
      title: "Kallakal",
      location: "",
      imageSrc: kallakal,
      children: <Kallakal />,
    },
  ];

  const cardData3 = [
    {
      title: "Blooming Mill",
      location: "",
      imageSrc: tata,
      children: <Bokaro />,
    },
    {
      title: "Sail Bhilai",
      location: "",
      imageSrc: sail,
      children: <SailBilai />,
    },
  ];

  const cardData4 = [
    {
      title: "Sail Bokaro",
      location: "",
      imageSrc: bokaro,
      children: <SailBokaro />,
    },
  ];

  // Set initial state to the first section key
  const [expandedSection, setExpandedSection] = useState("manufacturing");

  const sections = {
    manufacturing: {
      title: "Manufacturing & Heavy Industries",
      description:
        "These industries require heavy-duty machines, whether it's crushers, grinders, mixers, or conveyor belts which undergo intense lateral and torsional stress while operating in harsh environmental conditions. Given this environment and operational complexity, iHz proves to be an effective and scalable tool, reaching every corner of your industry without worrying about machine location or accessibility.",
      content: (
        <ApplicationGrid
          cards={cardData3}
          title="Featured Projects"
          heroImage={heavy}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    processManufacturing: {
      title: "Process Manufacturing",
      description:
        "These industries are highly sensitive to process parameters, where equipment health directly impacts product quality. From PVC mixing to chemical processing, precise control and consistency are crucial. iHz not only monitors equipment health but also provides valuable operational insights into process efficiency and batch quality, making it a win-win solution for both maintenance and production teams.",
      content: (
        <ApplicationGrid
          cards={cardData1}
          title="Featured Projects"
          heroImage={process}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    energy: {
      title: "Energy Generation",
      description:
        "Power generation depends heavily on equipment reliability where unplanned outages can be extremely costly. Beyond preventing failures, iHz provides crucial energy-related insights that help optimize operations. In an industry where efficiency improvements directly impact thin margins, having both reliability and energy performance monitoring makes every watt count.",
      content: (
        <ApplicationGrid
          cards={[]}
          title=""
          heroImage={Energy}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    utilities: {
      title: "Utilities & Infrastructure",
      description:
        "From commercial buildings to treatment plants, critical equipment like pumps, elevators, escalators, compressors, and exhaust fans form the backbone of modern infrastructure. The stakes are particularly high in wastewater treatment, where pump failures don't just cause operational disruptions - they can lead to downstream pollution and heavy penalties. Real-time monitoring becomes essential when you're processing waste matter on demand with no room for unplanned downtime.",
      content: (
        <ApplicationGrid
          cards={cardData2}
          title="Featured Projects"
          heroImage={utilities}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    transportation: {
      title: "Transportation & Mobility",
      description:
        "The world should run on time. Whether it's railway systems, metro stations, or airport infrastructure, every minute of downtime impacts thousands. These facilities depend on an intricate network of equipment - from escalators and elevators moving people to Conveyor systems ensuring  Luggage Delivery. iHz ensures these critical systems keep running reliably, helping maintain the precise schedules that modern mobility demands.",
      content: (
        <ApplicationGrid
          cards={[]}
          title=""
          heroImage={transport}
          heroTitle="Your Hero Title"
          heroDescription="Your hero description text"
        />
      ),
    },
    Other: {
      title: "Other",
      description: "",
      content: (
        <div className="flex h-full w-full items-center justify-center p-8 sm:p-12">
          <p className="max-w-2xl text-center text-lg sm:text-xl md:text-2xl font-medium leading-relaxed text-primary">
            Yeah! Our applications extend across numerous use cases and
            industries.{" "}
            <span className="relative inline-block">
              <span className="relative z-10">
                Let's connect and explore how iHz can address your unique
                monitoring needs.
              </span>
            </span>
          </p>
        </div>
      ),
    },
  };

  const handleSectionClick = (key) => {
    setExpandedSection(expandedSection === key ? null : key);
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6">
      <div className="bg-white rounded-xl p-4 sm:p-6 lg:p-8 mb-8 shadow-lg border border-blue-100">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {/* Content preview section */}
          <div className="w-full lg:w-2/3 order-2 lg:order-1">
            <div className="rounded-lg transition-all duration-300 ease-in-out">
              {expandedSection ? (
                sections[expandedSection]?.content
              ) : (
                <ContentPlaceholder
                  title="Industry Solutions"
                  description="Select an industry to view specific solutions and applications"
                />
              )}
            </div>
          </div>

          {/* Accordion section */}
          <div className="w-full lg:w-1/3 order-1 lg:order-2">
            {Object.entries(sections).map(([key, section]) => (
              <div
                key={key}
                className="border-b border-blue-100 last:border-b-0"
              >
                <div className="py-3 sm:py-4">
                  <button
                    className={`w-full flex items-center justify-between text-left transition-colors duration-200 ease-in-out ${
                      expandedSection === key
                        ? "text-secondary"
                        : "text-primary hover:text-primary"
                    }`}
                    onClick={() => handleSectionClick(key)}
                    aria-expanded={expandedSection === key}
                    aria-controls={`content-${key}`}
                  >
                    <span className="font-semibold text-sm sm:text-base pr-2">
                      {section.title}
                    </span>
                    <span
                      className="flex-shrink-0 transform transition-transform duration-200 ease-in-out"
                      aria-hidden="true"
                    >
                      {expandedSection === key ? (
                        <Minus className="w-4 h-4" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                    </span>
                  </button>
                  <div
                    id={`content-${key}`}
                    className={`transition-all duration-300 ease-in-out overflow-hidden ${
                      expandedSection === key
                        ? "max-h-72 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {section.description && (
                      <div className="mt-2 text-sm text-primary pr-4">
                        {section.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesAccordion;
