import React from "react";

import KeyValueDrivers from "../components/keyValueDriver";
import Testimonials from "../components/Testimonials";
import ContactUs from "../components/ContactUs";
import ApplicationsLayout from "../components/ApplicationsLayout";
import ApplicationsHeroSection from "../components/ApplicationsHeroSection";
import ApplicationsHowWeSeeSection from "../components/ApplicationsHowWeSeeSection";
import Application from "../components/Application";
import Breadcrumb from "../components/BreadCrumb";

import NetworkGraph from "../components/NetworkGraph";

export default function Applications() {
  return (
    <div>
      <ApplicationsHeroSection />
      <Breadcrumb />
      <ApplicationsHowWeSeeSection />
      <KeyValueDrivers />

      {/* <Application /> */}
      {/* Applications end */}

      <ApplicationsLayout />
      {/* <div className="text-center mb-6">
        <div className="text-secondary font-semibold mb-4 tracking-wider uppercase">
          APPLICATIONS
        </div>
      </div> */}

      {/* <NetworkGraph /> */}

      <div className="w-full p-12 bg-primary ">
        <div className="flex flex-col md:flex-row items-start  mx-[5%] justify-between">
          <div className="flex-grow pr-6">
            <h2 className=" text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-white leading-relaxed mb-8 md:mb-0">
              Discover your industry's solution , Start your journey
            </h2>
          </div>
          <div className="w-full md:w-auto flex items-center justify-center">
            <ContactUs />
          </div>
        </div>
      </div>
      <div className="bg-secondary-background">
        <div className="mx-[5%] pt-10">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center">
              <h2 className="text-4xl font-bold text-primary mb-2">
                Testimonials
              </h2>
              <div className="h-1 w-20 bg-secondary"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" rounded-md flex flex-col antialiased bg-primary-background items-center justify-center relative overflow-hidden">
        <Testimonials />
      </div>
    </div>
  );
}
