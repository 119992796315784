import React from "react";
import { ChevronRight } from "lucide-react";

const MissionSection = ({ missionImage }) => {
  return (
    <div className="w-full max-w-7xl mx-auto py-12 px-6">
      <div className="relative py-12">
        <div className="grid md:grid-cols-2 gap-8 items-start">
          {/* Left Column */}
          <div className="flex items-center justify-center">
            <div className="w-full group relative overflow-hidden rounded-lg shadow-lg transition-shadow duration-300 hover:shadow-xl bg-white">
              <div className="relative">
                {/* Image Container */}
                <div className="overflow-hidden">
                  <img
                    src={missionImage}
                    alt="Mission"
                    className="w-full h-auto object-cover object-center transition-transform duration-300 group-hover:scale-105 "
                  />
                </div>

                {/* Gradient Overlays */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-primary/90 via-primary/60 to-primary/30">
                  {/* Content */}
                  <div className="flex items-center gap-3 mb-3">
                    <h3 className="text-xl font-semibold text-secondary">
                      <span className="relaxed font-serif select-none">"</span>{" "}
                      Our Mission
                    </h3>
                  </div>
                  <p className="text-white mb-4 line-clamp-2">
                    Build accurate & reliable technology systems
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="text-lg flex flex-col justify-center h-full space-y-8">
            <div className="relative pl-8 flex items-start">
              <div className="absolute left-0 top-0 bottom-0 w-px bg-secondary" />
              <p className="text-primary leading-relaxed">
                Businesses and organizations managing large fleets of equipment
                and machinery bear the critical responsibility of keeping the
                world running on schedule. This is an immense challenge,
                requiring technology systems that ensure peak performance and
                efficiency, day in and day out, for years.
              </p>
            </div>

            <div className="relative pl-8 flex items-start">
              <div className="absolute left-0 top-0 w-2 h-2 rounded-full bg-secondary -translate-x-[3px]" />
              <p className="text-primary leading-loose">
                Such systems are rare because they must be both highly accurate
                and reliable. How many technologies in your experience truly
                meet these standards? This underscores the magnitude of the
                challenge.
              </p>
            </div>

            <div className="relative pl-8 flex items-start">
              <div className="absolute left-0 top-0 bottom-0 w-px bg-secondary" />
              <p className="text-primary leading-loose">
                For an IoT system to meet these demands (accurate & reliable),
                it must be robust, scalable, and capable of gathering
                high-fidelity data. The system must also be intelligent; without
                this, scaling becomes inefficient and eventually a major burden.
              </p>
            </div>

            <div className="relative pl-8 flex items-start">
              <div className="absolute left-0 top-0 w-2 h-2 rounded-full bg-secondary -translate-x-[3px]" />
              <p className="text-primary leading-loose">
                We partner with these businesses and organizations to deliver
                reliable and accurate technology systems, enabling them to
                operate their machinery at peak performance and efficiency with
                world-class reliability for years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionSection;
