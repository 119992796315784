import React, { useState, useRef, useEffect } from "react";

const ProductTour = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef.current) {
      const tabsContainer = tabsRef.current;
      const activeTabElement = tabsContainer.children[activeTab];
      if (activeTabElement) {
        const containerWidth = tabsContainer.offsetWidth;
        const tabWidth = activeTabElement.offsetWidth;
        const tabLeft = activeTabElement.offsetLeft;
        const scrollPosition = tabLeft - containerWidth / 2 + tabWidth / 2;
        tabsContainer.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [activeTab]);

  return (
    <div className="w-full bg-white">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 md:px-6 lg:px-8 py-4 sm:py-6 md:py-8">
        <div className="relative mb-4 sm:mb-8">
          {/* Gradient Overlay - visible only on larger screens */}
          <div className="hidden sm:block absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent pointer-events-none z-10" />
          <div className="hidden sm:block absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent pointer-events-none z-10" />

          {/* Scrollable Tabs with improved touch handling */}
          <div
            ref={tabsRef}
            className="flex overflow-x-auto scrollbar-hide gap-2 sm:gap-4 md:gap-8 pb-2 sm:pb-4 touch-pan-x"
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className={`flex-shrink-0 whitespace-nowrap px-2 sm:px-3 md:px-4 py-1 sm:py-2 text-sm sm:text-base md:text-lg font-medium transition-colors duration-200 border-b-2 min-w-fit
                  ${
                    activeTab === index
                      ? "border-secondary text-secondary"
                      : "border-transparent text-primary hover:text-primary"
                  }`}
              >
                {tab.title}
              </button>
            ))}
          </div>
        </div>

        {/* Content Container with dynamic height */}
        <div className="relative w-full">
          {/* Responsive height container using aspect ratio instead of fixed height */}
          <div className="w-full aspect-[4/3] sm:aspect-[16/10] md:aspect-[16/9] lg:aspect-[2/1] rounded-lg overflow-hidden ">
            {/* Scrollable content with improved mobile handling */}
            <div className="w-full h-full overflow-auto overscroll-contain">
              {/* Content wrapper with responsive padding */}
              <div className="p-2 sm:p-4 md:p-6">
                {typeof tabs[activeTab].content === "string" ? (
                  <p className="text-sm sm:text-base md:text-lg">
                    {tabs[activeTab].content}
                  </p>
                ) : (
                  tabs[activeTab].content
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTour;
