import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import app from "../assets/application-1.png";

import threeDgraph from "../assets/Applicaiton_Slide-3.png";

// Custom background components
const InterconnectedSystem = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="absolute inset-0">
      {isMobile ? (
        // Mobile layout
        <div className="relative flex justify-center">
          <div className="mt-20">
            <img
              src={app}
              alt="Interconnected System"
              className="w-auto h-[50vh] object-contain"
            />
          </div>
        </div>
      ) : (
        // Desktop layout - Positioned at bottom
        <div className="absolute inset-0 flex items-end justify-end">
          <div className="w-1/2 h-[90%] mb-0">
            <img
              src={app}
              alt="Interconnected System"
              className="w-full h-full object-contain object-bottom mb-0"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const IntricateConnections = () => (
  <div className="w-full h-full relative">
    <div className="absolute inset-0">
      <iframe
        src="https://d3dviy6vivq6am.cloudfront.net/scatter_plot.html"
        title="Interconnected System"
        className="w-full h-full"
        style={{ border: "none", zIndex: 50 }}
        allowFullScreen
      />
    </div>
  </div>
);

const IHzTechnology = () => (
  <div className="w-full h-full relative">
    <div className="relative flex justify-center md:justify-end">
      <div className="mt-20 md:mt-40">
        <img
          src={threeDgraph}
          alt="Interconnected System"
          className="w-auto h-[50vh] md:h-[90vh] object-contain"
        />
      </div>
    </div>
  </div>
);

const ApplicationsHowWeSeeSection = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [hasScrolled, setHasScrolled] = useState(false);
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const contents = [
    {
      title: (
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
          We see Machines as{" "}
          <span className="text-secondary">interconnected systems</span>
        </p>
      ),
      description:
        "Every system is designed by balancing fault tolerance requirements, degrees of freedom (DoF), and functional requirements. The design process involves finding the right balance between system complexity and efficiency. When operating and maintaining such a system in the real world—whether diagnosing faults or improving efficiency and performance—one must understand how these design variables interact within the system.",
      BackgroundComponent: InterconnectedSystem,
    },
    {
      title: (
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
          Monitoring these intricately connected systems are{" "}
          <span className="text-secondary">Multi-dimensional problem</span>
        </p>
      ),
      description:
        "In practice, the challenges faced by machines, equipment, and infrastructure on which businesses and industries rely stem from the interplay of these system design variables. Therefore, describing an application or use-case solely in terms of condition monitoring, predictive maintenance, or reliability is often imprecise.",
      BackgroundComponent: IntricateConnections,
    },
    {
      title: (
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
          <span className="text-secondary">iHz™ technology</span> is engineered
          to solve this problem
        </p>
      ),
      description:
        "We designed our iHz™ as a high-fidelity system that comprehends these system design variables, enabling efficient problem-solving.This Hi-Fi data combined with our spiderAI platform, enables the approach of Total Equipment Intelligence. This approach allows us to not only diagnose faults in the components & sub-systems but do this all within the framework of achieving your objective to maximize equipment performance in alignment with your operational goals without compromising safety and quality. While businesses and users will reap these benefits from our iHz™ systems, we define our applications and use-cases through the lens of system design variables, aiming for as much specificity as possible.",
      BackgroundComponent: IHzTechnology,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      if (!hasScrolled) {
        setHasScrolled(true);
      }

      const rect = containerRef.current.getBoundingClientRect();
      const containerTop = rect.top;
      const containerHeight = rect.height;
      const viewportHeight = window.innerHeight;

      if (
        containerTop <= viewportHeight &&
        containerTop > -containerHeight + viewportHeight
      ) {
        const progress =
          Math.abs(containerTop) / (containerHeight - viewportHeight);
        const newIndex = Math.min(
          Math.floor(progress * contents.length),
          contents.length - 1
        );
        setActiveIndex(newIndex);
      } else if (containerTop > viewportHeight) {
        setActiveIndex(-1);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [contents.length, hasScrolled]);

  return (
    <div
      ref={containerRef}
      className="relative bg-black"
      style={{ height: `${contents.length * 100}vh` }}
    >
      <div className="sticky top-0 h-screen overflow-hidden">
        <div className="mx-[5%]">
          {contents.map((content, index) => {
            const BackgroundComponent = content.BackgroundComponent;
            const isVisible = activeIndex === index && hasScrolled;
            const isIntricateConnections =
              BackgroundComponent === IntricateConnections;

            return (
              <div
                key={index}
                className={`absolute inset-0 transition-all duration-700 ${
                  isVisible
                    ? "opacity-100 blur-none"
                    : "opacity-0 blur-lg pointer-events-none"
                }`}
              >
                {isMobile ? (
                  // Mobile Layout - Keep as is
                  <div className="flex flex-col h-full">
                    <div className="relative z-30 px-4 pt-24">
                      <div className="mb-6">
                        <div className="mb-4 font-heading text-white">
                          {content.title}
                        </div>
                        <p className="text-gray-300 text-sm leading-relaxed font-body">
                          {content.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 relative mt-4">
                      <div
                        className={`h-full ${
                          isIntricateConnections ? "-mt-12" : ""
                        }`}
                      >
                        <BackgroundComponent />
                      </div>
                    </div>
                  </div>
                ) : (
                  // Desktop Layout - Adjusted for text spacing
                  <div className="relative w-full h-full">
                    {isIntricateConnections ? (
                      <>
                        {/* Content positioned with better spacing */}
                        <div className="absolute top-28 left-12 lg:left-16 max-w-2xl z-30">
                          <div className="mb-8 font-heading text-white">
                            {content.title}
                          </div>
                          <p className="text-gray-300 text-lg leading-relaxed font-body max-w-xl">
                            {content.description}
                          </p>
                        </div>
                        {/* Large interactive component that can overlap with text */}
                        <div className="absolute inset-0 pt-16 flex items-center justify-end">
                          <div
                            className="w-[85%] h-full"
                            style={{ pointerEvents: "all" }}
                          >
                            <BackgroundComponent />
                          </div>
                        </div>
                      </>
                    ) : (
                      // Original layout for other components
                      <>
                        <div className="absolute top-28 left-12 lg:left-16 max-w-xl lg:max-w-2xl z-30">
                          <div className="mb-6 font-heading text-white">
                            {content.title}
                          </div>
                          <p className="text-gray-300 text-lg leading-relaxed font-body">
                            {content.description}
                          </p>
                        </div>
                        <div className="absolute inset-0">
                          <BackgroundComponent />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}

          {/* Progress indicator remains the same */}
          <div className="absolute right-2 sm:right-4 md:right-8 top-1/2 -translate-y-1/2 flex flex-col gap-2 sm:gap-4 z-40">
            {contents.map((_, index) => (
              <div
                key={index}
                className={`transition-all duration-300 rounded-full ${
                  activeIndex === index
                    ? "bg-secondary w-1 sm:w-1.5 md:w-2 h-4 sm:h-6 md:h-8"
                    : "bg-gray-600 w-1 sm:w-1.5 md:w-2 h-1 sm:h-1.5 md:h-2"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsHowWeSeeSection;
